import React from 'react';
import Modal from 'react-responsive-modal';

const ErrorSignUpModal = ({ open, toggle }) => {
  return (
    <Modal
      open={open}
      onClose={() => toggle(false)}
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
      id="user_not_created"
    >
      <div className="popup">
        <i className="fa-solid fa-close"></i>
        <h4>Une erreur est survenue lors de la création de votre compte</h4>
        <div className="buttons">
          <a className="button_primary" id="close_user_not_created" href="/">
            OK
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorSignUpModal;
