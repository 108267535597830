import React, { useEffect, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { useAuthStore } from "../../store/auth.js";

import { CiMenuKebab } from "react-icons/ci";
import { useDocumentTitle, useToggle } from "usehooks-ts";
import imageHolder from "../../assets/images/logo_svg.svg";
import Products from "../../components/products/Products.jsx";
import { useFavoriteStore } from "../../store/useFavorite.js";
import ChangeUserInfosModal from "./ChangeUserInfosModal.jsx";
import CommandeItems, { Menu } from "./CommandeItems.jsx";
import { auth } from "../../configs/firebase";
import { useNavigate } from "react-router-dom";
import { useModalStore } from "../../store/modal.js";
import { formatNumberHumanReadable } from "../../utils/index.js";

function MobileOrderItem({ order }) {
  useDocumentTitle("CAREF | Commandes");
  const menuRef = useRef(null);
  const [openMenu, toggleMenu] = useToggle();
  const timestamp = order?.creationTimeStamp?.seconds;
  const dateAchat = new Date(timestamp * 1000).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const products =
    typeof order?.products == "string"
      ? JSON.parse(order?.products ?? "[]")
      : order?.products;

  const livraison =
    order?.shipping_method === "agence" || !order?.shipping_address
      ? "À L’AGENCE"
      : `À L'adresse ${order?.shipping_address}`;

  const isAvailable = products.reduce(
    (acc, product) =>
      product?.availability?.trim() === "Disponible" ||
      product?.availability?.trim() === "En stock"
        ? acc
        : false,
    true
  );
  return (
    <div className="order_mobile_item">
      <div className="top">
        <div>
          <p>Commande N°{order?.number || "--"}</p>
          <p>
            Date d'achat :{dateAchat}
            <br />
            Date de livraison :{" "}
            {isAvailable
              ? "Livraison sous 72h"
              : "nous allons vous contacter dès que la commande est prête"}
            <br />
            COMMANDE LIVRÉe : {livraison}
          </p>
        </div>
        <div>
          <p
            style={{
              fontSize: "1rem",
              textTransform: "uppercase",
              backgroundColor:
                order?.order_status === "en cours"
                  ? "#FFC107"
                  : order?.order_status === "livre"
                  ? "#4CAF50"
                  : "#ff0000",
            }}
          >
            {order?.order_status || "status"}
          </p>
          <span className="menu-btn" onClick={toggleMenu} ref={menuRef}>
            <CiMenuKebab />
            {openMenu && (
              <Menu
                containerRef={menuRef}
                toggleMenu={toggleMenu}
                order={order}
              />
            )}
          </span>
        </div>
      </div>
      <div className="middle">
        {products.map((product, i) => (
          <div key={`l-${product?.id}-${i}`}>
            <div className="images">
              <img
                src={product?.link_vendor ?? imageHolder}
                alt="piece"
                style={{
                  objectFit: "cover",
                }}
              />
              <img src={product?.link ?? imageHolder} alt="piece" />
              <p>{formatNumberHumanReadable(product.price)} FCFA</p>
            </div>
            <div className="infos">
              <p>
                {product?.brand} {product?.model}
              </p>
              <p>{product?.parts}</p>
              <p>
                {product?.tech_infos}
                <span>CAREF: {product?.caref}</span>
              </p>
              <p className="qte">quantité: {product?.quantity}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bottom">
        <div>Total :</div>
        <div>{order?.order_total || order?.total} FCFA</div>
      </div>
    </div>
  );
}

function CommandePage() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);
  const orders = useAuthStore((state) => state.orders);
  const loadingUser = useAuthStore((state) => state.loadingUser);
  const favorite = useFavoriteStore((state) => state.favorite);
  const [openEditModal, toggleEditModal] = useToggle();
  const setLoginModal = useModalStore((state) => state.setLoginModal);

  useEffect(() => {
    if (!loadingUser && !user) setLoginModal(true);

    return () => {};
  }, [loadingUser, setLoginModal, user]);

  return (
    <div className="commande_page_container">
      <ChangeUserInfosModal open={openEditModal} toggle={toggleEditModal} />
      <div className="commande_page">
        <div className="header">
          <div className="title">
            <h3>Historique des commandes</h3>
          </div>
          <div className="infos">
            <p>
              Prénom & Nom :{" "}
              {`${customer?.fname || ""} ${customer?.lname || ""}`} <br />
              Téléphone : {user?.phoneNumber} <br />
              Adresse : {customer?.address} <br />
            </p>
            <p
              className="edit"
              onClick={() => {
                if (user) toggleEditModal();
              }}
            >
              Editer{" "}
              <span>
                <MdEdit />
              </span>
            </p>
          </div>
        </div>

        <table className="commandes_table hide_mobile">
          <thead className="table_header">
            <tr>
              <th>Commandes </th>
              <th>Articles</th>
              <th>Quantité</th>
              <th>Total</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody className="table_item">
            {orders && user ? (
              orders.map((order, i) => (
                <CommandeItems key={order.id} order={order} />
              ))
            ) : (
              <tr>
                <td>
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                </td>
                <td>
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                </td>
                <td>
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                </td>
                <td>
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                </td>
                <td>
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="hide_desktop order_mobile">
          {orders ? (
            orders.map((order, i) => (
              <MobileOrderItem key={order.id} order={order} />
            ))
          ) : (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}
        </div>
      </div>
      <Products title="Mes favoris" products={favorite} id="favoris" />
      <div className="logout">
        <button
          onClick={() => {
            auth.signOut();
            navigate("/");
          }}
        >
          <i className="fas fa-sign-out-alt"></i>
          Se déconnecter
        </button>
      </div>
    </div>
  );
}

export default CommandePage;
