import React from "react";
import DiagnosticAboutImage from "../../assets/images/Entretient et réparation.png";

const TextOutSection = () => {
  return (
    <section id="service" className="text_out_section">
      <div className="diagnostic_about">
        <img src={DiagnosticAboutImage} alt="" />
        <div className="right_text">
          <h2 className="section_title" id="e_et_r">Entretien & Réparation</h2>
          <p>
            CAREF Automobile intervient sur toute la chaîne de l’entretien et de
            la maintenance de votre véhicule, de la détection du défaut jusqu’à
            la réparation en passant par le diagnostic électronique avec des
            outils à la pointe de la technologie et par la fourniture de la
            pièce de rechange de qualité garantie.
            <br /> <br />
            Nos services d’entretien et de Réparation: <br />
            <ul>
              <li
                style={{
                  listStyle: "inside",
                  textAlign: "left",
                  marginLeft: "20px",
                }}
              >
                Diagnostic électronique & mécanique
              </li>
              <li
                style={{
                  listStyle: "inside",
                  textAlign: "left",
                  marginLeft: "20px",
                }}
              >
                Remise en état
              </li>
              <li
                style={{
                  listStyle: "inside",
                  textAlign: "left",
                  marginLeft: "20px",
                }}
              >
                Pré-contrôle technique
              </li>
              <li
                style={{
                  listStyle: "inside",
                  textAlign: "left",
                  marginLeft: "20px",
                }}
              >
                Reprogrammation clés & calculateurs
              </li>
              <li
                style={{
                  listStyle: "inside",
                  textAlign: "left",
                  marginLeft: "20px",
                }}
              >
                Forfaits d’entretien et de révision
              </li>
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};

export default TextOutSection;
