import React, { useEffect, useRef, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDocumentTitle, useFetch } from 'usehooks-ts';
import Pagination from '../../components/pagination/Pagination';
import { API_URL } from '../../constant/api';
import useQuery from '../../hooks/useQuery';
import { useSearchStore } from '../../store/search';
import { isValidFirebaseId, slugify } from '../../utils';
import splitVendors from '../../utils/splitVendor';
import Breadcrumbs from './Breadcrumbs';
import CatalogItems from './CatalogItems';
import SliderPieceCatalog from './SliderPieceCatalog';

import Swiper from 'swiper';

function CatalogPage() {
  useDocumentTitle('CAREF | Catalogue des pièces');
  const swiperRef = useRef(null);
  const piecesPerPage = 5;
  const query = useQuery();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const selectedSearchOption = useSearchStore((state) => state.searchOptions);
  const { data: categories, error: errorCategories } = useFetch(`${API_URL}/categories_piece`);
  const { data: subCategories, error: errorSubCategories } = useFetch(
    selectedCategory ? `${API_URL}/categories_piece/${selectedCategory}/subcategories` : null,
  );
  const { data: pieces, error: errorPieces } = useFetch(
    selectedSearchOption.selectedMarque &&
      selectedSearchOption.selectedModel &&
      selectedSearchOption.selectedDrive
      ? `${API_URL}/marques/${selectedSearchOption.selectedMarque}/modeles/${selectedSearchOption.selectedModel}/drive/${selectedSearchOption.selectedDrive}/pieces`
      : null,
  );

  const selectedCategoryName = categories?.find((category) => category.id === selectedCategory)?.nom;
  const selectedSubCategoryName = subCategories?.find((subCategory) => {
    return subCategory.id === selectedSubCategory;
  })?.nom;

  const filteredPieces = pieces
    ?.filter((piece) => {
      return (
        slugify(piece.category) === slugify(selectedCategoryName) &&
        slugify(piece.sous_category) === slugify(selectedSubCategoryName)
      );
    })
    .map((e) => splitVendors(e))
    .flat(1);

  useEffect(() => {
    document.querySelector('#pieces_catalogue_page')?.scrollIntoView({ behavior: 'smooth' });
    return () => {};
  }, []);

  useEffect(() => {
    if (categories) {
      const categoryQuery = query.get('category');
      const index = categories.findIndex((category) => category.id === categoryQuery);
      if (swiperRef.current) swiperRef.current.slideTo(index ?? 0);
      const defaultCategory = isValidFirebaseId(categoryQuery) ? categoryQuery : categories[0]?.id;
      if (defaultCategory) setSelectedCategory(defaultCategory);
    }
  }, [query, categories, swiperRef]);

  useEffect(() => {
    if (subCategories) {
      const subCategoryQuery = query.get('subCategory');
      const defaultSubCategory = isValidFirebaseId(subCategoryQuery)
        ? subCategoryQuery
        : subCategories[0]?.id;

      if (defaultSubCategory) setSelectedSubCategory(defaultSubCategory);
    }
  }, [query, subCategories]);

  useEffect(() => {
    swiperRef.current = new Swiper('.pieces_catalogue_page .swiper-container', {
      direction: 'vertical',
      effect: 'slide',
      slidesPerView: 5,
      navigation: {
        nextEl: '.pieces_catalogue_page .next_slide',
        prevEl: '.pieces_catalogue_page .prev_slide',
      },
    });

    return () => {};
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [currentPage]);

  const indexOfLastPiece = currentPage * piecesPerPage;
  const indexOfFirstPiece = indexOfLastPiece - piecesPerPage;
  const currentPieces = filteredPieces?.slice(indexOfFirstPiece, indexOfLastPiece);

  return (
    <>
      <div className="popup_overlay hide fade_left" id="added_to_cart">
        <div className="popup added_to_cart">
          <i className="fa-solid fa-check"></i>
          <h4>Votre produit a bien été ajouté dans votre panier</h4>
          <div className="buttons">
            <Link className="button_empty close_popup">continuer vos achats</Link>
            <Link className="button_primary">confirmer l'achat</Link>
          </div>
        </div>
      </div>
      <section className="pieces_catalogue_page" id="pieces_catalogue_page">
        <h2>CATALOGUE DES PIÈCES</h2>
        <span className="compatible_piece">
          <AiFillCheckCircle />
          &nbsp; LES PIECES COMPATIBLES AVEC VOTRE {selectedSearchOption.selectedMarqueName}{' '}
          {selectedSearchOption.selectedModelName}
        </span>
        <div className="section_container">
          <SliderPieceCatalog
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
            setCurrentPage={setCurrentPage}
            errorCategories={errorCategories}
            swiperRef={swiperRef}
          />
          <div className="section_content">
            <Breadcrumbs
              categories={categories}
              errorCategories={errorCategories}
              subCategories={subCategories}
              setCurrentPage={setCurrentPage}
              errorSubCategories={errorSubCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
            />
            <div className="catalogues">
              {pieces &&
                !errorPieces &&
                (currentPieces?.length > 0 ? (
                  currentPieces?.map((piece, index) => (
                    <CatalogItems key={index} piece={piece} index={index} />
                  ))
                ) : (
                  <div className="center">
                    <p
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        marginTop: '2rem',
                      }}
                      className=""
                    >
                      Aucun résultat trouvé
                    </p>
                  </div>
                ))}

              {!pieces && !errorPieces && (
                <div className="loader-container">
                  <span className="loader"></span>
                </div>
              )}

              {errorPieces && (
                <div className="loader-container">
                  <span className="error">Une erreur est survenue</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={filteredPieces}
          piecesPerPage={piecesPerPage}
        />
      </section>
    </>
  );
}

export default CatalogPage;
