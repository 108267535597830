import React, { useState } from "react";
import PackImage from "../../assets/images/packs.png";
import StepsImage from "../../assets/images/Etapes réservation desktop.png";
import { Controller, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ConsultationImage_mobile1 from "../../assets/images/carroussel/3-Contenus pack vendeur-1.png";
import ConsultationImage_mobile2 from "../../assets/images/carroussel/3-Contenus pack vendeur-2.png";
import reservation1 from "../../assets/images/Etapes réservation mobile 1.png";
import reservation2 from "../../assets/images/Etapes réservation mobile 2.png";
import reservation3 from "../../assets/images/Etapes réservation mobile 3.png";

const MethodologySection = () => {
  const [currentPagePack, setCurrentPagePack] = useState(1);
  const totalSlides = 2;
  const [controlledSwiper1, setControlledSwiper1] = useState(null);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);
  const handlePackSlideChange = (swiper) => {
    setCurrentPagePack(swiper.realIndex + 1);
  };
  return (
    <section className="methodology_section" id="notre_service">
      <h1 id="consulting">Consulting</h1>
      <p>
        CAREF vous accompagne en toute transparence pour la vente ou l’achat de
        votre véhicule dans un état sûr et garanti. <br />
        <br />
        Profitez de nos packs pour sécuriser votre projet de vente ou d’achat de
        voiture d’occasion: <br />
        <br />
        <ul>
          <li
            style={{
              listStyle: "inside",
              textAlign: "left",
              marginLeft: "20px",
              fontWeight: "bold",
            }}
          >
            PACK ACHAT
          </li>
        </ul>
        <br />
        L’achat d’une voiture est un investissement considérable en terme de
        budget et aussi de temps. Avec CAREF, vous vous assurez d'acheter
        rapidement votre voiture à l’état souhaité et au prix du marché.
        <br /> <br />
        <ul>
          <li
            style={{
              listStyle: "inside",
              textAlign: "left",
              marginLeft: "20px",
              fontWeight: "bold",
            }}
          >
            PACK VENTE
          </li>
        </ul>
        <br />
        Avec la saturation du marché, vendre un véhicule devient de plus en plus
        compliqué. De plus, les acheteurs deviennent de plus en plus réticents à
        cause des risques de vol ou de véhicules accidentés importés, ou bien
        encore de vices cachées ou de pannes d’organes non visibles. <br />
        <br /> CAREF vous propose des services qui sécurisent la vente en
        fournissant des justificatifs techniques ainsi qu’une garantie prouvant
        le bon état de votre véhicule en vente et qui rassurent son futur
        propriétaire.
      </p>
      <img src={PackImage} alt="Packs" id="packs" className="hide_swipper" />
      <div
        className="hide_desktop"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#EB550E",
        }}
      >{`${currentPagePack}/${totalSlides}`}</div>
      <div className="logos_slider hide_desktop">
        <Swiper
          modules={[Controller, Pagination]}
          onSlideChange={handlePackSlideChange}
          slidesPerView={1}
          onSwiper={setControlledSwiper1}
          spaceBetween={50}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' +
                className +
                '" style="background-color: #EB550E;"></span>'
              );
            },
          }}
          controller={{ control: controlledSwiper1 }}
          className="swiper-wrapper"
        >
          <SwiperSlide>
            <img
              alt=""
              className="swiper-slide"
              src={ConsultationImage_mobile1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              alt=""
              className="swiper-slide"
              src={ConsultationImage_mobile2}
            />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
      <img src={StepsImage} alt="Packs" className="hide_swipper" />
      <div className="logos_slider hide_desktop">
        <Swiper
          modules={[Controller, Pagination]}
          slidesPerView={1}
          onSwiper={setControlledSwiper2}
          spaceBetween={50}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' +
                className +
                '" style="background-color: #EB550E;"></span>'
              );
            },
          }}
          controller={{ control: controlledSwiper2 }}
          className="swiper-wrapper"
        >
          <SwiperSlide>
            <img alt="" className="swiper-slide" src={reservation1} />
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" className="swiper-slide" src={reservation2} />
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" className="swiper-slide" src={reservation3} />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default MethodologySection;
