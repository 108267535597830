import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBhqOBxkVrqlgQNXYVTjB6WhY7vIr_3O88',
  authDomain: 'caref-35715.firebaseapp.com',
  projectId: 'caref-35715',
  storageBucket: 'caref-35715.appspot.com',
  messagingSenderId: '192181170187',
  appId: '1:192181170187:web:08de26de0de80c70b8d70e',
  measurementId: 'G-VCQ66L9PE0',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
