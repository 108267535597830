import React, { useState } from 'react';
import { useModalStore } from '../../store/modal';
import { useNavigate } from 'react-router-dom';

const pages = [
  {
    id: 1,
    name: 'Entretien & Réparation',
    url: '/diagnostic#diagnostic_booking',
  },
  {
    id: 2,
    name: 'Services de Consulting ',
    url: '/consulting-services#diagnostic_booking',
  },
];

const QuickAccessBooking = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(pages[0].url);
  const [open, setOpen] = useModalStore((state) => [
    state.quickAccessBooking,
    state.setQuickAccessBooking,
  ]);

  return (
    <div
      style={{
        display: open ? 'flex' : 'none',
      }}
      className="popup_overlay"
      onClick={() => setOpen(false)}
    >
      <div
        className="popup sent_booking"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h4>Accès rapide aux formulaires de réservation</h4>
        <div className="page_select">
          {pages.map((p) => (
            <div key={p.id} className="page_select_option">
              <input
                onChange={(e) => setUrl(pages.find((p) => p.id === parseInt(e.target.value)).url)}
                type="radio"
                defaultChecked={p.id === 1}
                defaultValue={p.id}
                name="page"
                id={`page-${p.id}`}
              />
              <label htmlFor={`page-${p.id}`}>{p.name}</label>
            </div>
          ))}
        </div>

        <div className="buttons">
          <button className="button_primary button_empty" onClick={() => setOpen(false)}>
            Annuler
          </button>
          <button
            className="button_primary close_popup"
            onClick={() => {
              navigate(url);
              setOpen(false);
            }}
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickAccessBooking;
