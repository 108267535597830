import React, { Fragment } from "react";
import { set, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InterrogationImage from "../../assets/images/icons/interogation.svg";
import logoCenterImage from "../../assets/images/logo_center.svg";
import { useSearchStore } from "../../store/search";
import VinTooltip from "../VinTooltip";
import { fetchApi } from "../../libs/fetch";
import toast from "react-hot-toast";
import { API_URL } from "../../constant/api";

const VinModal = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [
    open,
    setOpen,
    otherDriver,
    setOtherDriver,
    redirectUrl,
    setVin,
    setRedirectUrl,
  ] = useSearchStore((state) => [
    state.openVinModal,
    state.setVinModal,
    state.otherDriver,
    state.setOtherDriver, //
    state.redirectUrl,
    state.setVin,
    state.setRedirectUrl,
  ]);

  const onSubmit = async (data) => {
    // save data to endpoint /api/vehicleToRegister
    if (otherDriver) {
      try {
        await fetchApi(`${API_URL}/vehicle-to-register`, {
          method: "POST",
          body: JSON.stringify({
            vin: data.vin,
            phone: data.phone,
            status: false,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        setVin(data.vin);
        setOpen(false);
        navigate(redirectUrl);
        setRedirectUrl(null);
        setOtherDriver(false);
        toast.success("Votre demande a été envoyée avec succès");
      } catch (error) {
        toast.error("Une erreur est survenue lors de l'envoi de votre demande");
      }
    } else {
      setVin(data.vin);
      setOpen(false);
      navigate(redirectUrl);
      setRedirectUrl(null);
    }
  };

  return (
    <div
      onClick={() => {
        setOpen(false);
      }}
      className={`popup_overlay fade_left ${open ? "" : "hide"}`}
      id="vin_form"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="popup"
      >
        <img alt="" src={logoCenterImage} />
        <p>
          Veuillez renseigner votre numéro de châssis (VIN) à 17 caractères.
          <br />
          <br />
          Pour l'identification unique de votre véhicule et de toutes ses
          caractéristiques.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="form_rep">
          <div className="elm">
            <label>
              Renseigner le VIN<span>*</span> (Numéro de châssis)
            </label>
            {errors.vin && <span className="error">{errors.vin.message}</span>}
            <div className="icon_input">
              <input
                {...register("vin", {
                  required: true,
                  validate: (value) =>
                    value.length === 17 ||
                    "Le VIN doit contenir 17 caractères!",
                })}
                type="text"
                placeholder="*****************"
                aria-invalid={errors.vin ? "true" : "false"}
              />
              <img
                alt=""
                src={InterrogationImage}
                data-tooltip-id="vin_tooltip"
              />
              <VinTooltip />
            </div>
          </div>
          <div className="elm">
            <label>
              Confirmer le VIN<span>*</span>
            </label>
            {errors.confirmVin && (
              <span className="error">{errors.confirmVin.message}</span>
            )}
            <input
              {...register("confirmVin", {
                required: true,
                validate: (value) =>
                  value === watch("vin") || "Le VIN ne correspondent pas!",
              })}
              type="text"
              placeholder="Renseigner le numéro de châssis"
              aria-invalid={errors.confirmVin ? "true" : "false"}
            />
          </div>
          {otherDriver && (
            <Fragment>
              <div className="elm">
                <label>
                  Téléphone<span>*</span>
                </label>
                {errors.phone && (
                  <span className="error">{errors.phone.message}</span>
                )}
                <input
                  {...register("phone", {
                    required: true,
                    validate: (value) =>
                      value.length > 8 || "Numéro de téléphone invalide!",
                    pattern: {
                      value: /^\d+$/,
                      message: "Numéro de téléphone invalide!",
                    },
                  })}
                  type="text"
                  placeholder="Téléphone"
                  aria-invalid={errors.phone ? "true" : "false"}
                />
              </div>
              <p>
                Dès que votre véhicule aura été ajouté au catalogue sur notre
                site, vous recevrez une confirmation par Téléphone.
              </p>
            </Fragment>
          )}
          <button type="submit">OK</button>
        </form>
      </div>
    </div>
  );
};

export default VinModal;
