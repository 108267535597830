import React, { useEffect, useRef } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { useToggle } from "usehooks-ts";
import imageHolder from "../../assets/images/logo_center.svg";

export const Menu = ({ containerRef, toggleMenu, order }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        toggleMenu();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef, toggleMenu]);

  function openMail() {
    const title = `A propos de ma commande N°${order?.number || order?.id}`;
    const email = "contact@carefautomobile.com";
    const mailto = `mailto:${email}?subject=${title}`;

    window.open(mailto, "_blank");
  }

  return (
    <ul>
      <li onClick={openMail}>Nous contacter</li>
    </ul>
  );
};
function CommandeItems({ order }) {
  const menuRef = useRef(null);
  const [openMenu, toggleMenu] = useToggle();
  const timestamp = order?.creationTimeStamp?.seconds;

  const dateAchat = new Date(timestamp * 1000).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const products =
    typeof order?.products == "string"
      ? JSON.parse(order?.products ?? "[]")
      : order?.products;

  const livraison =
    order?.shipping_method === "agence" || !order?.shipping_address
      ? "À L’AGENCE"
      : `À L'adresse ${order?.shipping_address}`;

  const isAvailable = products.reduce(
    (acc, product) =>
      product?.availability?.trim() === "Disponible" ||
      product?.availability?.trim() === "En stock"
        ? acc
        : false,
    true
  );

  return (
    <tr>
      <td>
        <div>
          <p>Commande N°{order?.number || "--"}</p>
          <p>
            Date d'achat : {dateAchat}
            <br />
            Date de livraison :
            {isAvailable
              ? "Livraison sous 72h"
              : "nous allons vous contacter dès que la commande est prête"}
            <br />
            COMMANDE LIVRÉe : {livraison}
          </p>
        </div>
      </td>
      <td>
        {products?.map((product, i) => (
          <div key={`l-${product?.id}-${i}`}>
            <div className="images">
              <img
                src={product?.link_vendor ?? imageHolder}
                alt="piece"
                style={{
                  objectFit: "cover",
                }}
              />
              <img src={product?.link ?? imageHolder} alt="piece" />
            </div>
            <div>
              <p>
                {product?.brand} {product?.model}
              </p>
              <p>{product?.parts}</p>
              <p>
                {product?.tech_infos}
                <span>CAREF: {product?.caref}</span>
              </p>
            </div>
          </div>
        ))}
      </td>
      <td>
        <div className="quantite">
          {products?.map((product, i) => (
            <div key={`r-${product?.id}-${i}`}>
              <p>{product?.quantity}</p>
            </div>
          ))}
        </div>
      </td>
      <td
        style={{
          textTransform: "uppercase",
        }}
      >
        {order?.order_total || order?.total} FCFA
      </td>
      <td>
        <div>
          <p
            style={{
              fontSize: "1rem",
              textTransform: "uppercase",
              backgroundColor: {
                "en cours": "#FFC107",
                annule: "#F44336",
                livre: "#4CAF50",
              }[order?.order_status || "en cours"],
            }}
          >
            {order?.order_status || "en cours"}
          </p>
          <span className="menu-btn" onClick={toggleMenu} ref={menuRef}>
            <CiMenuKebab />
            {openMenu && (
              <Menu
                containerRef={menuRef}
                toggleMenu={toggleMenu}
                order={order}
              />
            )}
          </span>
        </div>
      </td>
    </tr>
  );
}

export default CommandeItems;
