import { API_URL } from '../constant/api';
import { fetchApi } from './fetch';

export async function payByPaydunya({ cart, customer_phone, customer_name, vin }) {
  const res = await fetchApi(`${API_URL}/orders/createOrder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...cart,
      client_phone: customer_phone,
      client_name: customer_name,
      vin,
    }),
  });

  return res;
}
