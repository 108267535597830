import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const MenuMobile = ({
  menuRef,
  showMenu,
  partCategories,
  partSubCategories,
  parts,
  handleSearchCatalogPeace,
  setSelectedPartCategory,
  setSelectedPartSubCategories,
  closeMenu,
}) => {
  const menuMobileRef = useRef(null);
  const [step, setStep] = useState(1);

  const scrollToMenu = useCallback(() => {
    const el = menuMobileRef.current?.parentNode;
    if (!el) return;
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [menuMobileRef]);

  function toggleSubMenu(e) {
    e.preventDefault();
    const container = document.getElementById(e.target.dataset.containerTarget);
    if (!container) return;
    const i = e.target.querySelector('i');
    if (i && i.classList.contains('fa-chevron-down')) {
      i.classList.remove('fa-chevron-down');
      i.classList.add('fa-chevron-up');
    } else if (i && i.classList.contains('fa-chevron-up')) {
      i.classList.remove('fa-chevron-up');
      i.classList.add('fa-chevron-down');
    }
    container.classList.toggle('hide');
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={menuMobileRef} className={`menu_mobile hide_desktop`}>
      <div className={`menu_mobile_1 ${step === 1 ? 'show' : 'hide'}`}>
        <h4>Menu</h4>
        <div className="menu_mobile_list">
          <Link
            onClick={(e) => {
              e.preventDefault();
              setStep(2);
            }}
          >
            CATALOGUE DES PIÈCES
            <i className="fas fa-chevron-right"></i>
          </Link>
          <Link
            onClick={toggleSubMenu}
            className="list_title"
            data-container-target="mobile_container_2"
          >
            entretien & réparation
            <i className="fas fa-chevron-down"></i>
          </Link>
          <div className="mobile_submenu_container hide" id="mobile_container_2">
            <Link
              to="/diagnostic#service"
              onClick={closeMenu}
              className="submenu"
              data-submenu-target="sub_2_1"
            >
              Services
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/diagnostic#forfaits"
              onClick={closeMenu}
              className="submenu"
              data-submenu-target="sub_2_2"
            >
              Forfaits
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/diagnostic#abonnements"
              onClick={closeMenu}
              className="submenu"
              data-submenu-target="sub_2_3"
            >
              Abonnements
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
          <Link
            onClick={toggleSubMenu}
            className="list_title"
            data-container-target="mobile_container_3"
          >
            services de consulting
            <i className="fas fa-chevron-down"></i>
          </Link>
          <div className="mobile_submenu_container hide" id="mobile_container_3">
            <Link
              to="/consulting-services#notre_service"
              className="submenu"
              data-submenu-target="sub_3_1"
              onClick={closeMenu}
            >
              Services
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/consulting-services#packs"
              onClick={closeMenu}
              className="submenu"
              data-submenu-target="sub_3_2"
            >
              Packs
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
          <Link
            onClick={toggleSubMenu}
            className="list_title"
            data-container-target="mobile_container_4"
          >
            ventes d'occasions
            <i className="fas fa-chevron-down"></i>
          </Link>
          <div className="mobile_submenu_container hide" id="mobile_container_4">
            <Link
              to="/sale/bons_plans_piece#navbar"
              className="submenu"
              data-submenu-target="sub_3_1"
              onClick={closeMenu}
              id="parts"
            >
              Pièces détachées
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/sale/bons_plans_auto#navbar"
              className="submenu"
              data-submenu-target="sub_3_2"
              onClick={closeMenu}
              id="vehicles"
            >
              Véhicules
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className={`menu_mobile_2 ${step === 2 ? 'show' : 'hide'}`}>
        <h4>
          <i
            className="fas fa-arrow-left"
            onClick={() => {
              setStep(1);
              setSelectedPartCategory(null);
            }}
          ></i>
          Catégorie
        </h4>
        <div className="menu_mobile_list">
          {partCategories &&
            partCategories?.map((partCategory) => (
              <Link
                key={partCategory.id}
                onClick={(e) => {
                  e.preventDefault();
                  setStep(3);
                  setSelectedPartCategory(partCategory.id);
                  scrollToMenu();
                }}
              >
                {partCategory.nom}
                <i className="fas fa-chevron-right"></i>
              </Link>
            ))}
          {!partCategories && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>

      <div className={`menu_mobile_2 ${step === 3 ? 'show' : 'hide'}`}>
        <h4>
          <i
            className="fas fa-arrow-left"
            onClick={() => {
              setStep(2);
              setSelectedPartSubCategories(null);
            }}
          ></i>
          Sous Categories
        </h4>
        <div className="menu_mobile_list">
          {partSubCategories &&
            partSubCategories?.map((partSubCategory) => (
              <Link
                key={partSubCategory.id}
                onClick={(e) => {
                  e.preventDefault();
                  setStep(4);
                  setSelectedPartSubCategories(partSubCategory.id);
                  scrollToMenu();
                }}
              >
                {partSubCategory.nom}
                <i className="fas fa-chevron-right"></i>
              </Link>
            ))}
          {!partSubCategories && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>

      <div className={`menu_mobile_1 ${step === 4 ? 'show' : 'hide'}`}>
        <h4>
          <i className="fas fa-arrow-left" onClick={() => setStep(3)}></i>
          Pièces
        </h4>
        <div className="menu_mobile_list">
          {parts &&
            parts?.map((part) => (
              <Link
                key={part.id}
                onClick={(e) => {
                  e.preventDefault();
                  handleSearchCatalogPeace(part);
                  setStep(1);
                }}
              >
                {part.parts}
                <i className="fas fa-chevron-right"></i>
              </Link>
            ))}

          {!parts && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
