import React from "react";
import imageHolder from "../../assets/images/logo_center.svg";
import { useFavoriteStore } from "../../store/useFavorite";
import { useCartStore } from "../../store/cart.js";
import { formatNumberHumanReadable } from "../../utils/index.js";

function PieceBonPlans({ product }) {
  const cart = useCartStore((state) => state.cart);
  const cartAdd = useCartStore((state) => state.addToCart);
  const addToCart = (e) => {
    e.preventDefault();

    let existItem = cart.find((item) => item.id === product?.id);

    if (existItem) return;

    cartAdd({ ...product, quantity: 1 });
  };
  const favorite = useFavoriteStore((state) => state.favorite);
  const addToFavorite = useFavoriteStore((state) => state.addToFavorite);
  const removeFromFavorite = useFavoriteStore(
    (state) => state.removeFromFavorite
  );

  const isFavorite = favorite.find((item) => item.id === product?.id);
  function toggleFavorite(p) {
    if (isFavorite) {
      removeFromFavorite(p);
    } else {
      addToFavorite(p);
    }
  }
  return (
    <div className="single_product">
      <img src={product?.link ?? imageHolder} alt="piece" />
      <h3>{product?.parts}</h3>
      <div className="packed_text">
        <p>{product?.brand}</p>
        <p>{product?.model}</p>
        <p>{product?.drive}</p>
      </div>
      <h4>
        Garantie {product?.waranty}{" "}
        {parseInt(product?.waranty) <= 1 ? "an" : "ans"}
      </h4>
      <strong>{formatNumberHumanReadable(product?.price)} FCFA</strong>
      <button className="add_to_cart" onClick={addToCart}>
        <i className="fa-solid fa-cart-shopping"></i>
      </button>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
        className={`heart ${isFavorite ? "fa-solid" : "fa-regular"} fa-heart`}
        onClick={() => toggleFavorite(product)}
      ></div>
    </div>
  );
}

export default PieceBonPlans;
