import { Tooltip } from 'react-tooltip';

const VinTooltip = ({ id = 'vin_tooltip' }) => {
  return (
    <Tooltip
      style={{
        backgroundColor: '#fff',
        maxWidth: '430px',
        width: "100%",
        color: '#000',
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        border: '1px solid #000',
      }}
      id={id}
      effect="solid"
    >
      <div>
        <p
          style={{
            textAlign: 'left',
            color: '#000 !important',
            margin: '10px 0',
          }}
        >
          Le numéro VIN (Vehicle Identification Number) appelé également numéro de châssis, est composé
          d’une suite alphanumérique unique de 17 caractères et permet d’identifier précisément votre
          véhicule.
        </p>
        <p
          style={{
            textAlign: 'left',
            color: '#000 !important',
            margin: '10px 0',
          }}
        >
          Le VIN est renseigné sur la carte grise, au niveau du châssis et sur le pare-brise de votre
          véhicule.
        </p>
        <p
          style={{
            textAlign: 'left',
            color: '#000 !important',
            margin: '10px 0',
          }}
        >
          Contactez-nous au 77 355 52 52 si vous ne retrouvez pas votre VIN.
        </p>
      </div>
    </Tooltip>
  );
};
export default VinTooltip;
