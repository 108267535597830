import { updateProfile } from 'firebase/auth';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import logoCenterImage from '../../assets/images/logo_center.svg';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../store/auth';
import { Link } from 'react-router-dom';

const SignUpModal = ({ open, toggle }) => {
  const navigate = useNavigate();
  const [acceptCgu, setAcceptCgu] = useState(false);
  const user = useAuthStore((state) => state.user);
  const login = useAuthStore((state) => state.login);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const regexValidName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

  const onSubmit = async (e) => {
    e?.preventDefault();
    try {
      setLoading(true);
      await updateProfile(user, {
        displayName: firstName + ' ' + lastName,
      });
      await fetchApi(`${API_URL}/customers`, {
        method: 'POST',
        body: JSON.stringify({
          fname: firstName?.trim(),
          lname: lastName?.trim(),
          authId: user.uid,
          address: address?.trim(),
          tel: user.phoneNumber,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      login({ ...user, displayName: firstName + ' ' + lastName });
      toggle(false, { back: true });
      navigate('/?l=success-sign-up');
    } catch (error) {
      toggle(false, { back: true });
      navigate('/?l=error-sign-up');
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={user?.displayName ? () => toggle(false) : () => null}
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
      id="login_form"
    >
      <form onSubmit={onSubmit} className="popup">
        <img alt="" src={logoCenterImage} />
        <h3>Bienvenue ! Veuillez saisir votre nom et prénom pour compléter la création de compte</h3>
        <div className="form_rep">
          <div className="elm">
            <label>
              Prénom<span>*</span>
            </label>
            <input
              onInput={(e) => setFirstName(e.target.value)}
              id="fname_plus"
              type="text"
              placeholder="Xaadim"
            />
          </div>
          <div className="elm">
            <label>
              Nom<span>*</span>
            </label>
            <input
              onInput={(e) => setLastName(e.target.value)}
              id="lname_plus"
              type="text"
              placeholder="Faal"
            />
          </div>
          <div className="elm">
            <label>
              Address<span>*</span>
            </label>
            <input
              onInput={(e) => setAddress(e.target.value)}
              id="address_plus"
              type="text"
              placeholder="Grand Yoff"
            />
          </div>
          <button
            disabled={
              loading ||
              !user?.phoneNumber ||
              !firstName ||
              !lastName ||
              !address?.trim() ||
              !regexValidName.test(firstName?.trim()) ||
              !regexValidName.test(lastName?.trim()) ||
              !acceptCgu
            }
            id="submit_plus"
          >
            {loading ? 'Chargement...' : 'Envoyer'}
          </button>
          <div
            className="o_cgu"
            style={{
              marginTop: 30,
            }}
          >
            <input
              value={acceptCgu}
              onChange={() => setAcceptCgu(!acceptCgu)}
              type="checkbox"
              name="cgu"
              id="cgu"
            />
            <label htmlFor="cgu">
              J'accepte les{' '}
              <Link
                to="/cgu#title"
                onClick={() => {
                  toggle(false, { back: true });
                }}
              >
                conditions générales d'utilisation
              </Link>
            </label>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SignUpModal;
