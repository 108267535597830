import React from 'react';
import OrangeImage from '../assets/images/icons/orange.png';
import WaveImage from '../assets/images/icons/wave.png';
import VisaImage from '../assets/images/icons/visa.svg';

const payments = [
  {
    id: 1,
    name: 'Orange Money',
    image: OrangeImage,
    value: 'orange_money',
  },
  {
    id: 2,
    name: 'Wave',
    image: WaveImage,
    value: 'wave',
  },
  {
    id: 3,
    name: 'Carte Bancaire',
    image: VisaImage,
    value: 'paydunya',
  },
];

const PaymentSelectModal = ({
  togglePaymentSelectModel,
  paymentSelectModel,
  payment,
  setPayment,
  validePayment,
}) => {
  return (
    <div
      style={{
        display: paymentSelectModel ? 'flex' : 'none',
      }}
      className="popup_overlay"
      onClick={() => togglePaymentSelectModel()}
    >
      <div
        className="popup sent_booking"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h4>Choisissez votre mode de paiement</h4>
        <div className="page_select">
          {payments.map((p) => (
            <div key={p.id} className="page_select_option">
              <input
                onChange={(e) => setPayment(e.target.value)}
                type="radio"
                defaultChecked={p.value === payment}
                value={p.value}
                name="payment"
                id={`payment-${p.id}`}
              />
              <label htmlFor={`payment-${p.id}`}>
                <img src={p.image} alt={p.name} width={40} height={40} />
                {p.name}
              </label>
            </div>
          ))}
        </div>

        <div className="buttons">
          <button className="button_primary button_empty" onClick={() => togglePaymentSelectModel()}>
            Annuler
          </button>
          <button
            className="button_primary close_popup"
            onClick={() => {
              togglePaymentSelectModel();
              validePayment();
            }}
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSelectModal;
