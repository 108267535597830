import React from 'react';
import { useNavigate } from 'react-router-dom';
import CartReturnArrowImage from '../../assets/images/icons/car_return_arrow.svg';
import { useCartStore } from '../../store/cart';
import { Link } from 'react-router-dom';
import Cart from '../../components/Cart';
import { formatNumberHumanReadable } from '../../utils';
import { useDocumentTitle } from 'usehooks-ts';

const CartPage = () => {
  useDocumentTitle('CAREF | Panier');
  const cart = useCartStore((state) => state.cart);
  const navigate = useNavigate();
  const retraitPrice = Math.round(0);
  const totalPrice = Math.round(
    cart.reduce((acc, item) => acc + item.price * item.quantity, 0)?.toFixed(2),
  );

  return (
    <section className="validation_container">
      <p onClick={() => navigate('/')}>
        <img src={CartReturnArrowImage} alt="back" />
        Continuer mes achats
      </p>
      <Cart cart={cart} />
      {cart.length !== 0 && (
        <div className="order_details">
          <div className="order_details_card">
            <div className="title">Votre commande</div>
            <div className="prices">
              <span>Prix total des marchandises</span>
              <span>{formatNumberHumanReadable(totalPrice)} FCFA</span>
            </div>
            <div className="prices hr">
              <span>Retrait en agence</span>
              <span>{formatNumberHumanReadable(retraitPrice)} FCFA</span>
            </div>
            <div className="prices bold">
              <span>Total de la commande</span>
              <span
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {formatNumberHumanReadable(+totalPrice + retraitPrice)} FCFA
              </span>
            </div>
            <div className="btn">
              <Link to="/order/validation">Valider mon panier</Link>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CartPage;
