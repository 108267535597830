import React, { useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import disponible from '../../assets/images/icons/disponible.svg';
import indisponible from '../../assets/images/icons/indisponible.svg';
import imageHolder from '../../assets/images/logo_center.svg';
import { API_URL } from '../../constant/api.js';
import { fetchApi } from '../../libs/fetch.js';
import { useAuthStore } from '../../store/auth.js';
import { useCartStore } from '../../store/cart.js';
import { useFavoriteStore } from '../../store/useFavorite';
import QuantityInput from './QuantityInput';
import Rating from './Rating';
import CartModal from '../../components/CartModal.jsx';
import { dateLivraison, formatNumberHumanReadable } from '../../utils/index.js';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { useSearchStore } from '../../store/search.js';

function CatalogItems({ piece, index }) {
  const navigate = useNavigate();
  const favorite = useFavoriteStore((state) => state.favorite);
  const addToFavorite = useFavoriteStore((state) => state.addToFavorite);
  const removeFromFavorite = useFavoriteStore((state) => state.removeFromFavorite);

  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);
  const [rating, setRating] = useState(0);
  const [lastRating, setLastRating] = useState(0);
  const cart = useCartStore((state) => state.cart);
  const cartAdd = useCartStore((state) => state.addToCart);
  const setDevisAutoFillPart = useSearchStore((state) => state.setDevisAutoFillPart);
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const { data: averageRating } = useFetch(`${API_URL}/ratings/note/${piece?.id}?rating=${rating}`);

  const existItem = cart.find((item) => item.id === piece.id && item.vendor === piece.vendor);
  const isFavorite = favorite.find((item) => item.caref === piece.caref);

  function incrementRating() {
    setRating(rating + 1);
  }

  const addToCart = (e) => {
    e.preventDefault();

    let existItem = cart.find((item) => item.id === piece.id && item.vendor === piece.vendor);

    if (existItem) return;

    cartAdd({
      ...piece,
      brand: piece?.bname,
      model: piece?.mname,
      quantity: 1,
    });
  };

  async function toggleFavorite(p) {
    try {
      if (isFavorite) {
        removeFromFavorite(p);

        if (user) {
          await fetchApi(`${API_URL}/likes/${customer.id}/${p?.product_id ?? p?.id}`, {
            method: 'DELETE',
          });
        }
      } else {
        addToFavorite({ ...p, brand: p?.bname, model: p?.mname });

        if (user) {
          const data = {
            user_id: user?.id,
            product_id: p?.id,
            customer_id: customer.id,
            price: p?.price,
            brand: p?.bname,
            category: p?.category,
            model: p?.mname,
            parts: p?.parts,
            link: p?.link,
            caref: p?.caref,
            vendor: p?.vendor,
          };

          const formData = new FormData();

          for (const key in data) {
            formData.append(key, data[key]);
          }

          await fetchApi(`${API_URL}/likes`, {
            method: 'POST',
            body: formData,
            Headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      }
    } catch (error) {
      console.error('add favorite', error);
    }
  }

  async function toggleRating(rating) {
    try {
      if (!user) return;

      await fetchApi(`${API_URL}/ratings/${customer.id}/${piece?.product_id ?? piece?.id}`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: user?.id,
          product_id: piece?.id,
          customer_id: customer.id,
          price: piece?.price,
          brand: piece?.bname,
          category: piece?.category,
          rating,
          model: piece?.mname,
          parts: piece?.parts,
          link: piece?.link,
          vendor: piece?.vendor,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('rating', error);
    }
  }
  const toogleCartModal = (e) => {
    e.preventDefault();
    setOpen(true);
    addToCart(e);
  };

  console.log('piece', piece);

  return (
    <div className="single_catalogue" key={index}>
      <CartModal open={open} setOpen={setOpen} />
      <div className="left_side">
        <div className="pieces_images">
          <img
            style={{
              width: '4.5rem',
              height: 'auto',
              scale: 1.2,
              objectFit: 'contain',
            }}
            alt=""
            src={piece?.link_vendor ?? imageHolder}
            className="img_catalogue"
          />
          <img
            style={{
              width: '7.3125rem',
              height: '6.8125rem',
              objectFit: 'contain',
            }}
            alt=""
            src={piece?.link ?? imageHolder}
            className="img_catalogue"
          />
          {/* rating */}
          <div className="mobile_rating hide_desktop">
            <Rating
              rating={averageRating ?? lastRating}
              toggleRating={toggleRating}
              incrementRating={incrementRating}
              setLastRating={setLastRating}
            />
          </div>
          <span className="hide_mobile">
            {' '}
            <BsInfoCircle /> Photo À titre indicatif
          </span>
        </div>
        <span
          className="hide_desktop"
          style={{
            color: '#858080',
            fontSize: '0.9375rem',
            lineHeight: '1.5rem',
            letterSpacing: '-0.00563rem',
            fontFamily: 'Oxanium',
          }}
        >
          {' '}
          <BsInfoCircle /> Photo À titre indicatif
        </span>
      </div>
      <div className="catalogue_details">
        <div className="top_details">
          <p>
            <strong>{piece?.parts}</strong>
            <span>{piece?.joint_side}</span>
            <span
              style={{
                color: '#eb550e',
              }}
            >
              CAREF: {piece?.caref}
            </span>
          </p>
          <div className="hide_desktop description">
            <div className="availability">
              {piece?.availability === 'En stock' && (
                <>
                  <img src={disponible} alt="" />
                  <span>En stock</span>
                </>
              )}
              {piece?.availability === 'Disponible' && (
                <>
                  <img src={disponible} alt="" />
                  <span>Disponible</span>
                </>
              )}
              {piece?.availability !== 'Disponible' && piece?.availability !== 'En stock' && (
                <>
                  <img src={indisponible} alt="" />
                  <span>Indisponible</span>
                </>
              )}
            </div>
            {/* Utilisez le prix spécifique au fabricant ici */}
            <strong className="price">{formatNumberHumanReadable(piece?.price)} Fcfa</strong>
            <p>{piece?.deliveryDate}</p>
          </div>
          <div className="rating hide_mobile">
            <Rating
              rating={averageRating ?? lastRating}
              toggleRating={toggleRating}
              incrementRating={incrementRating}
              setLastRating={setLastRating}
            />
          </div>
          {piece?.availability === 'En stock' || piece?.availability === 'Disponible' ? (
            <span
              className="hide_desktop"
              style={{
                marginTop: '2rem',
              }}
            >
              {dateLivraison(piece?.availability)}
            </span>
          ) : null}
        </div>
        <div className="bottom_details hide_mobile">
          <h3>Informations sur le produit:</h3>
          <div className="table">
            <div className="row">
              <span>Catègories :</span>
              <span className="bold">{piece?.category}</span>
            </div>
            <div className="row gray_row">
              <span>Type :</span>
              <span className="bold"> {piece?.sous_category}</span>
            </div>
            <div className="row">
              <span>Forme & dimensions :</span>
              <span className="bold">Compatible</span>
            </div>
            <div className="row gray_row">
              <span>Référence caref :</span>
              <span className="bold">{piece?.caref}</span>
            </div>
            <div className="row">
              {/* Utilisez le prix spécifique au fabricant ici */}
              <span>Prix :</span>
              <span className="bold">{formatIncompletePhoneNumber(piece?.price)} FCFA</span>
            </div>
            <div className="row gray_row">
              <span>Etat : </span>
              <span className="bold">{piece?.status ?? 'Neuf'}</span>
            </div>
            <div className="row">
              <span>Nota :</span>
              <span className="bold">{piece?.tech_infos}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="catalogue_right">
        <div
          style={{ cursor: 'pointer' }}
          className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart hide_mobile`}
          onClick={() => toggleFavorite(piece)}
        ></div>
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
          }}
          className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart hide_desktop`}
          onClick={() => toggleFavorite(piece)}
        ></div>
        <div className="price_down">
          <h4 className="hide_mobile">{formatNumberHumanReadable(piece?.price)} FCFA</h4>
          <p className="hide_mobile">
            <span>
              {piece?.availability === 'En stock' && (
                <>
                  <img src={disponible} alt="" />
                  <span>En stock</span>
                </>
              )}
              {piece?.availability === 'Disponible' && (
                <>
                  <img src={disponible} alt="" />
                  <span>Disponible</span>
                </>
              )}
              {piece?.availability !== 'Disponible' && piece?.availability !== 'En stock' && (
                <>
                  <img src={indisponible} alt="" />
                  <span>Indisponible</span>
                </>
              )}
            </span>
            {piece?.availability === 'En stock' || piece?.availability === 'Disponible'
              ? dateLivraison(piece?.availability)
              : null}
          </p>
          <div
            onClick={() => {
              setShowDetail(!showDetail);
              setTimeout(() => {
                document.getElementById(`details_peace_${piece?.id}`)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }, 200);
            }}
            className="details_button hide_desktop"
          >
            Details{' '}
            {!showDetail ? (
              <i className="fa-solid fa-chevron-down"></i>
            ) : (
              <i className="fa-solid fa-chevron-up"></i>
            )}
          </div>
          <form action="#">
            {piece?.availability === 'En stock' || piece?.availability === 'Disponible' ? (
              !existItem ? (
                <button className={'open_added_to_cart'} onClick={(e) => toogleCartModal(e)}>
                  <i className="fa-solid fa-cart-shopping"></i>
                  <span className="hide_mobile">Panier</span>
                </button>
              ) : (
                <>
                  <QuantityInput piece={existItem} />
                  <p
                    style={{
                      color: '#002F5D',
                      fontSize: '0.9375rem',
                      lineHeight: '1.5rem',
                      letterSpacing: '-0.00563rem',
                      fontFamily: 'Oxanium',
                      textAlign: 'center',
                    }}
                  >
                    Ajouté au panier
                  </p>
                </>
              )
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setDevisAutoFillPart(piece);
                  navigate('/#demande_devis');
                }}
                className="devis_button"
                style={{ backgroundColor: '#002F5D' }}
              >
                <span>Devis</span>
              </button>
            )}
          </form>
        </div>

        {showDetail && (
          <div
            style={{
              marginTop: '1.5rem',
            }}
            id={`details_peace_${piece?.id}`}
            className="hide_desktop bottom_details_mobile"
          >
            <h3>Informations sur le produit:</h3>
            <div className="table">
              <div className="row">
                <span>Catègories :</span>
                <span className="bold">{piece?.category}</span>
              </div>
              <div className="row gray_row">
                <span>Type :</span>
                <span className="bold"> {piece?.sous_category}</span>
              </div>
              <div className="row">
                <span>Forme & dimensions :</span>
                <span className="bold">Compatible</span>
              </div>
              <div className="row gray_row">
                <span>Référence caref :</span>
                <span className="bold">{piece?.caref}</span>
              </div>
              <div className="row">
                {/* Utilisez le prix spécifique au fabricant ici */}
                <span>Prix :</span>
                <span className="bold">{formatNumberHumanReadable(piece?.price)} FCFA</span>
              </div>
              <div className="row gray_row">
                <span>Etat : </span>
                <span className="bold">{piece?.status ?? 'Neuf'}</span>
              </div>
              <div className="row">
                <span>Nota :</span>
                <span className="bold">{piece?.tech_infos}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CatalogItems;
