import React, { Fragment } from "react";

function Subtitles() {
  return (
    <Fragment>
      <h2>CATALOGUE DES PIECES D'OCCASION</h2>
      <p>
        <span>Note:</span> Si la pièce que vous cherchez n'est pas disponible,
        veuillez <a href="/#demande_devis"> demander un devis</a>
        <br />
        <span>
          <span></span>
        </span>
        Pour <span>le référencement</span> et
        <span> la publication</span> de votre stock de pièces détachées,
        veuillez &nbsp;
        <a href="mailto:contact@carefautomobile.com">nous contacter</a>
      </p>
    </Fragment>
  );
}

export default Subtitles;
