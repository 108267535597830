import React from 'react';

const PaymentWarningModal = ({ paymentWarningModel, togglePaymentWarningModel }) => {
  return (
    <div
      style={{
        display: paymentWarningModel ? 'flex' : 'none',
      }}
      className="popup_overlay"
      onClick={() => togglePaymentWarningModel()}
    >
      <div className="popup sent_booking" onClick={(e) => e.stopPropagation()}>
        <i className="fas fa-exclamation-triangle orange"></i>

        <h4>Ce mode payment n'est pas encore disponible!</h4>
        <div className="buttons">
          <button className="button_primary close_popup" onClick={() => togglePaymentWarningModel()}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentWarningModal;
