import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Menu = ({
  menuRef,
  showMenu,
  closeMenu,
  toggleSubMenu,
  setSelectedPartCategory,
  setSelectedPartSubCategories,
  selectedPartCategory,
  selectedPartSubCategories,
  partCategories,
  partCategoriesError,
  partSubCategories,
  partSubCategoriesError,
  parts,
  partsError,
  handleSearchCatalogPeace,
}) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`menu hide_mobile`}>
      <div className="menu_list">
        <Link onClick={toggleSubMenu} className="list_title" data-container-target="container_1">
          CATALOGUE DES PIÈCES
        </Link>
        <div className="submenu_container hide" id="container_1">
          {partCategories &&
            !partCategoriesError &&
            partCategories?.map((partCategory) => (
              <Link
                key={partCategory.id}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedPartCategory(partCategory.id);
                }}
                className={`submenu ${selectedPartCategory === partCategory.id ? 'active_link' : ''}`}
                data-submenu-target="sub_2_1"
              >
                {partCategory.nom}
              </Link>
            ))}
          {!partCategories && !partCategoriesError && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <Link onClick={toggleSubMenu} className="list_title" data-container-target="container_2">
          entretien & réparation
        </Link>
        <div className="submenu_container hide" id="container_2">
          <Link
            to="/diagnostic#service"
            onClick={closeMenu}
            className="submenu"
            data-submenu-target="sub_2_1"
          >
            Services
          </Link>
          <Link
            to="/diagnostic#forfaits"
            onClick={closeMenu}
            className="submenu"
            data-submenu-target="sub_2_2"
          >
            Forfaits
          </Link>
          <Link
            to="/diagnostic#abonnements"
            onClick={closeMenu}
            className="submenu"
            data-submenu-target="sub_2_3"
          >
            Abonnements
          </Link>
        </div>
        <Link onClick={toggleSubMenu} className="list_title" data-container-target="container_3">
          services de consulting
        </Link>
        <div className="submenu_container hide" id="container_3">
          <Link
            to="/consulting-services#notre_service"
            className="submenu"
            data-submenu-target="sub_3_1"
            onClick={closeMenu}
          >
            Services
          </Link>
          <Link
            to="/consulting-services#packs"
            onClick={closeMenu}
            className="submenu"
            data-submenu-target="sub_3_2"
          >
            Packs
          </Link>
        </div>
        <Link onClick={toggleSubMenu} className="list_title" data-container-target="container_4">
          ventes d'occasions
        </Link>
        <div className="submenu_container hide" id="container_4">
          <Link
            to="/sale/bons_plans_piece#navbar"
            className="submenu"
            data-submenu-target="sub_3_1"
            onClick={closeMenu}
            id="parts"
          >
            Pièces détachées
          </Link>
          <Link
            to="/sale/bons_plans_auto#navbar"
            className="submenu"
            data-submenu-target="sub_3_2"
            onClick={closeMenu}
            id="vehicles"
          >
            Véhicules
          </Link>
        </div>
      </div>
      <div className={`mid_level`}>
        {partSubCategories &&
          !partSubCategoriesError &&
          partSubCategories?.map((partSubCategory) => (
            <Link
              key={partSubCategory.id}
              onClick={(e) => {
                e.preventDefault();
                setSelectedPartSubCategories(partSubCategory.id);
              }}
              className={`submenu ${
                selectedPartSubCategories === partSubCategory.id ? 'active_link' : ''
              }`}
              data-submenu-target="sub_2_1"
            >
              {partSubCategory.nom}
            </Link>
          ))}

        {!partSubCategories && !partSubCategoriesError && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <div className={`leaf_level`} data-submenu="sub_1">
        {parts &&
          !partsError &&
          parts?.map((part) => (
            <Link
              key={part.id}
              className="submenu_content"
              onClick={(e) => {
                e.preventDefault();
                handleSearchCatalogPeace(part);
              }}
            >
              {part.parts}
            </Link>
          ))}

        {!parts && !partsError && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
