import React from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

const CGU = () => {
  const navigate = useNavigate();
  useDocumentTitle('CAREF | CGU');

  return (
    <section className="cgu">
      <div>
        <i
          onClick={() => navigate(-1)}
          style={{
            fontSize: '1.5rem',
            cursor : 'pointer'
          }}
          className="fas fa-arrow-left"
        ></i>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'center',
        }}
        id="title"
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'blue' }}>
            Conditions générales d’utilisation et de ventes du site internet de CAREF Automobile
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            &nbsp;1 - Présentation de CAREF Automobile &nbsp;et du site internet
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          CAREF Automobile est une société spécialisée dans l’entretien et la maintenance automobile
          créée par des ingénieurs mécatroniques et en partenariat &nbsp;avec des entreprises
          internationales expertes dans le domaine de l’aftermarket automobile (c’est -à -dire l'après
          -vente).
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>Notre site E-commerce&nbsp;</span>
        <a href="http://www.carefautomobile.com">
          <span style={{ fontSize: 13, lineHeight: '150%', color: '#1155cc' }}>
            WWW.CAREFAUTOMOBILE.COM
          </span>
        </a>
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          &nbsp; propose des ventes de pièces de rechange automobiles neuves. Nous proposons également
          des solutions de prise de rendez-vous en ligne pour des services de diagnostic, d’entretien et
          de &nbsp;réparation automobiles, sous forme de forfaits et d’abonnements. Egalement, vous
          pouvez consulter et acheter les pièces détachées et des véhicules d’occasion en vente par CAREF
          ou par nos partenaires.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>2</span>
        </strong>
        <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
          &nbsp;-<strong>&nbsp;Inscription</strong>
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.1 - Une inscription est nécessaire pour effectuer des achats sur la boutique en ligne de
            CAREF. Cela peut être effectué dans le cadre du processus de commande, mais aussi
            indépendamment d'une commande. Votre compte sera créé après votre inscription afin de vous
            permettre essentiellement de pouvoir effectuer vos achats de pièces de rechange neuves ou
            d’occasion.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.2 - Seules les personnes physiques individuelles, majeures et légalement responsables
            peuvent créer des comptes. Les inscriptions par famille ou par couple ne sont pas
            autorisées.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les personnes morales (entreprises, les professionnels) sont admises à l’inscription.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Cette condition s'applique également pour la prise de commandes. Si une entreprise est
            enregistrée en tant que client, le client garantit qu'il est bien habilité à représenter
            l'entreprise.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.3 - Lors de votre inscription, vous devez indiquer votre nom, prénom ainsi que votre numéro
            de téléphone auquel un code OTP (One Time Password) vous sera transmis pour des raisons de
            sécurité afin de valider votre identification et votre inscription.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.4 - L’envoi du formulaire d'inscription rempli vaut une demande ferme et définitive
            d’adhésion.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Le client est tenu responsable des informations fournies lors de son inscription et doit
            veiller à vérifier les données saisies dans le formulaire d’inscription avant sa validation
            définitive.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.5 - Un message de bienvenue vous sera adressé directement sur le site dès que vous aurez
            terminé le processus d'inscription. La réception de ce message conclut l'inscription de votre
            compte.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.6 - Vous garantissez l’exactitude des informations fournies lors de votre inscription. En
            cas d’information fausse ou incomplète, nous sommes en droit de résilier et de supprimer
            votre compte sans préavis. Vous vous engagez à communiquer spontanément et immédiatement
            toute modification future de vos données personnelles.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.7 - Le compte peut être clôturé conformément au point 3 ci-dessous. Sous réserve
            d’éventuelle(s) obligation(s) légale(s) de conservation, vos données personnelles seront
            alors immédiatement supprimées.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.8 - Vous n'êtes en aucun cas autorisé à partager vos données d'accès et en particulier
            votre mot de passe avec un tiers. Au cas où un tiers tenterait d'accéder à votre compte sans
            votre accord ou que vous avez connaissance d'une utilisation abusive de votre compte, vous
            devez nous en informer sans délai et modifier vos données personnelles de connexion.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            2.9 - Toute demande d‘inscription nécessite des informations uniques à savoir &nbsp;un nom,
            un prénom, un numéro &nbsp;de téléphone et un code OTP qui sera délivré sur ce dernier.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Ce numéro de téléphone doit être lié officiellement au nom du client qui doit s’inscrire.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Vous êtes autorisé à vous inscrire qu'une seule fois sur le site.
          </span>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
        id="pdc"
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            3 - Politique de confidentialité du site&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Le présent politique de confidentialité &nbsp;décrit la façon dont vos données personnelles
          sont recueillies, utilisées et partagées lorsque vous vous rendez sur le site
          www.carefautomobile.com pour effectuer un achat en ligne ou des réservations de services.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Lorsque vous visitez notre site WEB, nous recueillons automatiquement certaines informations
          personnelles concernant votre appareil, notamment des informations sur le navigateur web, votre
          adresse IP, votre fuseau horaire &nbsp;et certains cookies qui sont installés dans votre
          appareil.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginLeft: '0in',
          textAlign: 'justify',
          textIndent: '0in',
          background: 'white',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            3.1 - &nbsp;Traitement des données sur notre site Web
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginLeft: '0in',
          textAlign: 'justify',
          textIndent: '0in',
          background: 'white',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </strong>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              3.1.1 - &nbsp;Consultation de notre site Web/ données d’accès
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            À chaque visite de notre site Internet, notre système saisit automatiquement des données et
            des informations du système informatique de l’ordinateur sur lequel le client appelant qui
            effectue les requêtes.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les données suivantes sont collectées dans ce cadre :
          </span>
        </p>
      </div>
      <ul style={{ marginLeft: '0.5in' }}>
        <li>
          <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
            informations concernant le type de navigateur et la version utilisée
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>
            système d’exploitation de l’utilisateur
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>
            fournisseur d’accès Internet de l’utilisateur
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>
            adresse IP de l’utilisateur
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
            date et heure de la consultation
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
            sites Internet depuis lesquels le système de l’utilisateur accède à notre site Internet
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
            sites Internet consultés par le système de l’utilisateur à partir de notre site Internet
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>etc.</span>
        </li>
      </ul>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Le traitement de ces données d’accès est nécessaire pour permettre une consultation du site
            Web et pour garantir le fonctionnement permanent et la sécurité de nos systèmes. Par
            ailleurs, les données d’accès sont enregistrées temporairement dans des fichiers journaux
            internes aux fins décrites ci-dessus, afin d’établir des données statistiques sur
            l’utilisation de notre site Web, de perfectionner notre site Web en fonction des habitudes
            d’utilisation de nos visiteurs (par ex. en cas d’augmentation de la proportion d’appareils
            mobiles utilisés pour accéder aux pages) et plus généralement, pour la maintenance
            administrative de notre site Web.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les données sont effacées dès lors qu’elles ne sont plus nécessaires aux fins pour lesquelles
            elles avaient été collectées. En cas de collecte des données pour la mise à disposition du
            site Internet, il s’agit du moment auquel la session respective prend fin. En cas
            d’enregistrement des données dans des fichiers journaux, cet effacement intervient, en règle
            générale, après sept jours au plus tard. Dans un tel cas, les adresses IP de l’utilisateur
            sont effacées ou rendues méconnaissables pour éviter tout recoupement avec le client
            appelant.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              3.1.2 - Prise de contact
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Il existe plusieurs possibilités pour prendre contact avec nous. Cela englobe les formulaires
            de contact et la prise de contact par courrier électronique ou par téléphone. Dans ce cadre,
            nous traitons exclusivement les données aux fins de communiquer avec vous.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les données que nous recueillons lorsque vous utilisez le formulaire de contact seront
            automatiquement supprimées dès le traitement de votre demande achevé, hormis dans les cas où
            nous devons conserver votre demande pour nous conformer à des obligations contractuelles ou
            légales pour une durée de conservation de 36 mois.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              3.1.3 - Formulaires de contact
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Vous trouverez sur notre site Internet des formulaires de contact qui peuvent être utilisés
            pour prendre contact avec nous par voie électronique.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Lorsqu’un utilisateur utilise cette fonctionnalité, les données obligatoires suivantes nous
            sont communiquées et sont enregistrées :
          </span>
        </p>
      </div>
      <ul style={{ marginLeft: '0.5in' }}>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>Numéro de commande</span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>Numéro de téléphone</span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
            Prénom et nom (en option)
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>Adresse électronique</span>
        </li>
        <li>
          <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>Numéro d’article</span>
        </li>
      </ul>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Au moment de l’envoi du message, les données suivantes sont enregistrées :
          </span>
        </p>
        <ul style={{ marginLeft: '0.5in' }}>
          <li>
            <span style={{ lineHeight: '150%', color: 'black', fontSize: 13 }}>Adresse IP</span>
          </li>
          <li>
            <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
              Date et heure de l’ enregistrement de la demande
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              3.1.4 - Prise de contact par courrier électronique
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            En guise d’alternative au formulaire de contact, vous pouvez également prendre contact avec
            nous par écrit, à l’adresse électronique&nbsp;
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'blue' }}>
            contact@carefautomobile.com
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            . Dans une telle hypothèse, les données à caractère personnel de l’utilisateur communiquées
            dans le courrier électronique sont enregistrées.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les données sont effacées dès lors qu’elles ne sont plus nécessaires aux fins pour lesquelles
            elles avaient été collectées. Dans le cas d’une correspondance professionnelle, nous
            conserverons ces données pendant trois ans au maximum.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              3.1.5 - Prise de contact par téléphone
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Vous avez également la possibilité de nous joindre par téléphone au&nbsp;
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'blue' }}>00221 77 355 52 52</span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            . En cas de besoin et si vous nous donnez votre consentement en début d’appel, nous pouvons
            enregistrer notre conversation.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Nous conserverons les informations communiquées lors de l’entretien téléphonique pour la
            formation de nos collaborateurs du support clients et pour l’assurance qualité de notre
            centre d’appels et nous les supprimerons, en principe, dans les trois mois, sauf nécessité
            d’une conservation plus longue pour satisfaire à des obligations légales ou à des fins de
            preuve.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: 13,
                lineHeight: '150%',
                color: 'black',
                background: 'white',
              }}
            >
              3.1.6 - Utilisation de cookies et de technologies similaires
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Nous utilisons, sur notre site Web, des cookies et des technologies similaires
            (collectivement dénommés les « outils ») proposés soit par nous-mêmes, soit par des tiers.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les « cookies » sont de petits fichiers textes qui sont enregistrés sur votre appareil par le
            navigateur. Les cookies ne sont pas utilisés pour exécuter des programmes ou installer des
            virus sur votre ordinateur. Les technologies similaires sont notamment le stockage web local
            (stockage local / par session), les empreintes, les balises ou les pixels
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Certains cookies demeurent un certain temps sur votre ordinateur et nous permettent de
            reconnaître votre ordinateur lors de votre prochaine visite (cookies permanents). Nos
            partenaires ne sont pas en droit de collecter, traiter ou utiliser des données à caractère
            personnel par le biais de notre site Web au moyen de cookies.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            La plupart des navigateurs acceptent les cookies et les technologies similaires par défaut.
            Toutefois, vous pouvez généralement paramétrer votre navigateur de sorte qu’il bloque les
            cookies ou les technologies similaires ou qu’ils ne soient stockés que moyennant votre
            consentement préalable.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Dans les paramètres de sécurité, vous pouvez également autoriser ou bloquer les cookies
            temporaires et permanents de manière indépendante les uns des autres. Si vous désactivez les
            cookies, vous ne pourrez pas, le cas échéant, utiliser certaines fonctionnalités de notre
            site Web, certaines pages web risquent de ne pas s’afficher correctement et nos offres sont
            susceptibles de ne pas fonctionner correctement sur votre ordinateur.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &nbsp;À titre d’exemple, pour utiliser notre panier d’achat, vous devrez autoriser les
            cookies temporaires.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &nbsp;Les données stockées dans nos cookies ne sont pas recoupées avec les données à
            caractère personnelles vous concernant (nom, prénom, téléphone, adresse, etc.). Nous ne
            récupérons pas, sans votre consentement exprès, les données stockées dans nos cookies avec
            les données à caractère personnelles vous concernant (nom, prénom, téléphone, adresse, etc.).
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Vous pouvez supprimer les cookies déjà enregistrés à tout moment en sélectionnant l’option de
            menu correspondante dans votre navigateur Internet ou en supprimant les cookies sur votre
            disque dur. Vous trouverez plus d’informations à ce sujet dans le menu d’aide de votre
            navigateur Internet.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les cookies de sessions sont supprimés lorsque vous quittez le site Internet et sont
            uniquement utilisés afin que la navigation sur le site et la consultation des contenus soient
            aussi conviviales que possible et puissent être prises en compte dans les statistiques.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              4 - Condition générale de vente (CGV)
            </span>
          </strong>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les présentes Conditions Générales de Vente (ci-après dénommées « CGV ») régissent les règles
          de vente et d’achat des produits mis en vente sur internet&nbsp;
        </span>
        <a href="http://www.carefautomobile.com">
          <span style={{ fontSize: 13, lineHeight: '150%', color: '#1155cc' }}>
            www.carefautomobile.com
          </span>
        </a>
        <span style={{ fontSize: 13, lineHeight: '150%' }}>, dénommé CAREF Automobile.</span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les CGV ne concernent qu’en principe &nbsp;les automobilistes, les garagistes, les vendeurs et
          les acheteurs de véhicules neufs ou d’occasions, professionnels ou particuliers.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les CGV définissent les étapes nécessaires à la passation de la commande, de l’achat et
          permettent de suivre l’acheminement de chaque commande conclue, à l’exclusion de toute autre
          convention présente ou non sur le site.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les présentes CGV doivent obligatoirement être acceptées lors de l’inscription pour tout achat
          effectué sur &nbsp;le Site.&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          En ayant accès au site, vous confirmez votre compréhension des conditions générales
          d’utilisation et consentez à les respecter. Si vous n’acceptez pas les présentes CGV, nous vous
          prions de ne pas entrer, ni vous enregistrer ou encore d’utiliser le site.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.1 - Conclusion d'un contrat d'achat</span>
        </strong>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &nbsp;En ce qui concerne l’achat d’un article, la conclusion d'un contrat se déroule comme
            suit:
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.1 - vous devez tout d'abord rechercher et ensuite ajouter les articles que vous désirez à
            votre panier en cliquant sur le bouton correspondant afin de commencer le processus de
            commande,
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.2 - Avant l'achat définitif des articles figurant dans votre panier, le contenu total de
            la commande vous sera à nouveau présenté. Vous pouvez donc modifier votre commande à nouveau.
            Nous sauvegardons vos commandes après la conclusion du contrat, mais vous n'y aurez plus
            accès après le processus de commande et de paiement.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Nous vous prions de vérifier attentivement votre commande avant de cliquer sur le bouton
            «Commander et Payer».
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.3 - En cas de recours à des modes de paiement impliquant un règlement immédiat de votre
            part (Wave, Orange money, Carte bancaire, etc.), notre consentement est donné dès la
            conclusion de l’achat, soit dès que vous cliquez sur le bouton «Commander et Payer» &nbsp;sur
            la page de votre panier numérique et que vous validez votre paiement dans les plateformes de
            paiement mobile (OM, Wave) ou par carte bancaire (CB) avec une confirmation de celui-ci.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.4 - &nbsp;En cas de recours à des modes de paiement qui n’impliquent pas un règlement
            immédiat de votre part, vous faites une demande de devis via notre site ou bien en nous
            contactant par téléphone ou par email. Ensuite nous vous fournissons une offre alignée avec
            votre demande de devis pour une confirmation et un paiement par virement ou par chèque
            bancaire.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Le simple fait de sélectionner les produits dans le panier en ligne n'est pas constitutif
            d’une offre ferme.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Nous vous confirmerons automatiquement la réception de la commande par téléphone ou par
            E-mail au cas échéant.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            La seule réception de la confirmation de la commande ne vaut pas obligatoirement acceptation
            définitive de la commande en cas de mode de paiement non-immédiat. Notre acceptation
            n’interviendra qu'à compter de la confirmation de l'envoi de la commande. En cas de règlement
            par virement électronique ou par chèque (option de paiement en avance), la confirmation de la
            commande reçue vaudra également acceptation de notre part à la conclusion du contrat.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.5 - Vous pouvez imprimer les informations de votre commande dès l'envoi de celle-ci. Si
            vous souhaitez à nouveau vérifier les documents de votre commande, contactez-nous par e-mail
            ou par téléphone. Nous vous enverrons volontiers une copie des données de votre commande.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.6 - Il est possible de modifier une commande avant qu'elle n'ait quitté notre entrepôt ou
            qu'elle n'ait été remise à un transporteur (donc avant l'envoi d'un numéro de suivi et avant
            la confirmation de l'envoi par téléphone ou par e-mail). Ceci n'affecte pas vos droits légaux
            en termes de garantie et{'{'}' '{'}'}
            <span style={{ background: 'white' }}>droit de rétractation.</span>
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.1.7 - Nous nous réservons également le droit d'annuler le contrat si les produits ne sont
            pas disponibles chez nos fournisseurs, sans que cela ne constitue une faute de notre part.
            Dans un cas pareil, nous nous engageons à vous informer de la non-disponibilité du produit et
            à vous rembourser dans les meilleurs délais.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Afin de pouvoir conclure une affiliation CAREF Automobile, il est nécessaire d’avoir un
            compte client CAREF. L’affiliation CAREF est associée à votre compte client CAREF. Si vous ne
            disposez pas encore d’un compte client CAREF, vous pouvez en ouvrir un en utilisant la
            fonction d’enregistrement qui figure sur le site carefautomobile.com. Dans ce cas, nos
            conditions générales disponibles sur le site&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a href="https://www.auto-doc.fr/services/cgv">
              <span
                style={{
                  fontSize: 13,
                  lineHeight: '150%',
                  color: 'blue',
                  textDecoration: 'none',
                }}
              >
                https://www.
              </span>
            </a>
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'blue' }}>
            carefautomobile.com&nbsp;
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>&nbsp;s’appliquent.</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Pour conclure une affiliation CAREF Automobile sur la base de nos différents propositions
            commerciales avantageuses pour le client &nbsp;(abonnés, compte pro, etc.), nous contacter
            par téléphone ou par email afin de prendre en charge votre besoin d’affiliation et vous
            proposer des solutions techniques et commerciales, ensuite effectuer la liaison directement
            avec votre compte CAREF afin de pouvoir bénéficier des avantages de celle-ci.
          </span>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            4. 2 - Disponibilité des articles &nbsp; &nbsp; &nbsp;&nbsp;
          </span>
        </strong>
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          &nbsp; &nbsp;
          <strong>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
          </strong>
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les offres présentées par CAREF sont valables tant qu’elles sont annoncées sur le site et dans
          la limite des stocks disponibles.&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          En cas d’indisponibilité de l’article commandé, CAREF se réserve le droit de vous livrer un
          article d’une autre marque de la même gamme et qualité, et si besoin d’en informer le client.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          En tout état de cause, et dans l’éventualité d’une indisponibilité totale ou partielle de
          produits après passation de la commande l’acheteur sera informé par téléphone, par mail ou par
          message dès que &nbsp;possible de l’indisponibilité du produit. CAREF se réserve le droit de
          proposer une solution alternative (par exemple une autre marque, article d’occasion, etc.)
          avant l’annulation totale ou partielle de sa commande.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          En cas d’annulation totale de la commande :
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          - &nbsp; &nbsp; &nbsp; &nbsp;La commande de l’acheteur sera automatiquement annulée et le
          montant payé sera remboursé sans frais si l’annulation intervient avant l'envoi de la commande.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          En cas d’annulation partielle de la commande :
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          - &nbsp; Le service clients de CAREF Automobile prend contact avec l’acheteur pour l’informer
          de l’indisponibilité de l’article (s) et lui propose soit de lui rembourser le montant de
          l’article manquant, ou d’attendre la disponibilité sans date d’engagement de la part de
          CAREF.&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Au cas où le client n’accepte pas l’annulation, une annulation totale sera considérée.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
        id="paymentOnline"
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            4.3 - Moyen et modalité de paiement en ligne&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.3.1 - Moyen de paiement</span>
        </strong>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            - Nous vous proposons différents moyens de paiement pour le règlement des marchandises
            commandées. Vous trouverez les modalités de paiement exactes dans notre site.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            - Tous les prix dans notre boutique en ligne sont indiqués en FCFA et comprennent la TVA. Les
            prix s'entendent au départ de l'entrepôt et comprennent l'emballage.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            - Le prix des marchandises est valable au moment de la commande et est indiqué dans la
            confirmation de celle-ci, que vous recevrez par téléphone ou email immédiatement avec le
            montant total de la commande après l'avoir passée. Si les articles sont enregistrés dans le
            panier, leur prix total est actualisé en fonction du prix actuel en vigueur pendant le
            processus de commande.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            - Une fois l'acte de vente conclu entre CAREF et le client, le montant total à payer est
            exigible immédiatement. Vous acceptez de recevoir les factures également sous forme
            électronique.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            - CAREF Automobile vous donne la possibilité de faire un paiement total, ou bien en cas
            d’affiliation ou de promotion de faire un paiement partiel lors de la commande et ensuite
            payer le reliquat lors de la réception de celle-ci.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Vous devrez sélectionner l'option de paiement (orange money ,wave, carte bancaire, etc.) lors
            de votre commande. Vous recevrez ensuite un message ou un e-mail de confirmation de votre
            commande, contenant un numéro de commande, que vous devrez indiquer pour retrait de l’article
            ou à la livraison.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            L'envoi des produits de votre commande sera effectué dès la réception des fonds.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <div
          style={{
            margin: '0in',
            lineHeight: '115%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
          }}
        >
          <ul
            style={{
              marginBottom: '0in',

              marginLeft: '0.5in',
            }}
          >
            <li
              style={{
                margin: '0in',
                lineHeight: '115%',
                fontSize: 15,
                fontFamily: '"Arial", sans-serif',
              }}
            >
              <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
                Au cas ou vous avez choisi un paiement mobile (OM, Wave):
              </span>
            </li>
          </ul>
        </div>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &gt; Avec l’utilisation de votre téléphone mobile:&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Après avoir valider votre panier et appuyer sur «Commander et Payer», vous êtes redirigé
            directement sur la plateforme des paiements mobile avec votre numéro de téléphone de compte
            paiement mobile sur la page CAREF avec le montant total de la commande que vous devez valider
            et ensuite confirmer le paiement. Ensuite un message de confirmation de votre paiement vous
            sera renvoyé. Ce qui confirme la conclusion d’achat.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &gt; Avec l’utilisation de votre ordinateur / tablette:&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Après avoir valider votre panier et appuyer sur «Commander et Payer», vous êtes redirigé
            directement sur la plateforme des paiements mobile avec votre numéro de téléphone de compte
            paiement mobile sur la page CAREF avec l’apparition d’un QR code que vous devez scanner afin
            de payer le montant de la commande.&nbsp;
          </span>
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Ce qui confirme la conclusion d’achat.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
      </div>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
          marginLeft: '0.25in',
          marginRight: '0in',
        }}
      >
        <ul style={{ marginLeft: '-0.25in' }}>
          <li>
            <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
              Au cas où vous avez choisi la carte de crédit comme méthode de paiement:
            </span>
          </li>
        </ul>
      </div>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Veuillez cliquer sur « Commander et Payer». Vous serez redirigé vers une page sécurisée sur
            laquelle vous pouvez choisir votre type de carte de crédit. Vous devez ensuite renseigner les
            espaces avec vos informations de carte de crédit en cours de validité et confirmer le
            paiement. L'argent sera débité de votre compte associé à votre carte de crédit après la
            commande.
          </span>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
        id="delivery"
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            4.3.2 - Envoi et Livraison de la commande
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Au cours de la validation de chaque commande, l’acheteur concerné doit obligatoirement choisir
          le mode de livraison de sa commande, soit par retrait chez CAREF, soit par &nbsp;livraison sur
          site à l’adresse indiquée obligatoire par le client.&nbsp;
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <strong>
            <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
              4.3.3 - Choix de zone de livraison
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Avec CAREF Automobile, vous bénéficiez d'une livraison:
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &gt; &nbsp;Pour les pièces disponibles en stock au niveau de notre plateforme à Dakar: moins
            de 24h ouvrable pour les livraisons à Dakar, et moins de 72h ouvrable pour les autres
            régions.&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            &gt; Pour les pièces disponibles en stock au niveau de notre plateforme à l’étranger ou chez
            nos fournisseurs partenaires étrangers: entre 3 à 5 jours ouvrables pour les livraisons à
            Dakar, et entre 5 à 8 jours ouvrables pour les livraisons dans les autres régions du Sénégal.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Les tarifs de livraisons de chez CAREF (Dakar) jusqu’à l’adresse de livraison sont à la
            charge du client.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Pour le choix du paiement à la livraison est parfois disponible en cas de promotion et peut
            être opté par le client, ce dernier peut payer lors de la livraison par orange money, par
            wave, par carte bancaire, etc. lors de la réception de la commande.
          </span>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Pour tous paiements en ligne, le site n'accède et ne conserve aucune donnée bancaire, seuls les
          organismes de paiement y ayant accès.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Chaque acheteur concerné est seul et entier responsable des éventuelles autorisations
          nécessaires autant dans le choix du mode de paiement qu’au règlement de sa commande.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
            4.3.4 - Transport et risque de pertes de la marchandise
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          Le risque de perte ou de détérioration accidentelle de la marchandise vous est communiqué au
          moment de l'expédition de la marchandise, c'est-à-dire lorsque celle-ci est remise à
          l’organisme ou la personne effectuant le transport ou lorsque la marchandise a quitté notre
          entrepôt en vue de l'expédition.&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          L'événement le plus précoce étant seul pris en compte.
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
        }}
      >
        <ul
          style={{
            marginBottom: '0in',

            marginLeft: '0.5in',
          }}
        >
          <li
            style={{
              margin: '0in',
              lineHeight: '115%',
              fontSize: 15,
              fontFamily: '"Arial", sans-serif',
            }}
          >
            <span style={{ lineHeight: '150%', fontSize: '10pt', background: 'white' }}>
              Au cas où le client opte pour la livraison à domicile:
            </span>
          </li>
        </ul>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          CAREF prend la responsabilité et les mesures nécessaires dont la vérification du bon état, de
          la compatibilité et des références des articles avant de livrer le colis en toute sécurité
          jusqu’à la zone de livraison.&nbsp;
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
        }}
      >
        <ul
          style={{
            marginBottom: '0in',

            marginLeft: '0.5in',
          }}
        >
          <li
            style={{
              margin: '0in',
              lineHeight: '115%',
              fontSize: 15,
              fontFamily: '"Arial", sans-serif',
            }}
          >
            <span style={{ lineHeight: '150%', fontSize: '10pt' }}>
              Au cas où le client effectue un retrait avec son transporteur:
            </span>
          </li>
        </ul>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Dès que le colis comportant la ou les marchandises commandées par le client est remis au
          transporteur envoyé par ce client, le colis n’est plus à la responsabilité de CAREF.&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Pour chaque type de livraison (retrait, livraison à domicile), un bon de livraison doit être
          signé afin de confirmer la bonne réception du colis en bon état. Un moyen de confirmation de
          l’identité du client ou de son transporteur sera demandé en plus du numéro de commande
          (exemple: pièce d’identité, passeport, etc.).
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            4.3.5 - Réception des marchandises / dégâts dus au transport
          </span>
        </strong>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.3..5.1 - Pour l'expédition de votre commande, nous faisons notamment appel à des
            transporteurs externes dans le cas où le client choisit l’option de livraison à domicile. Si
            la marchandise arrive endommagée à la livraison, nous vous demandons de bien vouloir procéder
            comme suit:&nbsp;
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Si possible, faites une réclamation directement auprès du transporteur, en précisant bien le
            degré et l'étendue des dégâts pour analyse de vos droits dans le cadre de la garantie.
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
        </p>
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            4.3.5.2 - Vous pouvez refuser les marchandises endommagées. Dans un tel cas, veuillez nous
            contacter par téléphone, par courrier électronique ou directement sur place à notre adresse
            aux Mamelles au 06 Rue OKM 17 Dakar. Vous pourrez ainsi nous aider à faire valoir nos droits
            à l'égard du transport des colis et nous permettre de procéder à la demande de dédommagement,
            et en même temps d'améliorer la qualité de notre service.
          </span>
        </p>
      </div>
      <p
        id="garantie"
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.4 - Garantie de nos article</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          CAREF Automobile vend des pièces de qualité distribuées par des sociétés agrées de
          l’aftermarket automobile au niveau international. Afin de vous rassurer de la qualité de nos
          produits nous offrons une garantie après vente sur certains de nos produits. Dans ce cas, la
          durée de la garantie est mentionnée directement dans le site en mois, années au niveau titre de
          l’article ou sur la facture.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          La garantie sera appliquée par CAREF après une étude approfondie de la pièce défectueuse afin
          d’en analyser les causes. Les pièces étant endommagées suite à un un accident et/ou à un choc
          de la voiture sur laquelle la pièce est assemblée, à un mauvais montage de la pièce dans la
          voiture, à une mauvaise manipulation ou modification de la pièce, etc.. &nbsp;ne sont pas
          admissibles à l'ouverture d’une demande d’un retour garanti.
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          border: 'none',
          borderBottom: 'none windowtext 1pt',
          padding: '0in 0in 11pt 0in',
          background: 'white',
        }}
      >
        <p
          style={{
            margin: '0in',
            lineHeight: '150%',
            fontSize: 15,
            fontFamily: '"Arial", sans-serif',
            textAlign: 'justify',
            background: 'white',
            border: 'none',
            padding: '0in',
          }}
        >
          <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>
            Si l’emballage d’origine est endommagé au moment du retour de la pièce, vous devrez
            rembourser la différence.
          </span>
        </p>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
        id="retourArticle"
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>
            4.5 - Retour et remboursement d’article&nbsp;
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.5.1 - Retour de l’article</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          Le client peut faire une demande de retour d’article en cas de:
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          &gt; Rétractation suite à l’achat et non utilisation de la pièce: dans ce cas il dispose d’un
          délai de 72h pour les articles neufs et de 24h pour les articles d’occasion, à compter de la
          signature de la conclusion d’achat pour exercer son droit de rétractation.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          &gt; Pièce non compatible avec le véhicule: CAREF prend la responsabilité de la reprise de la
          pièce non compatible et de fournir une nouvelle pièce appropriée après vérification et
          confirmation de la non compatibilité et de l’état neuf et non utilisé de la pièce retournée.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          Le client doit consulter un garagiste ou un technicien apte pour s’assurer de la détection de
          la non compatibilité de la pièce lors du montage afin de pouvoir faire valoir ses droit de
          rétractation ou demande d’échange.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          La non-compatibilité de la pièces détectées lors de l’utilisation du véhicule après montage de
          celle-ci peut faire l’objet d’un refus d’une demande de retour.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          La pièce à retouner ne doit présenter aucun signe d’endommagement, de déformation, d’usures,
          etc…
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          Avant de procéder au retour de la pièce de rechange, vous devez nous contacter:
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', background: 'white' }}>
          &gt; &nbsp;via votre compte client en ligne sur la rubrique historique des commandes afin de
          mentionner le numéro de commande, les raisons de ce retour d’article, &nbsp;et tout autre
          information jugé nécessaire pour la bonne prise en compte de votre demande. Notre service
          clientèle vous recontactera par la suite dans les meilleurs délais.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Si l’emballage d’origine est endommagé, vous devrez rembourser la différence.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Au cas où votre demande de retour est étudiée et acceptée, vous aurez droit à un remboursement
          du montant du prix d’achat de la pièces hors frais de livraisons et de services sous un délai
          de 15 jours maximum à partir de la date de l’acceptation du retour de l’article.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.5.2 - &nbsp;Expédition des retours</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Vous devez organiser le retour de votre article jusqu’à l’adresse qui vous a été communiquée et
          prendre en charge les frais de transport liés au retour de votre article.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          &nbsp;Nous ne sommes pas responsables des pertes ou des dommages survenus pendant le transport
          pour retourner la pièce.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;4.5.3 - Délai de retour</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Pour tout article acheté chez CAREF Automobile est accepté en retour tel que spécifié
          ci-dessus,
          <span style={{ background: 'white' }}>
            &nbsp;dans un délai de 72h pour les articles neufs et de 24h pour les articles d’occasion, à
            compter de la signature de la conclusion d’achat pour exercer son droit de rétractation.
          </span>
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;4.5.4 - Remboursement</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Si vous avez droit à un remboursement, nous vous rembourserons le prix d’achat et un crédit
          sera appliqué au mode de paiement utilisé pour l’achat dans les limites de faisabilité. Vous
          supporterez dans certains cas les frais de transfert liés à la transaction.
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <strong>
          <span style={{ fontSize: 13, lineHeight: '150%' }}>4.5.5 - Echanges</span>
        </strong>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les échanges d’articles peuvent avoir lieu dans le cadre d’une demande d’un retour d’article
          pour motif de pièce non-compatible comme mentionné ci-dessus sous un délai de 72h pour les
          articles neufs et de 24h pour les articles d’occasion,
          <span style={{ background: 'white' }}>
            à compter de la signature de la conclusion d’achat.
          </span>
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Si vous devez échanger un article, veuillez nous contacter
          <span style={{ background: 'white' }}>
            via votre compte client en ligne sur la rubrique historique des commandes afin de mentionner
            le numéro de commande, les raisons de ce retour d’article, &nbsp;et tout autre information
            jugé nécessaire pour la bonne prise en compte de votre demande. Notre service clientèle vous
            recontactera par la suite dans les meilleurs délais.
          </span>
        </span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>
          Les frais de transport relatif pour l'envoi du nouvel article et le retour de l’ancien restent
          à la charge du client.
        </span>
      </p>
      <div
        style={{
          margin: '0in',
          lineHeight: '115%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
        }}
      >
        <ul
          style={{
            marginBottom: '0in',

            marginLeft: '0.5in',
          }}
        >
          <li
            style={{
              margin: '0in',
              lineHeight: '115%',
              fontSize: 15,
              fontFamily: '"Arial", sans-serif',
            }}
          >
            <span style={{ lineHeight: '150%', fontSize: '10pt', color: 'black' }}>
              Si le client renvoie une pièce utilisée ou bien différente de celle livrée, le
              remboursement n'est pas effectué.
            </span>
          </li>
        </ul>
      </div>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%', color: 'black' }}>—--</span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          textAlign: 'justify',
          background: 'white',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: '0in',
          lineHeight: '150%',
          fontSize: 15,
          fontFamily: '"Arial", sans-serif',
          marginTop: '12pt',
          marginRight: '0in',
          marginBottom: '12pt',
          marginLeft: '0in',
          textAlign: 'justify',
        }}
      >
        <span style={{ fontSize: 13, lineHeight: '150%' }}>&nbsp;</span>
      </p>
    </section>
  );
};

export default CGU;
