import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle, useToggle } from "usehooks-ts";
import OrangeImage from "../../assets/images/icons/orange.png";
import PaymentSecurityImage from "../../assets/images/icons/payment_security.svg";
import VisaImage from "../../assets/images/icons/visa.svg";
import WaveImage from "../../assets/images/icons/wave.png";
import Cart from "../../components/Cart";
import PaymentSelectModal from "../../components/PaymentSelectModal";
import PaymentWarningModal from "../../components/PaymentWarningModal";
import { payByPaydunya } from "../../libs/payment";
import { useAuthStore } from "../../store/auth";
import { useCartStore } from "../../store/cart";
import { useModalStore } from "../../store/modal";
import { useSearchStore } from "../../store/search";
import { formatNumberHumanReadable } from "../../utils";
import ChangeUserInfosModal from "./ChangeUserInfosModal";

const OrderValidationPage = () => {
  useDocumentTitle("CAREF | Validation de commande");

  const addressInputRef = useRef(null);
  const navigate = useNavigate();
  const [acceptCgu, setAcceptCgu] = useState(false);
  const setOmPaymentResponse = useCartStore(
    (state) => state.setOmPaymentResponse
  );
  const [payment, setPayment] = useState("orange_money");
  const vin = useSearchStore((state) => state.vin);
  const setVin = useSearchStore((state) => state.setVin);
  const [paymentWarningModel, togglePaymentWarningModel] = useToggle();
  const [paymentSelectModel, togglePaymentSelectModel] = useToggle();
  const [openEditModal, setOpenEditModal] = useState(false);
  const cart = useCartStore((state) => state.cart);
  const setOrderRetraitType = useCartStore(
    (state) => state.setOrderRetraitType
  );
  const setLoginModal = useModalStore((state) => state.setLoginModal);
  const setOrder = useCartStore((state) => state.setOrder);
  const order = useCartStore((state) => state.order);
  const clearCart = useCartStore((state) => state.clearCart);
  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);
  const [paying, setPaying] = useState(false);
  const retraitPrice = Math.round(0);
  const totalPrice = Math.round(
    cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)
  );

  useEffect(() => {
    window.scrollTo(0, 0, {
      behavior: "smooth",
    });
    return () => {};
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setOpenEditModal(false);
  }

  const valideOrderDisplayName = order?.displayName?.split(" ")?.length > 1;

  async function validePayment() {
    try {
      if (!user || !customer) return setLoginModal(true);
      if (order.retraitType === "site" && !order.address)
        return addressInputRef.current.focus();

      const operations = [
        payByPaydunya({
          cart: {
            user: customer.id,
            products: JSON.stringify(cart),
            paiement_method: payment,
            shipping_method: order.retraitType,
            shipping_address: order.address ?? null,
            status: "pending",
          },
          vin,
          customer_phone: user.phoneNumber,
          customer_name: user.displayName,
        }),
      ];
      setPaying(true);

      const res = await Promise.all(operations);

      setPaying(false);

      const { url } = res[0];

      setVin(null);

      if (url) window.location.href = url;

      /* if (!url && payment !== 'orange_money') return toast.error('Erreur lors du paiement');

      if (payment === 'orange_money') {
        setOmPaymentResponse(res[0]);
        navigate('/payment/om');
      } */

      clearCart();
    } catch (error) {
      console.log(error);
      setPaying(false);
    }
  }

  const isAvailable = cart.reduce(
    (acc, product) =>
      product?.availability?.trim() === "Disponible" ||
      product?.availability?.trim() === "En stock"
        ? acc
        : false,
    true
  );

  return (
    <div style={{ backgroundColor: "#f5f5f5", paddingTop: "70px" }}>
      <PaymentSelectModal
        {...{
          togglePaymentSelectModel,
          paymentSelectModel,
          payment,
          setPayment,
          validePayment,
        }}
      />
      <PaymentWarningModal
        {...{ paymentWarningModel, togglePaymentWarningModel }}
      />
      <ChangeUserInfosModal
        {...{
          openEditModal,
          setOpenEditModal,
          order,
          setOrder,
          user,
          onSubmit,
          valideOrderDisplayName,
        }}
      />
      <section
        className="overall_info"
        style={{ marginTop: 0 }}
        id="overall_info"
      >
        <div className="livraison">
          <h2>Mode de livraison</h2>
          <p>1. Contact</p>
          <p>
            <span></span>Prénom & Nom: {order?.displayName ?? user?.displayName}
          </p>
          <p>
            <span></span>Téléphone: {order?.phoneNumber ?? user?.phoneNumber}
          </p>
          <p>
            <span onClick={() => setOpenEditModal(true)}>
              <Link href="/">Changer</Link>
            </span>
          </p>
          <p>
            <span></span>
          </p>

          <p>2. Options de livraisons</p>
          <div className="one_line">
            <input
              type="radio"
              onChange={(e) => setOrderRetraitType(e.target.value)}
              name="agence"
              value="agence"
              defaultChecked={order?.retraitType === "agence"}
            />
            <p>Retrait en agence</p>
          </div>
          <p className="no_padding_top">
            <span>
              {isAvailable
                ? "Livraison sous 72h"
                : "nous allons vous contacter dès que la commande est prête"}
            </span>
            <br />
            <br />
          </p>
          <div className="one_line">
            <input
              type="radio"
              onChange={(e) => setOrderRetraitType(e.target.value)}
              defaultChecked={order?.retraitType === "site"}
              name="agence"
              value="site"
            />
            <p>Livraison sur site:</p>
            {order?.retraitType === "site" && (
              <input
                type="text"
                ref={addressInputRef}
                onInput={(e) => setOrder({ ...order, address: e.target.value })}
                defaultValue={order?.address}
                placeholder="Adresse de livraison"
              />
            )}
          </div>
          <p className="no_padding_top">
            <span>
              * les frais de livraison sont à la charge du client et vous seront
              communiqués avant la livraison
            </span>
          </p>
        </div>
        <div className="paiement">
          <h2>Mode de paiement</h2>
          <div className="services_paiement">
            <img src={OrangeImage} alt="orange" />
            <img src={WaveImage} alt="wave" />
            <img src={VisaImage} alt="visa" />
          </div>
          <div className="one_line">
            <img src={PaymentSecurityImage} alt="securise" />
            <p>Paiment sécurisé</p>
          </div>
        </div>
        <div className="confirmation" id="confirmation">
          <h2>Confirmation</h2>
          <div className="one_line">
            <p>
              <span>Total de la commande</span>
            </p>
            <p>
              <span>
                <span>
                  {formatNumberHumanReadable(totalPrice + retraitPrice)} FCFA
                </span>
              </span>
            </p>
          </div>
          <div className="wrapped">
            <div className="one_line">
              <p>Votre commande</p>
            </div>
            <hr
              style={{
                borderStyle: "dashed",
                margin: "1rem 0",
                borderColor: "#A8A8A7",
              }}
            />
            <div className="one_line">
              <p>Prix total des marchandises</p>
              <p>{formatNumberHumanReadable(totalPrice)} FCFA</p>
            </div>
            {order?.retraitType !== "site" && (
              <div className="one_line">
                <p>Retrait en agence</p>
                <p>{formatNumberHumanReadable(retraitPrice)} FCFA</p>
              </div>
            )}
            <hr
              style={{
                borderStyle: "solid",
                margin: "1rem 0",
                borderColor: "#A8A8A7",
              }}
            />
            <div className="one_line no_margin_bottom">
              <p>
                Acompte (30%) :<strong className="orange">Maintenant</strong>
              </p>
              <p>
                <strong>
                  {formatNumberHumanReadable(
                    Math.round((totalPrice + retraitPrice) * 0.3)
                  )}{" "}
                  FCFA
                </strong>
              </p>
            </div>
            <div className="one_line no_margin_bottom">
              <p>
                Reliquat (70%) :
                <strong className="orange">A la livraison</strong>
              </p>
              <p>
                <strong>
                  {formatNumberHumanReadable(
                    Math.round((totalPrice + retraitPrice) * 0.7)
                  )}{" "}
                  FCFA
                </strong>
              </p>
            </div>
            <div className="button_wrapper with-cgu">
              <button
                disabled={paying || !cart.length || !acceptCgu}
                onClick={togglePaymentSelectModel}
              >
                {paying ? "Veuillez patienter..." : "Commander & Payer"}
              </button>
              {/* condition d'utilisation */}
              <div className="o_cgu">
                <input
                  value={acceptCgu}
                  onChange={() => setAcceptCgu(!acceptCgu)}
                  type="checkbox"
                  name="cgu"
                  id="cgu"
                />
                <label htmlFor="cgu">
                  J'accepte les{" "}
                  <Link to="/cgu#title">
                    conditions générales d'utilisation
                  </Link>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="validation_container">
        <Cart cart={cart} />
      </section>
    </div>
  );
};

export default OrderValidationPage;
