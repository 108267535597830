import countryCodes from 'country-codes-list';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import logoCenterImage from '../../assets/images/logo_center.svg';
import { auth } from '../../configs/firebase';
import { useAuthStore } from '../../store/auth';
import { useModalStore } from '../../store/modal';

const LoginModal = ({ open, toggle }) => {
  const navigate = useNavigate();
  const setConfirmOtpModal = useModalStore((state) => state.setConfirmOtpModal);
  const setConfirmResult = useAuthStore((state) => state.setConfirmResult);
  const [phoneId, setPhoneId] = useState('SN');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const close = () => {
    toggle(false);
    navigate('/', { replace: true });
  };

  const countryCode = countryCodes.customList('countryCode', '{countryCallingCode}');

  async function onSubmit() {
    if (loading) return;

    const recaptchaVerifier = setupRecaptcha(auth);

    try {
      setError(null);
      const phone = `+${countryCode[phoneId]}${phoneNumber.trim()}`;

      if (!isValidPhoneNumber(phone)) return setError('Numéro de téléphone invalide');

      setLoading(true);
      const res = await signInWithPhoneNumber(auth, `${phoneId}${phone}`, recaptchaVerifier);
      setLoading(false);

      setConfirmResult(res);
      setPhoneNumber(null);
      setPhoneId('SN');

      recaptchaVerifier?.clear();

      toggle(false);
      setConfirmOtpModal(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      recaptchaVerifier?.clear();

      if (error?.code === 'auth/argument-error') {
        setError('Veuillez valider le recaptcha!');
        return;
      }

      setError('Une erreur est survenue, veuillez réessayer plus tard');
    }
  }

  function setupRecaptcha(auth) {
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'normale',
    });

    return recaptchaVerifier;
  }

  return (
    <Modal
      open={open}
      onClose={() => close()}
      id="phone_login"
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="popup"
      >
        <img alt="" src={logoCenterImage} />
        <h3>Bienvenue ! Entrez vos identifiants pour accéder à votre compte</h3>
        <div className="form_rep">
          <label>Téléphone</label>
          <div className="inline_inputs">
            <select value={phoneId} onChange={(e) => setPhoneId(e.target.value)}>
              {Object.keys(countryCode).map((key) => (
                <option key={key} value={key}>
                  ({key}) +{countryCode[key]}
                </option>
              ))}
            </select>
            <input
              onInput={(e) => setPhoneNumber(e.target.value)}
              type="tel"
              placeholder="77 344 44 44"
              id="phone_number"
            />
          </div>
          <div className="error">{error}</div>
          <button
            disabled={!phoneNumber || phoneNumber?.trim()?.length < 8 || loading}
            id="submit_button"
          >
            Se connecter
          </button>
        </div>
        <div id="recaptcha-container"></div>
      </form>
    </Modal>
  );
};

export default LoginModal;
