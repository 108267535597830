import React from 'react';
import { useNavigate } from 'react-router-dom';
import imageHolder from '../../assets/images/logo_svg.svg';
import { useFavoriteStore } from '../../store/useFavorite';
import { formatNumberHumanReadable } from '../../utils';
function VehiculeBonPlans({ vehicule }) {
  const navigate = useNavigate();
  const favorite = useFavoriteStore((state) => state.favorite);
  const addToFavorite = useFavoriteStore((state) => state.addToFavorite);
  const removeFromFavorite = useFavoriteStore((state) => state.removeFromFavorite);
  const isFavorite = favorite.find((item) => item.id === vehicule?.id);
  function toggleFavorite(p) {
    if (isFavorite) {
      removeFromFavorite(p);
    } else {
      addToFavorite(p);
    }
  }

  return (
    <div className="single_product" style={{}}>
      <div
        className="image_container"
        style={{
          height: '100%',
          flex: 'auto',
        }}
      >
        <img
          src={vehicule?.links[1] ?? imageHolder}
          style={{
            padding: '8px',
            width: '100%',
            maxWidth: '250px',
            height: '170px',
            objectFit: 'cover',
          }}
          alt="vehicle"
        />
      </div>
      <div className="description_container">
        <h3>{vehicule?.type}</h3>
        <div className="packed_text">
          <p>{vehicule?.brandname}</p>
          <p>{vehicule?.modelname}</p>
          <p>{vehicule?.drivename}</p>
        </div>
        <h4>{vehicule?.distance}km</h4>
        <strong>{formatNumberHumanReadable(vehicule?.price)} FCFA</strong>
      </div>
      <div className="actions_container">
        <div className="infos">
          <h3>{vehicule?.vendor ?? 'CAREF'}</h3>
          <h4>Garantie {vehicule?.warranty ?? '12'} mois</h4>
        </div>
        <div className="actions">
          <button
            onClick={() => {
              navigate(`/sale/vehicule_details/${vehicule?.id}#vehicle_container`);
            }}
          >
            DETAILS
          </button>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            cursor: 'pointer',
          }}
          className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart`}
          onClick={() => toggleFavorite(vehicule)}
        ></div>
      </div>
    </div>
  );
}

export default VehiculeBonPlans;
