export default function splitVendors(partsObject) {
  const renameKeys = (keysMap, object) =>
    Object.keys(object).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: object[key] },
      }),
      {}
    );

  let index = 1;
  const partsArray = [];
  let yet = true;

  while (yet) {
    const vendor = `vendor${index}`;
    if (partsObject[vendor]) {
      const availability = `availability${index}`;
      const price = `price${index}`;
      const waranty = `waranty${index}`;
      const link_vendor = `link_vendor${index}`;
      partsArray.push(
        renameKeys(
          {
            [vendor]: "vendor",
            [availability]: "availability",
            [price]: "price",
            [waranty]: "waranty",
            [link_vendor]: "link_vendor",
          },
          partsObject
        )
      );
      index++;
    } else {
      yet = false;
    }
  }

  return partsArray;
}

/* // Exemple d'utilisation :
const partsObject = {
  vendor1: "Vendor 1",
  availability1: "Available",
  price1: 100,
  waranty1: "3 years",
  vendor2: "Vendor 2",
  availability2: "In stock",
  price2: 150,
  waranty2: "2 years",
};

const partsArray = splitVendors(partsObject); */
