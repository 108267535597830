import { create } from 'zustand';

export const useSearchStore = create((set) => ({
  searchOptions: {
    selectedModel: null,
    selectedModelName: null,
    selectedMarque: null,
    selectedMarqueName: null,
    selectedDrive: null,
    selectedDriveName: null,
  },
  otherDriver: false,
  openVinModal: false,
  vin: null,
  redirectUrl: null,
  phone: null,
  clickedOnSearch: false,
  devisAutoFillPart: null,
  setVin: (vin = null) => set(() => ({ vin })),
  setRedirectUrl: (redirectUrl = null) => set(() => ({ redirectUrl })),
  setPhone: (phone) => set(() => ({ phone })),
  setOtherDriver: (otherDriver) => set(() => ({ otherDriver })),
  setVinModal: (openVinModal, redirectUrl) => set(() => ({ openVinModal, redirectUrl })),
  setSelected: (searchOptions) =>
    set(() => {
      return {
        searchOptions,
        otherDriver:
          searchOptions?.selectedDrive === 'other' || searchOptions?.selectedModel === 'other',
      };
    }),
  setClickedOnSearch: (clickedOnSearch) => set(() => ({ clickedOnSearch })),
  setDevisAutoFillPart: (devisAutoFillPart) => set(() => ({ devisAutoFillPart })),
}));
