import React from 'react';
import intro from '../../assets/images/icons/intro.svg';
import { useFetch, useToggle } from 'usehooks-ts';
import { API_URL } from '../../constant/api';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { fetchApi } from '../../libs/fetch';

function Subtitles() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { data: marques } = useFetch(`${API_URL}/marquesBMD`);
  const { data: models } = useFetch(
    watch('brand') ? `${API_URL}/marquesBMD/${watch('brand')}/modeles` : null,
  );
  const [open, , setOpen] = useToggle();

  const onSubmit = async (data) => {
    try {
      await fetchApi(`${API_URL}/auto-devis`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          brandname:
            watch('brand') === 'other'
              ? data.other_brand
              : marques.find((m) => m.id === watch('brand'))?.nom,
          modelname:
            watch('model') === 'other'
              ? data.other_model
              : models.find((m) => m.id === watch('model'))?.nom,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setOpen(false);
      reset();
      toast.success('Votre demande a été envoyée avec succès');
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'envoi de votre demande");
    }
  };

  return (
    <div className="subtitles">
      <div
        style={{
          display: open ? 'flex' : 'none',
        }}
        className="popup_overlay"
        onClick={() => setOpen(false)}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="popup sent_booking"
          style={{
            padding: '3rem 3.4rem',
            borderRadius: '1rem',
            width: '100%',
            maxWidth: '30rem',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="input_right">
            <div class="input_right">
              <label for="name">
                Prénom &amp; Nom
                <b>*</b>
              </label>
              <input
                type="text"
                id="name"
                placeholder="Renseigner votre nom complet"
                name="name"
                {...register('name', {
                  required: true,
                })}
                aria-invalid={errors.name ? 'true' : 'false'}
              />
            </div>
          </div>
          <div class="input_right">
            <label for="phone">
              Téléphone
              <b>*</b>
            </label>
            <input
              type="tel"
              id="phone"
              placeholder="Renseigner votre numéro de téléphone"
              name="phone"
              {...register('phone', {
                required: true,
                pattern: /^(\+?(00221|\+221)?\s?\d{2}\s?\d{3}\s?\d{2}\s?\d{2})$/i,
              })}
              aria-invalid={errors.phone ? 'true' : 'false'}
            />
          </div>
          <div class="input_right">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Renseigner votre adresse email"
              name="email"
              {...register('email', {})}
              aria-invalid={errors.email ? 'true' : 'false'}
            />
          </div>
          <div className="input_right">
            <label htmlFor="brand_selector">
              Marque <b>*</b>
            </label>
            <div className="select_container">
              <select
                name="brand"
                id="brand_selector"
                {...register('brand', {
                  required: true,
                })}
                aria-invalid={errors.brand ? 'true' : 'false'}
              >
                <option value="">Sélectionner</option>
                {marques?.map((marque) => (
                  <option key={marque.id} value={marque.id}>
                    {marque.nom}
                  </option>
                ))}
                <option value="other">Autre</option>
              </select>
            </div>
          </div>
          {watch('brand') === 'other' && (
            <div class="input_right">
              <label for="other_brand">
                Autre marque
                <b>*</b>
              </label>
              <input
                type="text"
                id="other_brand"
                placeholder="Renseigner la marque"
                name="other_brand"
                {...register('other_brand', { required: true })}
                aria-invalid={errors.email ? 'true' : 'false'}
              />
            </div>
          )}
          <div className="input_right">
            <label htmlFor="model_selector">
              Modèle <b>*</b>
            </label>
            <div className="select_container">
              <select
                name="model"
                id="model_selector"
                {...register('model', {
                  required: true,
                })}
                aria-invalid={errors.model ? 'true' : 'false'}
              >
                <option value="">Sélectionner</option>
                {models?.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.nom}
                  </option>
                ))}
                <option value="other">Autre</option>
              </select>
            </div>
          </div>
          {watch('model') === 'other' && (
            <div class="input_right">
              <label for="other_model">
                Autre modèle
                <b>*</b>
              </label>
              <input
                type="text"
                id="other_model"
                placeholder="Renseigner le modèle"
                name="other_model"
                {...register('other_model', { required: true })}
                aria-invalid={errors.email ? 'true' : 'false'}
              />
            </div>
          )}
          <div class="input_right">
            <label for="budget">
              Budget
              <b>*</b>
            </label>
            <input
              type="number"
              id="budget"
              placeholder="Renseigner votre budget"
              name="budget"
              {...register('budget', {
                required: true,
              })}
              aria-invalid={errors.budget ? 'true' : 'false'}
            />
          </div>
          <button className="devisBtn" type="submit">
            Envoyer
          </button>
        </form>
      </div>
      <img src={intro} alt="intro" />
      <p>
        <span>
          Des véhicules certifiés et garantis.
          <br />
          Rapport d'historique, liste des fonctions et des options disponibles.
          <br />
        </span>
        <span>
          <br />
          Note:
        </span>{' '}
        Si le véhicule que vous recherchez n'est pas disponible, veuillez &nbsp;
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          demander un devis
        </a>
      </p>
    </div>
  );
}

export default Subtitles;
