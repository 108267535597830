import React from 'react';
import { useNavigate } from 'react-router-dom';
import imageHolder from '../../assets/images/logo_center.svg';

function SliderPieceCatalog({
  setSelectedCategory,
  selectedCategory,
  categories,
  setCurrentPage,
  errorCategories,
  swiperRef,
}) {
  const navigate = useNavigate();

  const handlePrevClick = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  const handleNextClick = () => {
    if (swiperRef.current) swiperRef.current.slideNext();
  };

  return (
    <div className="left_sidebar hide_mobile">
      <i className="fa-solid fa-chevron-up prev_slide" onClick={handlePrevClick}></i>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {categories &&
            !errorCategories &&
            categories?.map((category, index) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/catalog?category=${category?.id}`);
                  setCurrentPage(1);
                }}
                key={category?.id}
                className={`swiper-slide ${selectedCategory === category?.id ? 'active' : ''}`}
              >
                <img src={category?.link ?? imageHolder} alt="" />{' '}
                <span
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {category?.nom}
                </span>
              </div>
            ))}

          {!categories && !errorCategories && (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}

          {errorCategories && (
            <div className="loader-container">
              <span className="error">Une erreur est survenue</span>
            </div>
          )}
        </div>
      </div>
      <i className="fa-light fa-chevron-down next_slide" onClick={handleNextClick}></i>
    </div>
  );
}

export default SliderPieceCatalog;
