import React, { Fragment, useEffect, useState } from 'react';
import { useDocumentTitle, useToggle } from 'usehooks-ts';
import OrangeImage from '../../assets/images/icons/orange.png';
import PaymentSecurityImage from '../../assets/images/icons/payment_security.svg';
import VisaImage from '../../assets/images/icons/visa.svg';
import WaveImage from '../../assets/images/icons/wave.png';
import PaymentWarningModal from '../../components/PaymentWarningModal';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../store/auth';
import { useBookingStore } from '../../store/booking';
import { useCartStore } from '../../store/cart';
import { useModalStore } from '../../store/modal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const BookingValidationPage = () => {
  useDocumentTitle('CAREF | Validation de la reservation');
  const navigate = useNavigate();
  // const setOmPaymentResponse = useCartStore((state) => state.setOmPaymentResponse);
  const booking = useBookingStore((state) => state.booking);
  const formData = useBookingStore((state) => state.formData);
  const clearBooking = useBookingStore((state) => state.clearBooking);
  const clearFormData = useBookingStore((state) => state.clearFormData);
  // const [payment, setPayment] = useState('orange_money');
  // const customer = useAuthStore((state) => state.customer);
  const [paymentWarningModel, togglePaymentWarningModel] = useToggle(); /* 
  const [paymentSelectModel, togglePaymentSelectModel] = useToggle(); */
  const setLoginModal = useModalStore((state) => state.setLoginModal);
  const clearCart = useCartStore((state) => state.clearCart);
  const user = useAuthStore((state) => state.user);
  const [paying, setPaying] = useState(false);

  async function submit() {
    await fetchApi(`${API_URL}/${booking?.url}`, {
      method: 'POST',
      body: formData,
    });

    clearBooking();

    clearFormData();
  }

  useEffect(() => {
    window.scrollTo(0, 0, {
      behavior: 'smooth',
    });
    return () => {};
  }, []);

  async function validePayment() {
    try {
      if (!formData || !booking) return;
      if (!user) return setLoginModal(true);

      /*   const total_amount = formatNumberHumanReadable(parseInt(booking?.choice?.price));

      if (isNaN(total_amount)) throw new Error('Le prix est invalide');
 */

      const operations = [
        submit() /* 
        payByPaydunya({
          cart: {
            user: customer.id,
            products: JSON.stringify([{ price: booking?.choice?.price, quantity: 1 }]),
            paiement_method: payment,
            status: 'pending',
          },
          customer_phone: user.phoneNumber,
          customer_name: user.displayName,
        }), */,
      ];

      setPaying(true);

      await Promise.all(operations);

      setPaying(false);

      /* const { url } = res[1];

      if (url) window.location.href = url;

      if (!url && payment !== 'orange_money') return toast.error('Erreur lors du paiement');

      if (payment === 'orange_money') {
        setOmPaymentResponse(res[1]);
        navigate('/payment/om');
      }
 */
      clearCart();
      navigate(-1);
      toast.success('Votre reservation a été effectuée avec succès');
    } catch (error) {
      setPaying(false);
    }
  }

  function parseBookingDate(date, time) {
    if (!date || !time) return null;

    const dateParts = date?.split('-');
    const timeParts = time?.split(':');

    if (dateParts.length !== 3) return null;
    if (timeParts.length !== 2) return null;

    const newDate = new Date(
      +dateParts[0],
      +dateParts[1] - 1,
      +dateParts[2],
      timeParts[0],
      timeParts[1],
    );

    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    // 10h00
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };

    return `${newDate.toLocaleDateString('fr-FR', dateOptions)} à ${newDate.toLocaleTimeString(
      'fr-FR',
      timeOptions,
    )}`;
  }

  return (
    <Fragment>
      {/* <PaymentSelectModal
        {...{
          togglePaymentSelectModel,
          paymentSelectModel,
          payment,
          setPayment,
          validePayment,
        }}
      /> */}
      <PaymentWarningModal {...{ paymentWarningModel, togglePaymentWarningModel }} />
      <section className="overall_info" id="overall_info">
        <div className="livraison">
          <h2>Récaputilatif</h2>
          <p>
            <span></span>Prénom & Nom: <b className="bold">{booking?.name}</b>
          </p>
          <p>
            <span></span>Téléphone: <b className="bold">{booking?.tel}</b>
          </p>
          <p>
            <span></span> Marque : <b className="bold">{booking?.marque.nom}</b>
          </p>
          <p>
            <span></span> Model : <b className="bold">{booking?.model.nom}</b>
          </p>
          <p>
            <span></span> Besoin : <b className="bold">{booking?.service_type}</b>
          </p>
          <p>
            <span></span> Choix :{' '}
            <b className="bold">
              {booking?.choice ? booking?.choice[booking?.service_type.slice(0, -1)] : null}
            </b>
          </p>
          <p>
            <span></span> Lieu de rendez-vous : <b className="bold">{booking?.location}</b>
          </p>
          <p>
            <span></span> Date et heure souhaitées :{' '}
            <b className="bold">{parseBookingDate(booking?.date, booking?.time)}</b>
          </p>
          <p>
            <span></span> Message : <b className="bold">{booking?.message}</b>
          </p>
          <p
            style={{
              fontSize: '1.2rem',
              marginTop: '2rem',
              lineHeight: '1.5rem',
            }}
            className="bold"
          >
            Nous vous remercions pour votre réservation et allons prendre contact avec vous dans les plus
            bref délais
          </p>
        </div>
        <div className="paiement">
          <h2>Mode de paiement</h2>
          <div className="services_paiement">
            <img src={OrangeImage} alt="orange" />
            <img src={WaveImage} alt="wave" />
            <img src={VisaImage} alt="visa" />
          </div>
          <div className="one_line">
            <img src={PaymentSecurityImage} alt="securise" />
            <p>Paiment sécurisé</p>
          </div>
        </div>
        <div className="confirmation" id="confirmation">
          <h2>Confirmation</h2>
          <div className="wrapped">
            <div className="one_line">
              <p>Votre reservation</p>
            </div>
            <hr
              style={{
                borderStyle: 'dashed',
                margin: '1rem 0',
                borderColor: '#A8A8A7',
              }}
            />
            <div className="one_line no_margin_bottom">
              <p style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>Frais de réservation :</p>
              <p>
                <strong style={{ fontWeight: 'bold' }}>{0} FCFA</strong>
              </p>
            </div>
            <div className="button_wrapper">
              <button disabled={paying} onClick={validePayment}>
                Reserver
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default BookingValidationPage;
