import React from "react";
import { Link } from "react-router-dom";
import video from "../../assets/images/video_thumbnail.png";

function AboutSection() {
  return (
    <section className="about_section">
      <div className="section_content">
        <br />
        <h1>
          LE CEO VOUS PRÉSENTE SON PARCOURS ET LES PRINCIPES DE CAREF AUTOMOBILE
        </h1>
        <Link to="https://www.youtube.com/watch?v=1UaxjMduJXw" target="_blank">
          <img src={video} alt="video" />
        </Link>
      </div>
    </section>
  );
}

export default AboutSection;
