import React from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const HeroSlider = ({ image, text }) => {
  return (
    <Swiper
      className="swiper-container hero_slider"
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 7500,
        disableOnInteraction: false,
      }}
      navigation
    >
      <SwiperSlide className="swiper-slide">
        <img alt="" src={image} className="background_img" />
        <h2>{text}</h2>
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroSlider;
