import React from "react";
import CalenderImage from "../../assets/images/icons/calender_outline.svg";
import EmailImage from "../../assets/images/icons/email.svg";
import LocationImage from "../../assets/images/icons/location_outline.svg";
import PhoneImage from "../../assets/images/icons/phone.svg";
import MeetForm from "./MeetForm";
import { useToggle } from "usehooks-ts";

const Meet = () => {
  const [show, setShow] = useToggle(false);

  return (
    <section className="rendez_vous" id="rendez_vous">
      <h2 className="main-color">RESERVATION</h2>
      <p className="dist">
        Merci de remplir ce formulaire pour effectuer votre réservation en
        ligne.
      </p>
      <div className="form_wrapper">
        <div className="left_pane_info">
          <p>
            <b
              onClick={(e) => {
                setShow();
                if (!show) {
                  e.target.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
              className="hide_desktop"
            >
              Informations (cliquez ici)
            </b>{" "}
            Pour une réservation au téléphone ou chez CAREF:
          </p>
          {show && (
            <div className="hide_desktop">
              <div className="line_of_info">
                <p className="hide_mobile">Information</p>
              </div>
              <div className="line_of_info">
                <img src={PhoneImage} alt="Phone" />
                <p>+221 77 355 52 52</p>
              </div>
              <div className="line_of_info">
                <img src={EmailImage} alt="Email" />
                <p>contact@carefautomobile.com</p>
              </div>
              <div className="line_of_info">
                <img src={CalenderImage} alt="Location" />
                <p>
                  HEURES D'OUVERTURE
                  <br />
                  Lundi - Vendredi 9h - 18h
                  <br />
                  Samedi 9h - 16h
                </p>
              </div>
              <div className="line_of_info">
                <img src={LocationImage} alt="Location" />
                <p>
                  Notre point d’accueil client:
                  <br />
                  Mamelles, 06 Rue OKM-17 - Dakar, Sénégal
                </p>
              </div>
            </div>
          )}
          <div className="hide_mobile">
            <div className="line_of_info">
              <p>Information</p>
            </div>
            <div className="line_of_info">
              <img src={PhoneImage} alt="Phone" />
              <p>+221 77 355 52 52</p>
            </div>
            <div className="line_of_info">
              <img src={EmailImage} alt="Email" />
              <p>contact@carefautomobile.com</p>
            </div>
            <div className="line_of_info">
              <img src={CalenderImage} alt="Location" />
              <p>
                HEURES D'OUVERTURE
                <br />
                Lundi - Vendredi 9h - 18h
                <br />
                Samedi 9h - 16h
              </p>
            </div>
            <div className="line_of_info">
              <img src={LocationImage} alt="Location" />
              <p>
                Notre point d’accueil client:
                <br />
                Mamelles, 06 Rue OKM-17 - Dakar, Sénégal
              </p>
            </div>
          </div>
        </div>
        <MeetForm />
      </div>
    </section>
  );
};

export default Meet;
