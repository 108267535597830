import React, { useState } from 'react';
import { Controller, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useFetch } from 'usehooks-ts';
import { API_URL } from '../../constant/api';

const breakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 5,
  },
  1024: {
    slidesPerView: 6,
  },
  1200: {
    slidesPerView: 7,
  },
  1400: {
    slidesPerView: 8,
  },
};

const CarmakerBrands = () => {
  const { data: brandsLogo } = useFetch(`${API_URL}/brand-logo`);
  const { data: makersLogo } = useFetch(`${API_URL}/manufacturer`);

  const [controlledSwiper1, setControlledSwiper1] = useState(null);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);

  return (
    <section className="logos_section_container">
      <div className="logos_section" id="partenaires">
        <h2>LES MARQUES DE CONSTRUCTEURS AUTOMOBILES</h2>
        <div className="logos_slider">
          <Swiper
            modules={[Controller, Pagination]}
            slidesPerView={8}
            onSwiper={setControlledSwiper1}
            spaceBetween={50}
            pagination={{ clickable: true }}
            controller={{ control: controlledSwiper1 }}
            className="swiper-wrapper dot"
            breakpoints={breakpoints}
          >
            {brandsLogo?.map((item) => (
              <SwiperSlide key={item.id}>
                <img alt="" className="swiper-slide" src={item.link} />
              </SwiperSlide>
            ))}
          </Swiper>
          <i
            onClick={() => controlledSwiper1?.slideNext()}
            className="fa-solid fa-chevron-right next_slide hide_mobile"
          ></i>
          <i
            onClick={() => controlledSwiper1?.slidePrev()}
            className="fa-solid fa-chevron-left prev_slide hide_mobile"
          ></i>
        </div>
      </div>
      <div className="logos_section">
        <h2>LES FABRICANTS DE PIÈCES DE RECHANGE</h2>
        <div className="logos_slider">
          <Swiper
            modules={[Controller, Pagination]}
            onSwiper={setControlledSwiper2}
            controller={{ control: controlledSwiper2 }}
            slidesPerView={8}
            spaceBetween={50}
            pagination={{ clickable: true }}
            className="swiper-wrapper dot"
            breakpoints={breakpoints}
          >
            {makersLogo?.map((item) => (
              <SwiperSlide key={item.id}>
                <img alt="" className="swiper-slide" src={item.link} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-bubble"></div>
          <i
            onClick={() => controlledSwiper2?.slideNext()}
            className="fa-solid fa-chevron-right next_slide hide_mobile"
          ></i>
          <i
            onClick={() => controlledSwiper2?.slidePrev()}
            className="fa-solid fa-chevron-left prev_slide hide_mobile"
          ></i>
        </div>
      </div>
    </section>
  );
};

export default CarmakerBrands;
