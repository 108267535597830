import React, { useState } from 'react';
import { Controller, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFetch } from 'usehooks-ts';
import { API_URL } from '../../constant/api';

const breakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 5,
  },
  1024: {
    slidesPerView: 6,
  },
  1200: {
    slidesPerView: 7,
  },
  1400: {
    slidesPerView: 8,
  },
};

function Partenaires() {
  const { data: partner } = useFetch(`${API_URL}/partner`);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);
  return (
    <section className="logos_section_container flip">
      <div className="logos_section">
        <h2 id="partenaires">Nos Partenaires</h2>
        <div className="logos_slider">
          <Swiper
            modules={[Controller, Pagination]}
            onSwiper={setControlledSwiper2}
            controller={{ control: controlledSwiper2 }}
            slidesPerView={8}
            spaceBetween={50}
            pagination={{ clickable: true }}
            className="swiper-wrapper"
            breakpoints={breakpoints}
          >
            {partner?.map((item) => (
              <SwiperSlide key={item.id}>
                <img alt="" className="swiper-slide" src={item.link} />
              </SwiperSlide>
            ))}
          </Swiper>
          <i
            onClick={() => controlledSwiper2?.slideNext()}
            className="fa-solid fa-chevron-right next_slide hide_mobile"
          ></i>
          <i
            onClick={() => controlledSwiper2?.slidePrev()}
            className="fa-solid fa-chevron-left prev_slide hide_mobile"
          ></i>
        </div>
      </div>
    </section>
  );
}

export default Partenaires;
