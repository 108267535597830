import React from 'react';
import Modal from 'react-responsive-modal';

const ChangeUserInfosModal = ({
  openEditModal,
  setOpenEditModal,
  order,
  setOrder,
  user,
  onSubmit,
  valideOrderDisplayName,
}) => {
  return (
    <Modal
      open={openEditModal}
      onClose={() => setOpenEditModal(false)}
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
      id="login_form"
    >
      <form onSubmit={onSubmit} className="popup" style={{ minWidth: 375 }}>
        <div className="form_rep">
          <div className="elm">
            <label>
              Prénom & Nom<span>*</span>
            </label>
            <input
              onInput={(e) => setOrder({ ...order, displayName: e.target.value })}
              type="text"
              defaultValue={order?.displayName ?? user?.displayName}
              placeholder="Xaadim"
            />
          </div>
          <div className="elm">
            <label>
              Telephone<span>*</span>
            </label>
            <input
              onInput={(e) => setOrder({ ...order, phoneNumber: e.target.value })}
              type="text"
              defaultValue={order?.phoneNumber ?? user?.phoneNumber}
              placeholder="77 777 77 77"
            />
          </div>
          <button disabled={!valideOrderDisplayName || order?.phoneNumber?.length < 9} id="submit_plus">
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeUserInfosModal;
