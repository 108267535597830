import React from 'react';
import DotImage from '../../assets/images/icons/dots.svg';
import Email from '../../assets/images/icons/email.svg';
import Location from '../../assets/images/icons/location_filed.svg';
import Phone from '../../assets/images/icons/phone.svg';
import LogoCenter from '../../assets/images/logo_center.svg';
import Footer3 from '../../assets/images/logos/footer-3.png';
import Footer4 from '../../assets/images/logos/footer-4.png';
import Footer5 from '../../assets/images/logos/footer-5.svg';
import { Link } from 'react-router-dom';
import PubImage from '../../assets/images/Pub bas de page Desktop-min.png';
import PubMobileImage from '../../assets/images/Pub bas de page Mobile-min.png';
import { useMediaQuery } from 'usehooks-ts';

const Footer = () => {
  const matches = useMediaQuery('(min-width: 768px)');

  return (
    <footer>
      <div
        className="footer_top"
        style={{
          position: 'relative',
        }}
      >
        <img src={DotImage} alt="" />
        <h2>VOTRE PUB ICI</h2>

        <img
          src={matches ? PubImage : PubMobileImage}
          alt=""
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
      <div className="footer_branding">
        <img src={LogoCenter} alt="" />
        <div className="social_icons">
          <Link target="_blank" to="https://www.facebook.com/profile.php?id=100092690101085">
            <i className="fa-brands fa-facebook-f"></i>
          </Link>
          <Link target="_blank" to="https://www.instagram.com/caref_automobile/">
            <i className="fa-brands fa-square-instagram"></i>
          </Link>
          <Link target="_blank" to="https://www.tiktok.com/@carefautomobile">
            <i className="fa-brands fa-tiktok"></i>
          </Link>
          <Link target="_blank" to="https://www.linkedin.com/company/carefautomobile/?viewAsMember=true">
            <i className="fa-brands fa-linkedin-in"></i>
          </Link>
          <Link target="_blank" to="https://www.youtube.com/channel/UCd1RDPnke7ImjngsqMrIebQ">
            <i className="fa-brands fa-youtube"></i>
          </Link>
        </div>
      </div>
      <div className="footer_links">
        <div className="links_col">
          <h4>Information</h4>
          <Link to="/about#navbar">A propos</Link>
          <Link to="/cgu#garantie">Nos Garanties & SAV</Link>
          <Link to="/cgu#pdc">Notre politique de confidentialité</Link>
          <Link to="/">
            <img src={Location} alt="adresse" />
            <span>&nbsp;&nbsp;Mamelles, 06 Rue OKM-17 Dakar Sénégal</span>
          </Link>
          <Link to="/">
            <img src={Phone} alt="" />
            <span>&nbsp;&nbsp;+221 77 355 52 52</span>
          </Link>
          <Link className="email" to="/">
            <img src={Email} alt="" />
            <span>&nbsp;&nbsp;contact@carefautomobile.com</span>
          </Link>
        </div>
        <div className="links_col_center">
          <h4>Clients</h4>
          <Link to="/cgu#delivery">Livraison</Link>
          <Link to="/cgu#retourArticle">Retour & échange d’article</Link>
          <Link to="/cgu#title">Modalités et clauses de ventes</Link>
          <Link to="/cgu#paymentOnline">Paiement sécurisé via:</Link>
          <div className="media_container">
            <Link>
              <img src={Footer3} alt="" />
            </Link>
            <Link>
              <img src={Footer4} alt="" />
            </Link>
            <Link>
              <img src={Footer5} alt="" />
            </Link>
          </div>
        </div>
        <div className="links_col">
          <h4>Produits & Services</h4>
          <Link to="/#home_catalog">Ventes de Pièces de Rechange</Link>
          <Link to="/diagnostic#navbar">Entretien & Réparation</Link>
          <Link to="/consulting-services#navbar">Services de Consulting</Link>
          <Link to="/sale/bons_plans_piece#navbar">Ventes d'Occasions</Link>
          <Link to="/#partenaires">Des Marques Populaires</Link>
          <Link to="/about#partenaires">Des Partenaires de confiance</Link>
        </div>
      </div>
      <div className="footer_copyright">
        <p>Copyright &copy; 2023 CAREF AUTOMOBILE. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
