import { create } from 'zustand';

export const useModalStore = create((set) => ({
  quickAccessBooking: false,
  openLoginModal: false,
  openConfirmOtpModal: false,
  openSignUpModal: false,
  openSuccessSignUpModal: false,
  openErrorSignUpModal: false,
  openAlertMotorisationModal: false,
  motorisationModalMessage: '',
  signUpModalConfig: {
    back: true,
    defaultFirstName: '',
    defaultLastName: '',
  },
  setQuickAccessBooking: (quickAccessBooking) => set(() => ({ quickAccessBooking })),
  setLoginModal: (openLoginModal) => set(() => ({ openLoginModal })),
  setConfirmOtpModal: (openConfirmOtpModal) => set(() => ({ openConfirmOtpModal })),
  setSignUpModal: (openSignUpModal, signUpModalConfig) =>
    set(() => ({ openSignUpModal, signUpModalConfig })),
  setSuccessSignUpModal: (openSuccessSignUpModal) => set(() => ({ openSuccessSignUpModal })),
  setErrorSignUpModal: (openErrorSignUpModal) => set(() => ({ openErrorSignUpModal })),
  setAlertMotorisationModal: (openAlertMotorisationModal, motorisationModalMessage) =>
    set(() => ({ openAlertMotorisationModal, motorisationModalMessage })),
}));
