import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  const [showSecondNav, setShowSecondNav] = useState(false);

  return (
    <nav id="website_navigation" className="website_navigation">
      <div className="website_navigation_wrapper" id="navbar">
        <div className={`second_hand_switcher ${showSecondNav ? '' : 'hide'}`}>
          <NavLink to="/sale/bons_plans_piece#bons_plans_p">Pièces détachées</NavLink>
          <NavLink to="/sale/bons_plans_auto#bons_plans">Véhicules</NavLink>
        </div>
        <NavLink to="/about#about" className={({ isActive }) => (isActive ? 'nav_item active' : 'nav_item')}>
          A propos
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'nav_item active' : 'nav_item')}
          to="/#home_catalog"
        >
          Pièces de rechange
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'nav_item active' : 'nav_item')}
          to="/diagnostic#e_et_r"
        >
          Entretien & Réparation
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'nav_item active' : 'nav_item')}
          to="/consulting-services#consulting"
        >
          Services de Consulting
        </NavLink>
        <NavLink
          to={'/sale'}
          className={({ isActive }) =>
            isActive ? 'nav_item second_hand_menu active' : 'nav_item second_hand_menu'
          }
          onClick={(e) => {
            e.preventDefault();
            setShowSecondNav(!showSecondNav);
          }}
          id="second_hand_menu"
        >
          Vente d'Occasions
        </NavLink>
      </div>
    </nav>
  );
};

export default Navigation;
