import React, { useState } from 'react';
import { Controller, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleProduct from './SingleProduct';

const Products = ({ products, title, isNew, id = '' }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  return (
    <section className="products_section" id={id}>
      <h2>{title}</h2>
      <div className="swiper-container products_slider">
        <Swiper
          modules={[Controller, Pagination]}
          onSwiper={setControlledSwiper}
          controller={{ control: controlledSwiper }}
          pagination={{ clickable: true }}
          spaceBetween={30}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
          slidesPerView={4}
          className="swiper-wrapper dot"
          id="best_sellers_container"
        >
          {products?.map((p, i) => (
            <SwiperSlide
              style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
              className="swiper-slide"
              key={p.id}
            >
              <SingleProduct key={p.id} product={p} index={i} isNew={isNew} />
            </SwiperSlide>
          ))}
        </Swiper>
        <i
          onClick={() => controlledSwiper?.slideNext()}
          className="fa-solid fa-chevron-right next_slide"
        ></i>
        <i
          onClick={() => controlledSwiper?.slidePrev()}
          className="fa-solid fa-chevron-left prev_slide"
        ></i>
      </div>
    </section>
  );
};

export default Products;
