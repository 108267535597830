import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useFetch, useMediaQuery } from 'usehooks-ts';
import DragPlusIcon from '../../assets/images/icons/drag_plus.svg';
import DraganddropIcon from '../../assets/images/icons/draganddrop.svg';
import InfosIcon from '../../assets/images/icons/info.svg';
import VinTooltip from '../../components/VinTooltip';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useSearchStore } from '../../store/search';
import MobileForm from './MobileForm';
import { slugify } from '../../utils';

export function renamePiece(name) {
  return name?.replace('model:', '').replace('marque:', '').replace('(', '').replace(')');
}

const RequestAQuote = () => {
  const pictureOverlayRef = useRef(null);
  const matches = useMediaQuery('(max-width: 1024px)');

  const [files, setFiles] = useState([]);
  const [success, setSuccess] = useState(false);
  const devisAutoFillPart = useSearchStore((state) => state.devisAutoFillPart);
  const setDevisAutoFillPart = useSearchStore((state) => state.setDevisAutoFillPart);
  const vin = useSearchStore((state) => state.vin);
  const { data: partCategories } = useFetch(`${API_URL}/categoriesCP`);
  const { data: marques } = useFetch(
    `${API_URL}/${devisAutoFillPart?.bname ? 'marques' : 'marquesBMD'}`,
  );
  const defaultBrand = marques?.find((marque) => marque.nom === devisAutoFillPart?.bname);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { data: models } = useFetch(
    watch('brand')
      ? `${API_URL}/${devisAutoFillPart?.bname ? 'marques' : 'marquesBMD'}/${watch('brand')}/modeles`
      : null,
  );
  const defaultModel = models?.find((model) => model.nom === devisAutoFillPart?.mname);
  const { data: drives } = useFetch(
    watch('model') && watch('brand')
      ? `${API_URL}/${devisAutoFillPart?.bname ? 'marques' : 'marquesBMD'}/${watch(
          'brand',
        )}/modeles/${watch('model')}/drive`
      : null,
  );
  const defaultDrive = drives?.find((drive) => drive.nom === devisAutoFillPart?.dname);
  const { data: peaces } = useFetch(
    watch('category') ? `${API_URL}/categoriesCP/${watch('category')}/pieces` : null,
  );
  const { data: counter } = useFetch(`${API_URL}/counters`);
  const onDrop = useCallback((acceptedFiles) => setFiles(acceptedFiles), []);
  const onDropRejected = useCallback((files) => {
    if (files.length > 2) alert('Vous ne pouvez pas ajouter plus de 2 images');
    else alert('Vous ne pouvez ajouter que des images');
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    maxFiles: 2,
    multiple: true,
    noClick: true,
    validator: (file) => {
      if (!file.type.startsWith('image/')) {
        return {
          code: 'file-invalid-type',
          message: 'File type must be an image',
        };
      }
      return null;
    },
    onDropRejected,
  });

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('fname', data.fname);
      formData.append('lname', data.lname);
      formData.append('tel', data.tel);
      formData.append('email', data.email);
      formData.append('brand', data.brand);
      const brandname = marques?.find((marque) => marque.id === data.brand)?.nom;
      formData.append('brandname', brandname);
      formData.append('model', data.model);
      const modelname = models?.find((model) => model.id === data.model)?.nom;
      formData.append('modelname', modelname);
      formData.append('drive', data.drive);
      const drivename = drives?.find((drive) => drive.id === data.drive)?.nom;
      formData.append('drivename', drivename);
      formData.append('vin', data.vin);
      formData.append('number', +counter?.pi + 1);
      formData.append('category', data.category);
      const categoryname = partCategories?.find((category) => category.id === data.category)?.nom;
      formData.append('categoryname', categoryname);
      formData.append('parts', data.parts);
      const partsname = peaces?.find((piece) => piece.id === data.parts)?.parts;
      formData.append('partsname', partsname);
      formData.append('status', data.status);
      formData.append('quantity', data.quantity);
      formData.append('message', data.message);
      Array.from(files || []).forEach((file) => formData.append('files', file));

      await fetchApi(`${API_URL}/pi`, {
        method: 'POST',
        body: formData,
      });

      setSuccess(true);
      setFiles([]);
      reset();
      setDevisAutoFillPart(null);
    } catch (error) {
      console.log(error);
      alert('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const defaultCategory = partCategories?.find(
    (partCategory) => partCategory.nom === devisAutoFillPart?.category,
  );

  const defaultPart = peaces?.find(
    (piece) => slugify(piece.parts) === slugify(devisAutoFillPart?.parts),
  );

  useEffect(() => {
    if (defaultBrand) {
      setValue('brand', defaultBrand.id);
      setValue('status', 'new');
      setValue('vin', vin);
    }
    if (defaultModel) {
      setValue('model', defaultModel.id);
    }
    if (defaultDrive) {
      setValue('drive', defaultDrive.id);
    }

    if (defaultCategory) {
      setValue('category', defaultCategory.id);
    }

    if (defaultPart) {
      console.log('defaultPart', defaultPart);
      setValue('parts', defaultPart.parts);
    }

    return () => {};
  }, [defaultBrand, defaultCategory, defaultDrive, defaultModel, defaultPart, setValue, vin]);

  const parts = peaces; /* ?.filter((piece) => {
    const category = partCategories?.find(
      (partCategory) => slugify(partCategory.nom) === slugify(piece.category),
    );
    return category?.id === watch('category');
  }) */

  return (
    <section className="form_section">
      {success && (
        <div className="popup_overlay">
          <div className="popup sent_booking">
            <i className="fa-solid fa-check"></i>
            <h4>
              Votre demande de devis a été bien envoyée. Nous vous revenons dans les meilleurs délais.
            </h4>
            <div className="buttons">
              <button
                style={{
                  maxWidth: '100%',
                }}
                className="button_primary close_popup"
                onClick={() => setSuccess(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <h2 id="demande_devis">DEMANDE DE DEVIS</h2>
      <div className="section_content">
        <div className="text_side">
          <h3>Un moyen facile et efficace d'envoyer toutes vos demandes de devis</h3>
          <p>
            Nous prenons contact avec vous dans les plus brefs délais à la réception de votre demande de
            devis
          </p>
        </div>
        <div className="form_side">
          <h3>Envoyer une demande de devis</h3>
          {!matches && (
            <form onSubmit={handleSubmit(onSubmit)} id="pi_form">
              <div className="inputs_container">
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  placeholder="Prénom*"
                  {...register('fname', {
                    required: true,
                    pattern: /^[A-Za-z]+$/i,
                  })}
                  aria-invalid={errors.fname ? 'true' : 'false'}
                />
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  placeholder="Nom*"
                  {...register('lname', {
                    required: true,
                    pattern: /^[A-Za-z]+$/i,
                  })}
                  aria-invalid={errors.lname ? 'true' : 'false'}
                />
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  placeholder="Ind + Numéro*"
                  {...register('tel', {
                    required: true,
                    pattern: /^(\+?(00221|\+221)?\s?\d{2}\s?\d{3}\s?\d{2}\s?\d{2})$/i,
                  })}
                  aria-invalid={errors.tel ? 'true' : 'false'}
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  aria-invalid={errors.email ? 'true' : 'false'}
                />
                <select
                  name="brand"
                  id="brand_selector"
                  {...register('brand', { required: true })}
                  aria-invalid={errors.brand ? 'true' : 'false'}
                >
                  <option value="">Marque*</option>
                  {marques?.map((marque) => (
                    <option key={marque.id} value={marque.id}>
                      {marque.nom}
                    </option>
                  ))}
                  <option value="other">autre</option>
                </select>
                <select
                  name="model"
                  id="model_selector"
                  key={defaultModel?.id}
                  defaultValue={defaultModel?.id}
                  {...register('model', { required: true })}
                  aria-invalid={errors.model ? 'true' : 'false'}
                >
                  <option value="">Modèle*</option>
                  {models?.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.nom}
                    </option>
                  ))}
                  <option value="other">autre</option>
                </select>
                <select
                  name="drive"
                  id="drive_selector"
                  key={defaultDrive?.id}
                  defaultValue={defaultDrive?.id}
                  {...register('drive', { required: true })}
                  aria-invalid={errors.drive ? 'true' : 'false'}
                >
                  <option value="">Motorisation*</option>
                  {drives?.map((drive) => (
                    <option key={drive.id} value={drive.id}>
                      {drive.nom}
                    </option>
                  ))}
                  <option value="other">autre</option>
                </select>
                <div className="img_title">
                  <input
                    name="vin"
                    id="vin"
                    type="text"
                    placeholder=" Numéro de chassis(VIN)*"
                    {...register('vin', {
                      required: true,
                      pattern: /^[A-Za-z0-9]+$/i,
                    })}
                    aria-invalid={errors.vin ? 'true' : 'false'}
                  />
                  <img src={InfosIcon} alt="infos" data-tooltip-id="vin_tooltip_home" />
                  <VinTooltip id="vin_tooltip_home" />
                </div>
                <select
                  name="category"
                  id="category_selector"
                  {...register('category', { required: true })}
                  aria-invalid={errors.category ? 'true' : 'false'}
                >
                  <option value="">Catégorie*</option>
                  {partCategories?.map((partCategory) => (
                    <option key={partCategory.id} value={partCategory.id}>
                      {partCategory.nom}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  {...register('db', { required: true })}
                  style={{
                    width: '0',
                    height: '0',
                    position: 'absolute',
                  }}
                  value={devisAutoFillPart?.bname ? 'normal' : 'bmd'}
                />
                <select
                  name="parts"
                  id="parts_selector"
                  {...register('parts', { required: true })}
                  aria-invalid={errors.parts ? 'true' : 'false'}
                >
                  <option value="">Nom de la pièce*</option>
                  {parts?.map((piece) => (
                    <option key={piece.id} value={piece.parts}>
                      {renamePiece(piece.parts)}
                    </option>
                  ))}
                </select>
                <select
                  name="status"
                  id="status_selector"
                  {...register('status', { required: true })}
                  aria-invalid={errors.status ? 'true' : 'false'}
                >
                  <option value="">Etat*</option>
                  <option value="new">Pièce neuve</option>
                  <option value="secondhand">Pièce d’occasion</option>
                </select>
                <div className="quantity_input_container">
                  <span>Quantité*</span>
                  <div className="quantity">
                    <div
                      className="quantity-minus"
                      onClick={() => {
                        if (watch('quantity') > 1) setValue('quantity', +watch('quantity') - 1);
                      }}
                    >
                      -
                    </div>
                    <input
                      className="quantity_input"
                      name="quantity"
                      id="quantity"
                      placeholder="Quantité"
                      min="1"
                      {...register('quantity')}
                      max="99"
                      defaultValue="1"
                      step="1"
                      required
                    />
                    <div
                      className="quantity-plus"
                      onClick={() => setValue('quantity', +watch('quantity') + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Message"
                  {...register('message')}
                ></textarea>
                <label htmlFor='files' className="overlay_container" {...getRootProps()}>
                  <input
                    type="file"
                    name="files"
                    className="picture_hide"
                    id="files"
                    multiple
                    max={2}
                    onInput={(e) => setFiles(Array.from(e.target.files).slice(0, 2))}
                    {...register('files')}
                  />
                  <div ref={pictureOverlayRef} className="picture_overlay">
                    <img src={DraganddropIcon} alt="drag" />
                    <p>Glisser ou importer vos images (maximum 2)</p>
                    <img src={DragPlusIcon} alt="plus" className="right_image" />
                  </div>
                  {files?.length !== 0 && (
                    <div className="files_overview" onClick={() => setFiles([])}>
                      {Array.from(files || []).map((file) => (
                        <div key={file.name} className="file_overview">
                          <img src={URL.createObjectURL(file)} alt={file.name} />
                        </div>
                      ))}
                    </div>
                  )}
                </label>
              </div>
              <button className="open_sent_pi">Envoyer la demande</button>
            </form>
          )}
          {matches && (
            <MobileForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              files={files}
              pictureOverlayRef={pictureOverlayRef}
              getRootProps={getRootProps}
              peaces={peaces}
              partCategories={partCategories}
              marques={marques}
              models={models}
              filteredPieces={peaces}
              drives={drives}
              counter={counter}
              setFiles={setFiles}
              reset={reset}
              renamePiece={renamePiece}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default RequestAQuote;
