import React, { Fragment, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useLocation } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { auth } from '../configs/firebase';
import { API_URL } from '../constant/api';
import { useAuthStore } from '../store/auth';
import { useModalStore } from '../store/modal';
import { useFavoriteStore } from '../store/useFavorite';

const MainLayout = () => {
  const location = useLocation();
  const login = useAuthStore((state) => state.login);
  const setCustomer = useAuthStore((state) => state.setCustomer);
  const setLoadingUser = useAuthStore((state) => state.setLoadingUser);
  const customer = useAuthStore((state) => state.customer);
  const setFavorite = useFavoriteStore((state) => state.setFavorite);
  const setOrders = useAuthStore((state) => state.setOrders);
  const { setLoginModal, setConfirmOtpModal, setSignUpModal } = useModalStore((state) => state);
  const { data: orders } = useFetch(`${API_URL}/orders/user/${customer?.id}`);

  
  const { data: customers } = useFetch(`${API_URL}/customers`);
  const { data: likes } = useFetch(`${API_URL}/likes/user/${customer?.id}`);

  useEffect(() => {
    const urlSpit = location?.hash?.split('?');
    if (location?.hash && urlSpit) {
      setTimeout(() => {
        document.querySelector(urlSpit[0])?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start',
        });
      }, 400);
    }
    return () => {};
  }, [location]);

  useEffect(() => {
    if (orders) setOrders(orders);

    return () => {};
  }, [orders, setOrders]);

  useEffect(() => {
    if (likes) setFavorite(likes);

    return () => {};
  }, [likes, setFavorite]);


  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      login(user ?? null);
      if (user && !user?.displayName) setSignUpModal(true);

      if (customers) {
        const customer = customers?.find((customer) => customer?.authId === user?.uid);
        if (customer) setCustomer(customer);
        else if (!customer && user) setSignUpModal(true);
      }

      setLoadingUser(false);
    });

    return () => {};
  }, [login, setSignUpModal, customers, setCustomer, setConfirmOtpModal, setLoadingUser, setLoginModal]);

  return (
    <Fragment>
      <Header orders={orders} />
      <Outlet />
      <Footer />
      <Toaster />
    </Fragment>
  );
};

export default MainLayout;
