import React, { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

const Rating = ({ rating, toggleRating, incrementRating, setLastRating }) => {
  const [loading, setLoading] = useState(false);
  const handleStarClick = async (index) => {
    const nextRating = index + 1;
    if ((nextRating === rating && rating !== 1) || loading) return;
    setLastRating(rating);
    if(rating === nextRating){
      await toggleRating(0);
      incrementRating();
      toast.success(`Vous avez supprimé votre note`, {
        position: 'bottom-center',
        duration: 2000,
      });
      return;
    }
    setLoading(true);
    await toggleRating(nextRating);
    setLoading(false);
    incrementRating();
    toast.success(`Vous avez noté ce produit ${nextRating} étoile(s)`, {
      position: 'bottom-center',
      duration: 2000,
    });
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const starClass = i < rating ? 'fa-sharp fa-star' : 'fa-sharp fa-star empty';
      stars.push(<i key={i} className={starClass} onClick={() => handleStarClick(i)}></i>);
    }
    return stars;
  };

  return (
    <Fragment>
      <div className="stars">{renderStars()}</div>
      <span>({`${rating}`})</span>
    </Fragment>
  );
};

export default Rating;
