import React from 'react';
import imageHolder from '../../assets/images/logo_center.svg';
import { useNavigate } from 'react-router-dom';

function Breadcrumbs({
  subCategories,
  errorSubCategories,
  selectedCategory,
  setSelectedCategory,
  setCurrentPage,
  selectedSubCategory,
  categories,
  errorCategories,
}) {
  const navigate = useNavigate();
  const selectedCategoryData = categories?.find((c) => c?.id === selectedCategory);
  return (
    <div className="breadcrumb">
      <div className="single_breadcrumb">
        <img src={selectedCategoryData?.link ?? imageHolder} alt="" />

        {categories && !errorCategories && (
          <span>
            <select
              style={{
                cursor: 'pointer',
                width: '240px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              value={selectedCategory ?? ''}
              onChange={(e) => {
                navigate(`/catalog?category=${e.target?.value}`);
                setCurrentPage(1);
              }}
              color="transparent"
              name="filter_category"
            >
              {categories?.map((c) => (
                <option key={c?.id} value={c?.id}>
                  {c?.nom}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>
      <i className="fa-sharp fa-chevron-right"></i>
      <div className="single_breadcrumb sc">
        {subCategories && !errorSubCategories && (
          <span>
            <select
              style={{
                cursor: 'pointer',
                width: '240px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              color="transparent"
              name="filter"
              value={selectedSubCategory ?? ''}
              onChange={(e) => {
                navigate(`/catalog?category=${selectedCategory}&subCategory=${e.target?.value}`);
                setCurrentPage(1);
              }}
            >
              {subCategories?.map((sb) => (
                <option key={sb?.id} value={sb?.id}>
                  {sb?.nom}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>
      <i className="fa-sharp fa-chevron-right"></i>
    </div>
  );
}

export default Breadcrumbs;
