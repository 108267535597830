import { updateProfile } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import logoCenterImage from '../../assets/images/logo_center.svg';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../store/auth';

const ChangeUserInfosModal = ({ open, toggle }) => {
  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);
  const setCustomer = useAuthStore((state) => state.setCustomer);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const regexValidName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

  const onSubmit = async (e) => {
    e?.preventDefault();
    try {
      setLoading(true);

      await Promise.all([
        updateProfile(user, {
          displayName: firstName + ' ' + lastName,
        }),
        fetchApi(`${API_URL}/customers/${customer.id}`, {
          method: 'PUT',
          body: JSON.stringify({
            fname: firstName,
            lname: lastName,
            address: address,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      ]);

      setCustomer({ ...customer, fname: firstName, lname: lastName, address: address });
      reset();
      closeModal();
    } catch (error) {
      closeModal();
      console.log(error);
    }
  };

  useEffect(() => {
    setFirstName(customer?.fname ?? '');
    setLastName(customer?.lname ?? '');
    setAddress(customer?.address ?? '');
    return () => {};
  }, [customer?.address, customer?.fname, customer?.lname]);

  function closeModal() {
    toggle(false);
    setLoading(false);
  }

  function reset() {
    setFirstName(null);
    setLastName(null);
    setAddress(null);
  }

  const invalidFirstName = !firstName || !regexValidName.test(firstName) || firstName === customer.fname;
  const invalidLastName = !lastName || !regexValidName.test(lastName) || lastName === customer.lname;
  const invalidAddress = !address || address === customer.address;

  return (
    <Modal
      open={open}
      onClose={user?.displayName ? () => toggle(false) : () => null}
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
      styles={{ modal: { maxWidth: '500px', width: '100%' } }}
      id="login_form"
    >
      <form onSubmit={onSubmit} className="popup">
        <img alt="" src={logoCenterImage} />
        <h3>Modifier mes informations</h3>
        <div className="form_rep">
          <div className="elm">
            <label>
              Prénom<span>*</span>
            </label>
            <input
              onInput={(e) => setFirstName(e.target.value)}
              id="fname_plus"
              type="text"
              defaultValue={customer?.fname || ''}
              placeholder="Xaadim"
              disabled={true}
              readOnly
            />
          </div>
          <div className="elm">
            <label>
              Nom<span>*</span>
            </label>
            <input
              onInput={(e) => setLastName(e.target.value)}
              id="lname_plus"
              type="text"
              defaultValue={customer?.lname || ''}
              placeholder="Faal"
              disabled={true}
              readOnly
            />
          </div>
          <div className="elm">
            <label>
              Address<span>*</span>
            </label>
            <input
              onInput={(e) => setAddress(e.target.value)}
              id="address_plus"
              defaultValue={customer?.address || ''}
              type="text"
              placeholder="Grand Yoff"
            />
          </div>
          <button
            disabled={loading || !user || (invalidFirstName && invalidLastName && invalidAddress)}
            id="submit_plus"
          >
            {loading ? 'Chargement...' : 'Envoyer'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeUserInfosModal;
