import React from "react";
import CareMobileReverseImage from "../../assets/images/Liste des services.png";

const DiagnosticAbout = () => {
  return (
    <section className="white_bg">
      <div className="diagnostic_about">
        <div className="right_text">
          <h2 className="section_title">Avantages des offres d'abonnements</h2>
          <p>
            Grâce à nos offres d’abonnement & services sur mesure, votre voiture
            reste bien entretenue avec un budget maitrisé. <br />
            <br /> Inscrivez-vous juste à l’une de nos offres d’abonnement et
            profitez du <b>Confort</b> et de la <b>Sérénité</b> d’une conduite
            en toute sécurité et des services de qualités garanties (Pièces &
            Main d’oeuvre) pendant toute la durée de votre contrat chez nous.
          </p>
        </div>
        <img src={CareMobileReverseImage} alt="" />
      </div>
    </section>
  );
};

export default DiagnosticAbout;
