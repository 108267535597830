import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoCenterImage from "../../assets/images/logo_center.svg";
import { useAuthStore } from "../../store/auth";
import Modal from "react-responsive-modal";

const ConfirmOtpModal = ({ open, toggle }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const confirmResult = useAuthStore((state) => state.confirmResult);
  const setConfirmResult = useAuthStore((state) => state.setConfirmResult);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await confirmResult?.confirm(code);

      setConfirmResult(null);

      if (!res.user.displayName) {
        toggle(false);
        return navigate("?l=sign-up", { replace: true });
      }

      toggle(false);
      navigate(window.location.pathname, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => toggle(false)}
      id="phone_confirm"
      classNames={{
        overlay: "dialog_overlay",
        modal: "dialog_container",
      }}
    >
      <form onSubmit={onSubmit} className="popup">
        <img alt="" src={logoCenterImage} />
        <h3>Veuillez saisir le code reçu par SMS et cliquer sur Confirmer</h3>
        <div className="form_rep">
          <div className="elm">
            <label>Code OTP</label>
            <input
              onInput={(e) => setCode(e.target.value)}
              className="singled_input"
              type="text"
              placeholder="Saisir le code OTP"
              id="phone_code"
            />
          </div>
          <button id="submit_code">Confirmer</button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmOtpModal;
