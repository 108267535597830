import { Outlet, createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import PageNotFound from '../pages/404/PageNotFound';
import { AboutPage } from '../pages/about/AboutPage';
import BonsPlansAutoPage from '../pages/bons_plans_auto/BonsPlansAutoPage';
import BonsPlansPiecePage from '../pages/bons_plans_piece/BonsPlansPiecePage';
import BookingValidation from '../pages/booking-validation/BookingValidationPage';
import CartPage from '../pages/cart/CartPage';
import CatalogPage from '../pages/catalog/CatalogPage';
import CGU from '../pages/cgu/CGU';
import CommandePage from '../pages/commandes/CommandePage';
import ConsultingServices from '../pages/consulting-services/ConsultingServices';
import DiagnosticPage from '../pages/diagnostic/DiagnosticPage';
import HomePage from '../pages/home/HomePage';
import OrderValidationPage from '../pages/order_validation/OrderValidationPage';
import OrangePaymentPage from '../pages/paymentPage/OrangePaymentPage';
import VehiculeDetailsPage from '../pages/vehicule_details/VehiculeDetailsPage';
import SuccessPaymentPage from '../pages/paymentPage/SuccessPaymentPage';
import ErrorPaymentPage from '../pages/paymentPage/ErrorPaymentPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/catalog',
        element: <CatalogPage />,
      },
      {
        path: '/cart',
        element: <CartPage />,
      },
      {
        path: 'sale',
        element: (
          <div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: 'bons_plans_auto',
            element: <BonsPlansAutoPage />,
          },
          {
            path: 'vehicule_details/:id',
            element: <VehiculeDetailsPage />,
          },
          {
            path: 'bons_plans_piece',
            element: <BonsPlansPiecePage />,
          },
        ],
      },
      {
        path: '/order/validation',
        element: <OrderValidationPage />,
      },
      {
        path: '/diagnostic',
        element: <DiagnosticPage />,
      },
      {
        path: '/consulting-services',
        element: <ConsultingServices />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/booking-validation',
        element: <BookingValidation />,
      },
      {
        path: '/commandes',
        element: <CommandePage />,
      },
      {
        path: 'cgu',
        element: <CGU />,
      },
    ],
  },
  {
    path: '/payment',
    children: [
      {
        path: 'om',
        element: <OrangePaymentPage />,
      },
      {
        path: 'success',
        element: <SuccessPaymentPage />,
      },
      {
        path: 'error',
        element: <ErrorPaymentPage />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

export default router;
