import React, { Fragment } from 'react';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../store/auth';
import { useCartStore } from '../../store/cart';
import { useFavoriteStore } from '../../store/useFavorite';
import { formatNumberHumanReadable } from '../../utils';

const SingleProduct = ({ product, isNew }) => {
  const cart = useCartStore((state) => state.cart);
  const addToCart = useCartStore((state) => state.addToCart);
  const incrementQuantity = useCartStore((state) => state.incrementQuantity);
  const decrementQuantity = useCartStore((state) => state.decrementQuantity);
  const favorite = useFavoriteStore((state) => state.favorite);
  const addToFavorite = useFavoriteStore((state) => state.addToFavorite);
  const removeFromFavorite = useFavoriteStore((state) => state.removeFromFavorite);
  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);

  const isFavorite = favorite.find((item) => item.caref === product.caref);
  const isCart = cart.find((item) => item.id === product.id);

  async function toggleFavorite(p) {
    try {
      if (isFavorite) {
        removeFromFavorite(p);
        if (user) {
          await fetchApi(`${API_URL}/likes/${customer.id}/${product?.product_id ?? product?.id}`, {
            method: 'DELETE',
          });
        }
      } else {
        addToFavorite(p);
        if (user) {
          const data = {
            user_id: user?.id,
            product_id: product?.id,
            customer_id: customer.id,
            price: product?.price,
            brand: product?.brand,
            category: product?.category,
            model: product?.model,
            vendor: product?.vendor,
            parts: product?.parts,
            caref: product?.caref,
            link: product?.link,
          };

          const formData = new FormData();

          for (const key in data) {
            formData.append(key, data[key]);
          }

          await fetchApi(`${API_URL}/likes`, {
            method: 'POST',
            body: formData,
            Headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      }
    } catch (error) {
      console.error('add favorite', error);
    }
  }

  return (
    <Fragment>
      <div className={`single_product ${isFavorite ? 'favorite' : ''}`}>
        <img src={product.link} alt="" />
        <h3>{product.parts}</h3>
        <div className="packed_text">
          <p>{product.brand}</p>
          <p>{product.category}</p>
          <p>{product.model}</p>
        </div>
        <strong>{formatNumberHumanReadable(product.price)} FCFA</strong>

        {!isCart ? (
          <button
            disabled={isCart}
            onClick={() => {
              addToCart({ ...product, quantity: 1 }, isNew);
            }}
            className="btn"
          >
            <i className="fa-solid fa-cart-shopping"></i>
          </button>
        ) : (
          <button className="btn quantity">
            <div className="quantity_control">
              <div>
                <button disabled={isCart.quantity === 1} onClick={() => decrementQuantity(isCart)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="3" viewBox="0 0 10 3">
                    <path d="M0 2.1V0H9.09V2.1H0Z"></path>
                  </svg>
                </button>
                <span>{isCart.quantity}</span>
                <button onClick={() => incrementQuantity(isCart)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path d="M0 7.89V5.85H5.67V0H7.77V5.85H13.5V7.89H7.77V13.8H5.67V7.89H0Z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </button>
        )}

        <div
          className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart`}
          onClick={() => toggleFavorite(product)}
        ></div>
      </div>
    </Fragment>
  );
};

export default SingleProduct;
