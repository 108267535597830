import { API_URL } from "../constant/api";

export async function getVehicules() {
  const response = await fetch(`${API_URL}/vehicles`);
  const vehicules = await response.json();
  return vehicules;
}
export async function getVehicule(id) {
  const response = await fetch(`${API_URL}/vehicles/${id}`);
  const vehicule = await response.json();
  return vehicule;
}
