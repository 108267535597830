import React from 'react';
import { Autoplay, Navigation as Nav } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slide3Image from '../../assets/images/Image Header Services de consulting 4.png';
import Slide4Image from "../../assets/images/Image Header ventes d'occasions pièces détachées.png";
import Slide5Image from "../../assets/images/Image header ventes voitures d'occasion.png";
import Slide2Image from '../../assets/images/diagnostic2.png';
import Slide6Image from '../../assets/images/hero_slides/slide_1.png';
import Slide1Image from '../../assets/images/hero_slides/slide_2.png';
import Navigation from '../../components/navigation/Navigation';
import AboutSection from './AboutSection';
import Methodology from './Methodology';
import Partenaires from './Partenaires';
import { useDocumentTitle } from 'usehooks-ts';

export function AboutPage() {
  useDocumentTitle('CAREF | A propos');

  return (
    <div style={{ background: '#F7FAFC' }}>
      <HeroSlider />
      <Navigation />
      <Methodology />
      <AboutSection />
      <Partenaires />
    </div>
  );
}

export const HeroSlider = () => {
  return (
    <Swiper
      className="swiper-container hero_slider"
      modules={[Nav, Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      navigation
      loop
    >
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide1Image} className="background_img" />
        <h2>
          Commandez votre pièces de rechange de qualité garantie avec notre application web en seulement
          quelques clics!
        </h2>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide2Image} className="background_img" />
        <h2>
          Des services d’entretien et de maintenance dans le plus grand respect des préconisations des
          constructeurs automobiles
        </h2>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide3Image} className="background_img" />
        <h2>
          ACHETEZ ou VENDEZ votre véhicule en toute transparence grâce à nos Packs et à nos prestations
          de services de qualité.
        </h2>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide4Image} className="background_img" />
        <h2>Une gamme large et variée de pièces d'occasion garanties</h2>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide5Image} className="background_img" />
        <h2>Une gamme large et variée de véhicules d'occasion certifiés et garantis</h2>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <img alt="" src={Slide6Image} className="background_img" />
        <h2>Des services automobiles sous un nouvel angle</h2>
      </SwiperSlide>
    </Swiper>
  );
};
