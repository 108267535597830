import React, { Fragment, useEffect, useState } from 'react';
import { getVehicule } from '../../libs/vehicules';
import { useParams } from 'react-router-dom';
import VehiculeDetails from './VehiculeDetails';
import VehiculeBonPlans from '../../components/vehicule-bon-plans/VehiculeBonPlans';
import { usePagination } from '../../hooks/usePagination';
import HeroSlider from '../../components/hero-slider/HeroSlider';
import Navigation from '../../components/navigation/Navigation';
import headerImage from "../../assets/images/Image header ventes voitures d'occasion.png";
import { useDocumentTitle } from 'usehooks-ts';

function VehiculeDetailsPage() {
  useDocumentTitle("CAREF | Vente de véhicules d'occasion");

  let headerText = "Une gamme large et variée de véhicules d'occasion certifiés et garantis";

  let { id } = useParams();
  const [vehicule, setVehicule] = useState(null);
  const { products, showMore, loadMore, toggleShowMore } = usePagination(10, 10);
  useEffect(() => {
    const fetchDatas = async () => {
      const vehicule = await getVehicule(id);
      setVehicule(vehicule);
    };
    fetchDatas();
  }, [id]);
  return (
    <Fragment>
      <HeroSlider image={headerImage} text={headerText} />
      <Navigation />
      <VehiculeDetails vehicule={vehicule} />
      <div className="bons_plans">
        <h2>Voir aussi</h2>
        <div className="used_vehicles_container">
          {products.length > 0 ? (
            products?.map((vehicule, index) => <VehiculeBonPlans vehicule={vehicule} key={index} />)
          ) : (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}
        </div>
        {showMore ? (
          <button className="view_more" onClick={loadMore}>
            View More
          </button>
        ) : (
          <button className="view_more" onClick={toggleShowMore}>
            View Less
          </button>
        )}
      </div>
    </Fragment>
  );
}

export default VehiculeDetailsPage;
