export function isValidFirebaseId(id) {
  return typeof id === 'string' && id.length === 20;
}

export function slugify(string) {
  if (typeof string !== 'string') return string;
  return string
    .trim()
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

export const getCatalogUrl = ({ category, subCategory }) => {
  return `/catalog?category=${category?.trim() ?? ''}&subCategory=${subCategory?.trim() ?? ''}`;
};

export const dateLivraison = (availability) => {
  if (availability?.toLowerCase() === 'en stock') {
    return 'Livraison sous 24h';
  } else if (availability?.toLowerCase() === 'disponible') {
    return 'Livraison sous 72h';
  } else {
    return 'Date de livraison non disponible, nous allons vous revenir dans les meilleurs delais';
  }
};


export function formatNumberHumanReadable(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
