import React from 'react';
import HeroSlider from '../../components/hero-slider/HeroSlider';
import Navigation from '../../components/navigation/Navigation';
import Meet from './Meet';
import MethodologySection from './MethodologySection';
import headerImage from '../../assets/images/Image Header Services de consulting 4.png';
import { useDocumentTitle } from 'usehooks-ts';

const ConsultingServices = () => {
  useDocumentTitle('CAREF | Services de consulting');

  let headerText =
    'ACHETEZ ou VENDEZ votre véhicule en toute transparence grâce à nos Packs et à nos prestations de services de qualité.';
  return (
    <div style={{ background: '#F7FAFC' }}>
      <HeroSlider image={headerImage} text={headerText} />
      <Navigation />
      <MethodologySection />
      <Meet />
    </div>
  );
};

export default ConsultingServices;
