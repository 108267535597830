import { useEffect, useState } from "react";
import { getVehicules } from "../libs/vehicules";

export function usePagination(initialVisible = 10, step = 10) {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(initialVisible);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    getVehicules()
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        
      });
  }, []);

  const loadMore = () => {
    if (visibleProducts + step >= products.length) {
      setVisibleProducts(products.length);
      setShowMore(false);
    } else {
      setVisibleProducts(visibleProducts + step);
    }
  };

  const toggleShowMore = () => {
    if (showMore) {
      setVisibleProducts(products.length);
      setShowMore(false);
    } else {
      setVisibleProducts(initialVisible);
      setShowMore(true);
    }
  };

  return {
    products: products.slice(0, visibleProducts),
    showMore,
    loadMore,
    toggleShowMore,
  };
}
