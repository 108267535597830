import React, { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFetch } from 'usehooks-ts';
import imageHolder from '../../assets/images/logo_center.svg';
import { API_URL } from '../../constant/api';
import { useModalStore } from '../../store/modal';
import { useSearchStore } from '../../store/search';
import { getCatalogUrl, slugify } from '../../utils';
import { useNavigate } from 'react-router-dom';

const Part = ({ part, searchPart }) => {
  return (
    <div
      className="single_category"
      style={{
        justifyContent: 'left',
      }}
      onClick={(e) => {
        e.stopPropagation();
        searchPart(part);
      }}
    >
      <img alt="" src={part?.link ?? imageHolder} />
      <span
        style={{
          textAlign: 'left',
        }}
      >
        {part?.parts}
      </span>
    </div>
  );
};

const PartSubCategory = ({
  subCategory,
  url,
  searchPart,
  piecesByMMD,
  clickedOnSearch,
  categoryName,
}) => {
  const { data: parts } = useFetch(`${url}/${subCategory.id}/pieces`);

  let selectedPart = parts;

  if (clickedOnSearch) {
    selectedPart = piecesByMMD?.filter(
      (part) =>
        slugify(part.sous_category) === slugify(subCategory.nom) &&
        slugify(part.category) === slugify(categoryName),
    );
  }

  return (
    <div className="piece_category" id="piece_category_container">
      <div className="category_name hide_mobile">
        <h2>{subCategory.nom}</h2>
      </div>
      <div
        style={{
          display: selectedPart?.length === 0 ? 'flex' : 'grid',
        }}
        className="category_content hide_mobile"
      >
        {selectedPart?.map((part) => (
          <Part key={part.id} part={part} searchPart={searchPart} />
        ))}
        {selectedPart?.length === 0 && (
          <div
            style={{
              cursor: 'default',
              padding: '1.5rem 0',
              textAlign: 'left',
            }}
            className="center"
          >
            Pas de pièces disponible pour le moment. Veuillez nous contacter ou bien demander un devis en
            ligne
          </div>
        )}
      </div>
    </div>
  );
};

const PartSubCategoryMobile = ({
  subCategory,
  url,
  searchPart,
  piecesByMMD,
  clickedOnSearch,
  categoryName,
}) => {
  const { data: parts } = useFetch(`${url}/${subCategory.id}/pieces`);
  const [show, setShow] = useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;
  const endOffset = itemOffset + itemsPerPage;

  let selectedPart = parts;

  if (clickedOnSearch) {
    selectedPart = piecesByMMD?.filter(
      (part) =>
        slugify(part.sous_category) === slugify(subCategory.nom) &&
        slugify(part.category) === slugify(categoryName),
    );
  }

  const currentItems = selectedPart?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil((selectedPart?.length ?? 0) / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % selectedPart.length;
    setItemOffset(newOffset);
  };

  return (
    <div onClick={() => setShow(!show)} className="piece_category" id="piece_category_container">
      <div className="piece_category_mobile_title">
        <h3
          style={{
            color: show ? 'var(--main-color)' : '#3A3A3A',
          }}
        >
          {subCategory.nom}
        </h3>
        <i
          style={{
            color: show ? 'var(--main-color)' : '#3A3A3A',
          }}
          className={`fa-solid ${show ? 'fa-chevron-up' : 'fa-chevron-down'}`}
        ></i>
      </div>
      {show && (
        <Fragment>
          <div className="category_content">
            {currentItems?.map((part) => (
              <Part key={part.id} part={part} searchPart={searchPart} />
            ))}

            {currentItems?.length === 0 && (
              <div
                style={{
                  cursor: 'default',
                  padding: '1.5rem 0',

                  textAlign: 'left',
                }}
                className="center"
              >
                Pas de pièces disponible pour le moment. Veuillez nous contacter ou bien demander un
                devis en ligne
              </div>
            )}
          </div>
          <div
            className="mobile_pagination_container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="mobile_pagination"
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const SectionContent = ({
  selectedPartCategory,
  partSubCategories,
  partSubCategoriesError,
  searchPart,
  piecesByMMD,
  clickedOnSearch,
  categoryName,
}) => {
  return (
    <div className="section_content hide_mobile">
      {partSubCategoriesError && <div className="error">Une erreur est survenue</div>}
      {partSubCategories &&
        !partSubCategoriesError &&
        partSubCategories?.map((partSubCategory) => (
          <PartSubCategory
            key={partSubCategory.id}
            searchPart={searchPart}
            url={`${API_URL}/categories_piece/${selectedPartCategory}/subcategories`}
            subCategory={partSubCategory}
            piecesByMMD={piecesByMMD}
            clickedOnSearch={clickedOnSearch}
            categoryName={categoryName}
          />
        ))}
      {!partSubCategories && !partSubCategoriesError && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </div>
  );
};

const PiecesCatalogue = () => {
  const navigate = useNavigate();
  const vin = useSearchStore((state) => state.vin);
  const setVinModal = useSearchStore((state) => state.setVinModal);
  const clickedOnSearch = useSearchStore((state) => state.clickedOnSearch);
  const setClickedOnSearch = useSearchStore((state) => state.setClickedOnSearch);
  const setVin = useSearchStore((state) => state.setVin);
  const setAlertMotorisationModal = useModalStore((state) => state.setAlertMotorisationModal);
  const { data: partCategories, error: partCategoriesError } = useFetch(`${API_URL}/categories_piece`);
  const [selectedPartCategory, setSelectedPartCategory] = useState(null);
  const selectedSearchOption = useSearchStore((state) => state.searchOptions);
  const setSelectedSearchOption = useSearchStore((state) => state.setSelected);
  const CATEGORIES_PER_VIEW = 10;
  const [mobilePartCategories, setMobilePartCategories] = useState([]);
  const [mobilePartCategoriesPage, setMobilePartCategoriesPage] = useState(1);
  const { data: partSubCategories, error: partSubCategoriesError } = useFetch(
    selectedPartCategory ? `${API_URL}/categories_piece/${selectedPartCategory}/subcategories` : null,
  );
  const { data: piecesByMMD } = useFetch(
    selectedSearchOption.selectedMarque &&
      selectedSearchOption.selectedModel &&
      selectedSearchOption.selectedDrive
      ? `${API_URL}/marques/${selectedSearchOption.selectedMarque}/modeles/${selectedSearchOption.selectedModel}/drive/${selectedSearchOption.selectedDrive}/pieces`
      : null,
  );
  const categoryName = partCategories?.find((category) => category.id === selectedPartCategory)?.nom;

  const isSelectedMMD =
    selectedSearchOption.selectedMarque &&
    selectedSearchOption.selectedModel &&
    selectedSearchOption.selectedDrive;

  function searchPart(part) {
    const category = partCategories?.find((category) => category.nom === part?.category)?.id;
    const subCategory = partSubCategories?.find((s) => s.nom === part?.sous_category)?.id;

    const url = getCatalogUrl({
      category,
      subCategory,
    });

    if (!isSelectedMMD) {
      document.querySelector('.header_bottom')?.scrollIntoView({
        behavior: 'smooth',
      });
      return setAlertMotorisationModal(
        true,
        'Veuillez sélectionner une marque, un modèle et une motorisation',
      );
    }
    if (!vin) setVinModal(true, url);
    else navigate(url);

    //setVin(null);
  }

  const [controlledSwiper, setControlledSwiper] = useState(null);

  useEffect(() => {
    if (partCategories) {
      setSelectedPartCategory(partCategories[0]?.id ?? null);
      setMobilePartCategories(partCategories.slice(0, CATEGORIES_PER_VIEW));
    }
    return () => {};
  }, [partCategories]);

  function loadMoreMobileCategories() {
    if (mobilePartCategories?.length === partCategories?.length) {
      setMobilePartCategoriesPage(1);
      navigate('/#home_catalog');
      return setMobilePartCategories(partCategories.slice(0, CATEGORIES_PER_VIEW));
    }

    setMobilePartCategoriesPage((prev) => prev + 1);
    setMobilePartCategories((prev) => [
      ...prev,
      ...partCategories.slice(
        mobilePartCategoriesPage * CATEGORIES_PER_VIEW,
        (mobilePartCategoriesPage + 1) * CATEGORIES_PER_VIEW,
      ),
    ]);
  }

  return (
    <section className="pieces_catalogue" id="catalogue_pieces">
      <h2 id="home_catalog">
        CATALOGUE DES PIÈCES
        {clickedOnSearch && (
          <span>
            <i className="fa-solid fa-exclamation-triangle"></i>
            vous êtes entrain de filtrer par marque, modèle et motorisation{' '}
            <b
              onClick={() => {
                setClickedOnSearch(false);
                setSelectedSearchOption({
                  ...selectedSearchOption,
                  selectedMarque: null,
                  selectedModel: null,
                  selectedDrive: null,
                });
              }}
            >
              annuler
            </b>
          </span>
        )}
      </h2>
      <div className="section_container">
        <div className="left_sidebar hide_mobile">
          <i
            onClick={() => {
              const currentSlide = controlledSwiper?.realIndex;
              controlledSwiper?.slideTo(currentSlide - 5);
            }}
            className="fa-solid fa-chevron-up prev_slide"
          ></i>
          <div className="swiper-container">
            {partCategoriesError && <div className="error">Une erreur est survenue</div>}
            {partCategories && !partCategoriesError && (
              <Swiper
                modules={[Controller]}
                onSwiper={setControlledSwiper}
                controller={{ control: controlledSwiper }}
                direction={'vertical'}
                slidesPerView={5}
                className="swiper-wrapper"
                id="categories_catalogue"
              >
                {partCategories?.map((partCategory) => (
                  <SwiperSlide
                    key={partCategory.id}
                    onClick={() => setSelectedPartCategory(partCategory.id)}
                    className={`swiper-slide category ${
                      selectedPartCategory === partCategory.id ? 'active' : ''
                    }`}
                  >
                    <img src={partCategory.link ?? imageHolder} alt="" />{' '}
                    <span
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      {partCategory.nom}{' '}
                    </span>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {!partCategories && !partCategoriesError && <span className="loader"></span>}
          </div>
          <i
            onClick={() => {
              const currentSlide = controlledSwiper?.realIndex;
              controlledSwiper?.slideTo(currentSlide + 5);
            }}
            className="fa-solid fa-chevron-down next_slide"
          ></i>
        </div>
        {mobilePartCategories?.map((partCategory) => (
          <div
            key={partCategory.id}
            onClick={() => {
              setSelectedPartCategory(partCategory.id);
              document.querySelector('#parts_mobile')?.scrollIntoView({
                behavior: 'smooth',
              });
            }}
            className={`section_content hide_desktop ${
              selectedPartCategory === partCategory.id ? 'active' : ''
            }`}
          >
            <div className="piece_category">
              <div
                className={`category_name hide_desktop ${
                  selectedPartCategory === partCategory.id ? 'active' : ''
                }`}
              >
                <img src={partCategory.link ?? imageHolder} alt="" /> {partCategory.nom}
              </div>
            </div>
          </div>
        ))}

        {selectedPartCategory && (
          <SectionContent
            partCategories={partCategories}
            selectedPartCategory={selectedPartCategory}
            partSubCategories={partSubCategories}
            partSubCategoriesError={partSubCategoriesError}
            piecesByMMD={piecesByMMD}
            clickedOnSearch={clickedOnSearch}
            categoryName={categoryName}
            searchPart={searchPart}
          />
        )}
      </div>

      <div
        style={{
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem',
        }}
        className="center hide_desktop"
      >
        {!partCategories && !partCategoriesError && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
        <button onClick={loadMoreMobileCategories} className="view_more hide_desktop">
          {mobilePartCategories?.length === partCategories?.length ? 'Voir moins' : 'voir plus'}
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem 0',
          borderColor: 'var(--main-color)',
        }}
        className="hide_desktop"
      >
        <hr
          style={{
            width: '200px',
            borderColor: 'var(--main-color)',
          }}
        />
      </div>
      <div id="parts_mobile" className="section_container bottom hide_desktop">
        <div className="section_content">
          {partSubCategories?.map((subCategory) => (
            <PartSubCategoryMobile
              key={subCategory.id}
              searchPart={searchPart}
              subCategory={subCategory}
              piecesByMMD={piecesByMMD}
              clickedOnSearch={clickedOnSearch}
              categoryName={categoryName}
              url={`${API_URL}/categories_piece/${selectedPartCategory}/subcategories`}
            />
          ))}

          {!partSubCategories && !partSubCategoriesError && (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PiecesCatalogue;
