import React from 'react';
import { MdOutlineDone } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function CartModal({ open, setOpen }) {
  let navigate = useNavigate();
  return (
    <div
      onClick={() => {
        setOpen(false);
      }}
      className={`popup_overlay fade_left ${open ? '' : 'hide'}`}
      id="vin_form"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="popup"
      >
        <p>
          <p
            className="elm"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#2ecc71',
            }}
          >
            <MdOutlineDone size={80} />
          </p>
          Votre produit a bien été ajouté dans votre panier.
        </p>
        <div
          className="btn_cart"
          style={{
            display: 'flex',
            gap: '1.5rem',
          }}
        >
          <button
            id="submit_plus"
            style={{
              backgroundColor: '#fff',
              fontSize: '1.0625rem',
              border: '2px solid black',
              padding: '0.5rem 1rem',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(false)}
          >
            continuer vos achats
          </button>
          <button
            style={{
              backgroundColor: '#e84610',
              fontSize: '1.0625rem',
              border: 'none',
              color: '#fff',
              padding: '0.5rem 1rem',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            id="submit_plus"
            onClick={() => navigate('/cart')}
          >
            Passer au panier
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartModal;
