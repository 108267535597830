import React from 'react';
import { useCartStore } from '../../store/cart';

function QuantityInput({ piece }) {
  const incrementQuantity = useCartStore((state) => state.incrementQuantity);
  const decrementQuantity = useCartStore((state) => state.decrementQuantity);

  return (
    <div className="quantity_input_container">
      <div
        className="quantity-minus"
        onClick={() => {
          if (piece?.quantity > 1) {
            decrementQuantity(piece);
          }
        }}
      >
        -
      </div>
      <input className="quantity_input" name="Quantité" value={piece?.quantity} readOnly />
      <div className="quantity-plus" onClick={() => incrementQuantity(piece)}>
        +
      </div>
    </div>
  );
}

export default QuantityInput;
