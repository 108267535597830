import { create } from 'zustand';

export const useBookingStore = (() => {
  return create((set) => ({
    booking: null,
    formData: null,
    success: false,
    setBooking: (booking) => set(() => ({ booking })),
    clearBooking: () => set(() => ({ booking: null })),
    setFormData: (formData) => set(() => ({ formData })),
    clearFormData: () => set(() => ({ formData: null })),
    setSuccess: (success) => set(() => ({ success })),
  }));
})();
