import React from 'react';
import OrangeImage from '../../assets/images/icons/orange.png';
import { useCartStore } from '../../store/cart';
import { useDocumentTitle } from 'usehooks-ts';

const OrangePaymentPage = () => {
  useDocumentTitle('CAREF | Paiement');
  const omPaymentResponse = useCartStore((state) => state.omPaymentResponse);

  const base64ToImage = (base64) => {
    return `data:image/png;base64,${base64}`;
  };

  return (
    <div className="orange_payment_page">
      <div className="back">
        <a href="/commandes">
          <i className="fas fa-arrow-left"></i>Mes commands
        </a>
      </div>
      <h2>
        <img src={OrangeImage} alt="orange" />
        Scannez le QR code pour payer
      </h2>
      <div className="qr_code">
        <img src={base64ToImage(omPaymentResponse?.qrCode)} alt="" />
      </div>
      <p>Ou ouvrir sur</p>
      <div className="deep_link">
        <a href={omPaymentResponse?.deepLinks.MAXIT} target="_blank" rel="noreferrer">
          <span className="maxit">
            Max <b>it</b>
          </span>
        </a>

        <a href={omPaymentResponse?.deepLinks.OM} target="_blank" rel="noreferrer">
          <span className="maxit">
            Orange <b>Money</b>
          </span>
        </a>
      </div>
    </div>
  );
};
export default OrangePaymentPage;
