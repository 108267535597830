import React from 'react';
import { useModalStore } from '../../store/modal';

const AlertMotorisationModal = ({ closeMenu }) => {
  const [open, setOpen, msg] = useModalStore((state) => [
    state.openAlertMotorisationModal,
    state.setAlertMotorisationModal,
    state.motorisationModalMessage,
  ]);

  return (
    <div
      style={{
        display: open ? 'flex' : 'none',
      }}
      className="popup_overlay"
      onClick={() => setOpen(false)}
    >
      <div className="popup sent_booking" onClick={(e) => e.stopPropagation()}>
        <i className="fas fa-exclamation-triangle orange"></i>

        <h4>{msg} pour continuer!</h4>
        <div className="buttons">
          <button
            style={{
              maxWidth: '100%',
              width: '100%',
            }}
            className="button_primary close_popup"
            onClick={() => {
              setOpen(false);
              closeMenu(false);
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertMotorisationModal;
