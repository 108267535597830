import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFetch, useMediaQuery, useToggle } from 'usehooks-ts';
import barsImage from '../../assets/images/icons/bars.png';
import caImage from '../../assets/images/icons/ca.svg';
import cartImage from '../../assets/images/icons/cart.svg';
import closeImage from '../../assets/images/icons/close.png';
import commandsImage from '../../assets/images/icons/commands.svg';
import emailImage from '../../assets/images/icons/email.svg';
import heartImage from '../../assets/images/icons/heart.svg';
import phoneImage from '../../assets/images/icons/phone.svg';
import logoCenterImage from '../../assets/images/logo_center.svg';
import { API_URL } from '../../constant/api';
import { fetchApi } from '../../libs/fetch';
import { useAuthStore } from '../../store/auth';
import { useCartStore } from '../../store/cart';
import { useModalStore } from '../../store/modal';
import { useSearchStore } from '../../store/search';
import { useFavoriteStore } from '../../store/useFavorite';
import { getCatalogUrl, slugify } from '../../utils';
import AlertMotorisationModal from './AlertMotorisationModal';
import ConfirmOtpModal from './ConfirmOtpModal';
import ErrorSignUpModal from './ErrorSignUpModal';
import LoginModal from './LoginModal';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
import QuickAccessBooking from './QuickAccessBooking';
import SearchCatalogPiece from './SearchCatalogPiece';
import SignUpModal from './SignUpModal';
import SuccessSignUpModal from './SuccessSignUpModal';
import UserProfile from './UserProfile';
import VinModal from './VinModal';
import SearchResContainer from './SearchResContainer';

const Header = ({ orders }) => {
  let navigate = useNavigate();
  const favorite = useFavoriteStore((state) => state.favorite);
  const menuRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const setClickedOnSearch = useSearchStore((state) => state.setClickedOnSearch);
  const searchInputRef = useRef(null);
  const [searchCatalogOption, setSearchCatalogOption] = useSearchStore((state) => [
    {
      selectedMarque: state.searchOptions.selectedMarque,
      selectedModel: state.searchOptions.selectedModel,
      selectedDrive: state.searchOptions.selectedDrive,
      selectedMarqueName: state.searchOptions.selectedMarqueName,
      selectedModelName: state.searchOptions.selectedModelName,
      selectedDriveName: state.searchOptions.selectedDriveName,
    },
    state.setSelected,
  ]);
  const cart = useCartStore((state) => state.cart);
  const user = useAuthStore((state) => state.user);
  const vin = useSearchStore((state) => state.vin);
  const setVinModal = useSearchStore((state) => state.setVinModal);
  const setVin = useSearchStore((state) => state.setVin);
  const sowQuickAccessModal = useModalStore((state) => state.setQuickAccessBooking);
  const {
    openLoginModal,
    setLoginModal,
    openConfirmOtpModal,
    setConfirmOtpModal,
    openSignUpModal,
    setSignUpModal,
    openSuccessSignUpModal,
    setSuccessSignUpModal,
    openErrorSignUpModal,
    setErrorSignUpModal,
    setAlertMotorisationModal,
  } = useModalStore((state) => state);

  const [searchRes, setSearchRes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [showMenu, toggleMenu, setShowMenu] = useToggle(false);
  const { data: partCategories, error: partCategoriesError } = useFetch(`${API_URL}/categories_piece`);
  const [selectedPartCategory, setSelectedPartCategory] = useState(null);
  const [selectedPartSubCategories, setSelectedPartSubCategories] = useState(null);
  const { data: partSubCategories, error: partSubCategoriesError } = useFetch(
    `${API_URL}/categories_piece/${selectedPartCategory}/subcategories`,
  );
  const { data: parts, error: partsError } = useFetch(
    `${API_URL}/categories_piece/${selectedPartCategory}/subcategories/${selectedPartSubCategories}/pieces`,
  );

  const isSearchByMMD =
    searchCatalogOption.selectedMarque &&
    searchCatalogOption.selectedModel &&
    searchCatalogOption.selectedDrive;

  function searchPart(part) {
    const category = partCategories?.find(
      (c) => c.nom.toLowerCase() === part?.category.toLowerCase(),
    )?.id;
    const subCategory = partSubCategories?.find(
      (s) => s.nom.toLowerCase() === part?.sous_category.toLowerCase(),
    )?.id;

    const url = getCatalogUrl({
      category,
      subCategory,
    });

    if (!isSearchByMMD) {
      document.querySelector('.header_bottom')?.scrollIntoView({
        behavior: 'smooth',
      });
      return setAlertMotorisationModal(
        true,
        'Veuillez sélectionner une marque, un modèle et une motorisation',
      );
    }

    navigate(url);

    setSearchRes([]);
    setIsSearch(false);
    if (searchInputRef.current) searchInputRef.current.value = '';
  }

  function onSearchInput(e) {
    setSearchTerm(e.target.value.trim());
    if (e.target.value.trim().length === 0) {
      setSearchRes([]);
      setIsSearch(false);
    }
  }

  async function onSearch(e) {
    e.preventDefault();
    try {
      setIsSearch(true);
      setSearching(true);

      const res = await fetchApi(`${API_URL}/parts/caref/${searchTerm}`);

      if (!('id' in res)) return setSearching(false);

      const marques = await fetchApi(`${API_URL}/marques`);
      const marque = marques?.find((marque) => slugify(marque?.nom) === slugify(res?.brand));

      if (!marque) return setSearching(false);

      const models = await fetchApi(`${API_URL}/marques/${marque?.id}/modeles`);
      const model = models?.find((model) => slugify(model?.nom) === slugify(res?.model));

      if (!model) return setSearching(false);

      const drives = await fetchApi(`${API_URL}/marques/${marque?.id}/modeles/${model?.id}/drive`);
      const drive = drives?.find((drive) => slugify(drive?.nom) === slugify(res?.drive));

      if (!drive) return setSearching(false);

      setSearchCatalogOption({
        selectedMarque: marque?.id,
        selectedModel: model?.id,
        selectedDrive: drive?.id,
        selectedMarqueName: marque?.nom,
        selectedModelName: model?.nom,
        selectedDriveName: drive?.nom,
      });

      setSearching(false);

      setSearchRes([res]);
    } catch (error) {
      setSearching(false);
      console.log(error);
    }
  }

  function toggleSubMenu(e) {
    e.preventDefault();
    const container = e.target?.getAttribute('data-container-target');
    const submenuContainer = document.getElementById(container);
    const submenus = document.querySelectorAll('.submenu_container');
    submenuContainer?.classList.toggle('hide');
    submenus?.forEach((submenu) => (submenu?.id !== container ? submenu.classList.add('hide') : null));
  }

  function closeMenu() {
    toggleMenu();
    setSelectedPartCategory(null);
    setSelectedPartSubCategories(null);
    const submenus = document.querySelectorAll('.submenu_container');
    submenus?.forEach((submenu) => submenu.classList.add('hide'));
  }

  const handleSearchCatalogPeace = () => {
    const url = getCatalogUrl({
      category: selectedPartCategory,
      subCategory: selectedPartSubCategories,
    });

    if (!isSearchByMMD) {
      document.querySelector('.header_bottom')?.scrollIntoView({
        behavior: 'smooth',
      });
      return setAlertMotorisationModal(
        true,
        'Veuillez sélectionner une marque, un modèle et une motorisation',
      );
    }

    if (!vin) setVinModal(true, url);
    else navigate(url);

    //setVin(null);
    closeMenu();
  };

  const homeSearchCatalogPeace = (e) => {
    if (e) e?.preventDefault();
    if (
      !searchCatalogOption.selectedMarque ||
      !searchCatalogOption.selectedModel ||
      !searchCatalogOption.selectedDrive
    ) {
      document.querySelector('.header_bottom')?.scrollIntoView({
        behavior: 'smooth',
      });
      return setAlertMotorisationModal(
        true,
        'Veuillez sélectionner une marque, un modèle et une motorisation',
      );
    }

    setClickedOnSearch(true);

    navigate(`/#home_catalog`);
  };

  return (
    <header>
      <QuickAccessBooking />
      <AlertMotorisationModal closeMenu={setShowMenu} />
      <VinModal />
      <div className="header_top">
        <div className={`menu_container ${!showMenu ? '' : 'show'}`} ref={menuRef}>
          <div onClick={closeMenu} className="menu_icon">
            {showMenu ? <img alt="" src={closeImage} /> : <img alt="" src={barsImage} />}
          </div>
          {showMenu && isDesktop && (
            <Menu
              menuRef={menuRef}
              showMenu={showMenu}
              closeMenu={closeMenu}
              toggleSubMenu={toggleSubMenu}
              setSelectedPartCategory={setSelectedPartCategory}
              setSelectedPartSubCategories={setSelectedPartSubCategories}
              selectedPartCategory={selectedPartCategory}
              selectedPartSubCategories={selectedPartSubCategories}
              partCategories={partCategories}
              partCategoriesError={partCategoriesError}
              partSubCategories={partSubCategories}
              partSubCategoriesError={partSubCategoriesError}
              parts={parts}
              partsError={partsError}
              handleSearchCatalogPeace={handleSearchCatalogPeace}
            />
          )}
          {showMenu && !isDesktop && (
            <MenuMobile
              menuRef={menuRef}
              showMenu={showMenu}
              partCategories={partCategories}
              partSubCategories={partSubCategories}
              parts={parts}
              handleSearchCatalogPeace={handleSearchCatalogPeace}
              setSelectedPartCategory={setSelectedPartCategory}
              setSelectedPartSubCategories={setSelectedPartSubCategories}
              selectedPartCategory={selectedPartCategory}
              selectedPartSubCategories={selectedPartSubCategories}
              closeMenu={closeMenu}
            />
          )}
        </div>

        <Link to="/" className="logo">
          <img alt="" src={logoCenterImage} />
        </Link>
        {!user ? (
          <Link
            onClick={() => {
              setLoginModal(true);
              closeMenu();
            }}
            className="user_link open_login_form hide_desktop"
          >
            <i className="fa-solid fa-circle-user"></i>
          </Link>
        ) : (
          <UserProfile user={user} hideDesktop />
        )}

        <form className="search_field" onSubmit={onSearch}>
          <input
            ref={searchInputRef}
            type="text"
            name="search"
            onInput={onSearchInput}
            placeholder="Entrer la référence CAREF de la pièce"
          />
          <button disabled={searchTerm.trim().length === 0} type="submit">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
          {isSearch && (
            <SearchResContainer
              searchRes={searchRes}
              searching={searching}
              searchPart={searchPart}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              searchInputRef={searchInputRef}
              setSearchRes={setSearchRes}
            />
          )}
        </form>
        <div className="contact_details">
          <a href="tel:" className="detail">
            <img alt="" src={phoneImage} /> 77 355 52 52
          </a>
          <a href="mailto:contact@carefautomobile.com" className="detail">
            <img alt="" src={emailImage} /> contac@carefautomobile.com
          </a>
        </div>
        <Link
          className="menu_button"
          onClick={(e) => {
            e.preventDefault();
            sowQuickAccessModal(true);
          }}
        >
          Réserver en ligne
        </Link>
      </div>
      <div className="header_bottom">
        <Link className="ca_icon hide_mobile" to="/">
          <img alt="" src={caImage} />
        </Link>
        <SearchCatalogPiece
          searchCatalogOption={searchCatalogOption}
          setSearchCatalogOption={setSearchCatalogOption}
          homeSearchCatalogPeace={homeSearchCatalogPeace}
        />
        <ul className="icon_list hide_mobile">
          <li>
            <Link to="/commandes">
              <img alt="" src={commandsImage} /> Commandes
            </Link>
            <div className="order_notification">{orders?.length ?? 0}</div>
          </li>
          <li>
            <Link to="/commandes#favoris">
              <img alt="" src={heartImage} /> Favoris{' '}
            </Link>
            <div className="likes_notification">{favorite?.length ?? 0}</div>
          </li>
          <li>
            <Link to="/cart">
              <img alt="" src={cartImage} /> Panier
            </Link>
            <div className="notification">{cart?.length ?? 0}</div>
          </li>
        </ul>
        {!user ? (
          <Link onClick={() => setLoginModal(true)} className="user_link hide_mobile auth_trigger">
            <i className="fa-solid fa-circle-user"></i>
          </Link>
        ) : (
          <UserProfile user={user} />
        )}
      </div>
      <LoginModal open={openLoginModal} toggle={setLoginModal} />
      <ConfirmOtpModal open={openConfirmOtpModal} toggle={setConfirmOtpModal} />
      <SignUpModal open={openSignUpModal} toggle={setSignUpModal} />
      <SuccessSignUpModal open={openSuccessSignUpModal} toggle={setSuccessSignUpModal} />
      <ErrorSignUpModal open={openErrorSignUpModal} toggle={setErrorSignUpModal} />
      <VinModal />
    </header>
  );
};

export default Header;
