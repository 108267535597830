import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import ChoiceFormImage from '../../assets/images/icons/form_choice.svg';
import DateFormImage from '../../assets/images/icons/form_date.svg';
import LocationFormImage from '../../assets/images/icons/form_location.svg';
import MarqueFormImage from '../../assets/images/icons/form_marque.svg';
import ModeleFormImage from '../../assets/images/icons/form_modele.svg';
import PhoneFormImage from '../../assets/images/icons/form_phone.svg';
import UserImage from '../../assets/images/icons/form_user_new.svg';
import { API_URL } from '../../constant/api';
import { useBookingStore } from '../../store/booking';

const MeetForm = () => {
  const setBooking = useBookingStore((state) => state.setBooking);
  const setFormData = useBookingStore((state) => state.setFormData);
  const success = useBookingStore((state) => state.success);
  const setSuccess = useBookingStore((state) => state.setSuccess);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { data: marques } = useFetch(`${API_URL}/marquesBMD`);
  const { data: packs } = useFetch(`${API_URL}/packs`);
  const { data: pservices } = useFetch(`${API_URL}/pservices`);
  const { data: models } = useFetch(
    watch('brand') ? `${API_URL}/marquesBMD/${watch('brand')}/modeles` : null,
  );

  const choices = {
    services: pservices,
    packs,
  };

  const choice = choices[watch('service_type')];

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      for (const field in data) {
        if (Object.hasOwnProperty.call(data, field)) {
          const value = data[field];
          formData.append(field, value);
        }
      }

      setFormData(formData);

      const marque = marques.find((marque) => marque.id === data.brand);
      const model = models.find((model) => model.id === data.model);
      const choice = choices[data.service_type].find((item) => item.id === data.choice);

      setBooking({
        ...data,
        marque,
        model,
        choice,
        url: 'pbookings',
      });

      navigate('/booking-validation');
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      {success && (
        <div className="popup_overlay">
          <div className="popup sent_booking">
            <i className="fa-solid fa-check"></i>
            <h4>Votre réservation a bien été envoyée et nous vous revenons dans les meilleurs délais</h4>
            <div className="buttons">
              <button className="button_primary close_popup" onClick={() => setSuccess(false)}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} id="diagnostic_booking">
        <div className="square_radio_options">
          <div className="options_container reduce">
            <div className="single_option">
              <input
                type="radio"
                name="service_type"
                id="service_1"
                value="services"
                {...register('service_type')}
                defaultChecked
              />
              <label htmlFor="service_1">Services</label>
            </div>
            <div className="single_option">
              <input
                type="radio"
                name="service_type"
                {...register('service_type')}
                id="service_3"
                value="packs"
              />
              <label htmlFor="service_3">Packs</label>
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="form_field">
            <img src={UserImage} alt="" />
            <div className="input_right">
              <label htmlFor="name">Prénom & Nom*</label>
              <input
                type="text"
                id="name"
                placeholder="Renseigner votre nom complet"
                name="name"
                {...register('name', {
                  required: true,
                  pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/i,
                  maxLength: 55,
                  minLength: 3,
                })}
              />
            </div>
          </div>
          <div className="form_field">
            <img src={PhoneFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="tel">Téléphone*</label>
              <input
                type="text"
                id="tel"
                placeholder="Entrer votre numéro"
                name="tel"
                {...register('tel', {
                  required: true,
                  minLength: 9,
                })}
                aria-invalid={errors.tel ? 'true' : 'false'}
              />
            </div>
          </div>
          <div className="form_field">
            <img src={ChoiceFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="choice_selector">Choix*</label>
              <div className="select_container">
                <select
                  name="choice"
                  id="choice_selector"
                  {...register('choice', {
                    required: true,
                  })}
                  aria-invalid={errors.choice ? 'true' : 'false'}
                >
                  <option value="">Sélectionner</option>
                  {choice?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item[watch('service_type').slice(0, -1)]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form_field">
            <img src={MarqueFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="brand_selector">Marque*</label>
              <div className="select_container">
                <select
                  name="brand"
                  id="brand_selector"
                  {...register('brand', {
                    required: true,
                  })}
                  aria-invalid={errors.brand ? 'true' : 'false'}
                >
                  <option value="">Sélectionner</option>
                  {marques?.map((marque) => (
                    <option key={marque.id} value={marque.id}>
                      {marque.nom}
                    </option>
                  ))}
                  <option value="other">Autre</option>
                </select>
              </div>
            </div>
          </div>

          <div className="form_field">
            <img src={ModeleFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="model_selector">Modèle* </label>
              <div className="select_container">
                <select
                  name="model"
                  id="model_selector"
                  {...register('model', {
                    required: true,
                  })}
                  aria-invalid={errors.model ? 'true' : 'false'}
                >
                  <option value="">Sélectionner</option>
                  {models?.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.nom}
                    </option>
                  ))}
                  <option value="other">Autre</option>
                </select>
              </div>
            </div>
          </div>

          <div className="form_field">
            <img src={DateFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="el">Date et heure souhaitées*</label>
              <div className="date_hour">
                <input
                  type="date"
                  id="el"
                  placeholder="Date rendez vous (jj/mm/aaaa)"
                  name="date"
                  {...register('date', {
                    required: true,
                  })}
                  aria-invalid={errors.date ? 'true' : 'false'}
                />
                <span>à</span>
                <input
                  type="time"
                  className="timepicker"
                  placeholder="h:mn"
                  name="time"
                  {...register('time', {
                    required: true,
                  })}
                  aria-invalid={errors.time ? 'true' : 'false'}
                />
              </div>
            </div>
          </div>
          <div className="form_field">
            <img src={LocationFormImage} alt="" />
            <div className="input_right">
              <label htmlFor="location_selector">Lieu du rendez-vous*</label>
              <div className="select_container">
                <select name="location" id="location_selector" {...register('location')}>
                  <option value="locaux">Locaux de CAREF sis à Ouakam</option>
                  <option value="autres">Autres</option>
                </select>
              </div>
            </div>
          </div>
          {watch('location') === 'autres' && (
            <div className={`form_field`} id="address_input">
              <img src={LocationFormImage} alt="" />
              <div className="input_right">
                <label htmlFor="autre">Autre lieu du rendez-vous*</label>
                <input
                  type="text"
                  id="autre"
                  placeholder="Veuillez renseigner l'adresse"
                  name="autre"
                  {...register('autre', {
                    required: true,
                    minLength: 3,
                  })}
                  aria-invalid={errors.autre ? 'true' : 'false'}
                />
              </div>
            </div>
          )}
        </div>
        <div className="message_field">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            {...register('message')}
            placeholder="plus de précision ici"
          ></textarea>
        </div>
        <button className="open_sent_booking" type="submit">
          Réserver
        </button>
      </form>
    </Fragment>
  );
};

export default MeetForm;
