import React from 'react';
import { useDocumentTitle } from 'usehooks-ts';

const SuccessPaymentPage = () => {
  useDocumentTitle('CAREF | Paiement');

  return (
    <div className="orange_payment_page">
      <div className="back">
        <a href="/commandes">
          <i className="fas fa-arrow-left"></i>Mes commands
        </a>
      </div>
      <h2>
        <i
          className="fas fa-check-circle"
          style={{
            color: '#4caf50',
            fontSize: '96px',
            marginBottom: '10px',
          }}
        ></i>
        Votre commande a été validée avec succès
      </h2>
    </div>
  );
};
export default SuccessPaymentPage;
