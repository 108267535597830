import React from 'react';

function Pagination({ piecesPerPage, data, setCurrentPage, currentPage }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.length / piecesPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="pages_nav">
      <div
        className={`square ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => {
          currentPage > 1 && paginate(currentPage - 1);
          document.querySelector('#bons_plans_p')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }}
      >
        <i className="fa-solid fa-chevron-left"></i>
      </div>
      {pageNumbers.map((number) => (
        <div
          key={number}
          className={`square ${currentPage === number ? 'active' : ''}`}
          onClick={() => {
            paginate(number);
            document.querySelector('#bons_plans_p')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }}
        >
          {number}
        </div>
      ))}
      <div
        className={`square ${currentPage === pageNumbers.length ? 'disabled' : ''}`}
        onClick={() => {
          currentPage < pageNumbers.length && paginate(currentPage + 1);
          document.querySelector('#bons_plans_p')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }}
      >
        <i className="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  );
}

export default Pagination;
