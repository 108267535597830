import { create } from 'zustand';

export const useCartStore = (() => {
  const cartData = localStorage.getItem('cart') ?? '[]';
  const orderData = localStorage.getItem('order') ?? '{"retraitType": "agence"}';

  return create((set) => ({
    cart: JSON.parse(cartData),
    order: JSON.parse(orderData),
    omPaymentResponse: null,
    setOrder: (order) =>
      set(() => {
        localStorage.setItem('order', JSON.stringify(order));
        return { order };
      }),
    setOrderRetraitType: (retraitType) =>
      set((state) => {
        const order = { ...state.order };
        order.retraitType = retraitType;
        localStorage.setItem('order', JSON.stringify(order));
        return { order };
      }),
    addToCart: (product, isNew = true) =>
      set((state) => {
        let cart = [...state.cart, { ...product, isNew }];
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    removeFromCart: (product) =>
      set((state) => {
        const cart = state.cart.filter((p) => p.id !== product.id);
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    decrementQuantity: (product) =>
      set((state) => {
        const cart = [...state.cart];
        const index = cart.findIndex((p) => p.id === product.id);
        if (index !== -1) cart[index].quantity -= 1;
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    incrementQuantity: (product) =>
      set((state) => {
        const cart = [...state.cart];
        const index = cart.findIndex((p) => p.id === product.id);
        if (index !== -1) cart[index].quantity += 1;
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    setOmPaymentResponse: (omPaymentResponse) => set(() => ({ omPaymentResponse })),

    clearCart: () =>
      set(() => {
        localStorage.setItem('cart', JSON.stringify([]));
        localStorage.setItem('order', JSON.stringify({ retraitType: 'agence' }));
        return { cart: [], order: { retraitType: 'agence' } };
      }),
  }));
})();
