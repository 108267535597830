import React from 'react';
import { useFetch } from 'usehooks-ts';
import { API_URL } from '../../constant/api';
import { useSearchStore } from '../../store/search';

function SearchCatalogPiece({ searchCatalogOption, homeSearchCatalogPeace, setSearchCatalogOption }) {
  const setClickedOnSearch = useSearchStore((state) => state.setClickedOnSearch);
  const setVinModal = useSearchStore((state) => state.setVinModal);
  const setVin = useSearchStore((state) => state.setVin);
  const { data: marques } = useFetch(`${API_URL}/marques`);
  const { data: models } = useFetch(`${API_URL}/marques/${searchCatalogOption.selectedMarque}/modeles`);
  const { data: drives } = useFetch(
    `${API_URL}/marques/${searchCatalogOption.selectedMarque}/modeles/${searchCatalogOption.selectedModel}/drive`,
  );

  return (
    <div className="select_vehicule">
      <strong>sélectionner un véhicule</strong>
      <form action="pieces_catalogue.html" id="select_vehicle_form">
        <div
          className={`button_bars select_container ${
            searchCatalogOption?.selectedMarque ? '' : 'active-select'
          }`}
        >
          <select
            name="brand"
            id="header_brand"
            value={searchCatalogOption?.selectedMarque ?? ''}
            onChange={(e) => {
              const selectedMarqueName = marques?.find((marque) => marque.id === e.target.value)?.nom;
              setSearchCatalogOption({
                ...searchCatalogOption,
                selectedMarque: e.target.value,
                selectedMarqueName,
                selectedModel: '',
                selectedModelName: '',
                selectedDrive: '',
                selectedDriveName: '',
              });
              setClickedOnSearch(false);
              setVin(null);
            }}
          >
            <option value="">Marque *</option>
            {marques?.map((marque) => (
              <option key={marque.id} value={marque?.id}>
                {marque?.nom}
              </option>
            ))}
            <option value="other">Autre</option>
          </select>
        </div>
        <div
          className={`dropdown select_container ${
            searchCatalogOption?.selectedMarque &&
            !searchCatalogOption?.selectedModel &&
            !searchCatalogOption?.selectedDrive
              ? 'active-select'
              : ''
          }`}
        >
          <select
            name="model"
            id="header_model"
            value={searchCatalogOption?.selectedModel ?? ''}
            onChange={(e) => {
              const selectedModelName = models?.find((model) => model.id === e.target.value)?.nom;
              setSearchCatalogOption({
                ...searchCatalogOption,
                selectedModel: e.target.value,
                selectedModelName,
                selectedDrive: e.target.value === 'other' ? 'other' : '',
                selectedDriveName: e.target.value === 'other' ? 'other' : '',
              });
              setClickedOnSearch(false);
              setVin(null);
              if (e.target.value === 'other') {
                setVinModal(true, null);
              }
            }}
          >
            <option value="">Modèle *</option>
            {models?.map((model) => (
              <option key={model.id} value={model?.id}>
                {model?.nom}
              </option>
            ))}
            <option value="other">Autre</option>
          </select>
        </div>
        <div
          className={`dropdown select_container ${
            searchCatalogOption?.selectedMarque &&
            searchCatalogOption?.selectedModel &&
            !searchCatalogOption?.selectedDrive
              ? 'active-select'
              : ''
          }`}
        >
          <select
            name="drive"
            id="header_drive"
            value={searchCatalogOption?.selectedDrive ?? ''}
            onChange={(e) => {
              const selectedDriveName = drives?.find((drive) => drive.id === e.target.value)?.nom;

              setSearchCatalogOption({
                ...searchCatalogOption,
                selectedDrive: e.target.value,
                selectedDriveName,
              });

              setClickedOnSearch(false);

              setVin(null);
            }}
          >
            {searchCatalogOption.selectedModel !== 'other' && (
              <>
                <option value="">Motorisation *</option>
                {drives?.map((drive) => (
                  <option key={drive.id} value={drive?.id}>
                    {drive?.nom}
                  </option>
                ))}
              </>
            )}
            <option value="other">Autre</option>
          </select>
        </div>
        <button
          className={`${
            searchCatalogOption?.selectedMarque &&
            searchCatalogOption?.selectedModel &&
            searchCatalogOption?.selectedDrive
              ? 'active-select'
              : ''
          }`}
          onClick={(e) => {
            homeSearchCatalogPeace(e);
          }}
        >
          <span className="hide_mobile">Rechercher</span>
          <i className="fa-solid fa-magnifying-glass hide_desktop"></i>
        </button>
      </form>
    </div>
  );
}

export default SearchCatalogPiece;
