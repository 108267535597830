import React, { useState } from 'react';
import { useDocumentTitle, useFetch } from 'usehooks-ts';
import headerImage from "../../assets/images/Image header ventes voitures d'occasion.png";
import carNav from '../../assets/images/icons/car_nav.svg';
import HeroSlider from '../../components/hero-slider/HeroSlider';
import Navigation from '../../components/navigation/Navigation';
import Pagination from '../../components/pagination/Pagination';
import VehiculeBonPlans from '../../components/vehicule-bon-plans/VehiculeBonPlans';
import { API_URL } from '../../constant/api';
import Subtitles from './Subtitles';

function BonsPlansAutoPage() {
  useDocumentTitle("CAREF | Ventes de voitures d'occasion");
  const { data: vehicules } = useFetch(`${API_URL}/vehicles`);
  const { data: marques } = useFetch(`${API_URL}/brands`);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const { data: models } = useFetch(
    selectedBrandId ? `${API_URL}/brands/${selectedBrandId}/models` : null,
  );

  const piecesPerPage = 10;
  const indexOfLastPiece = currentPage * piecesPerPage;
  const indexOfFirstPiece = indexOfLastPiece - piecesPerPage;

  const filteredVehicules = vehicules?.filter((vehicule) => {
    if (selectedBrandId && selectedModelId) {
      return vehicule?.brand === selectedBrandId && vehicule?.drive === selectedModelId;
    } else if (selectedBrandId) {
      return vehicule?.brand === selectedBrandId;
    } else {
      return vehicule;
    }
  });
  const paginatedVehicules = filteredVehicules?.slice(indexOfFirstPiece, indexOfLastPiece);

  async function renderModelsByMarque(e) {
    setSelectedBrandId(e.target.value);
    setSelectedModelId(null);
  }

  function renderDataByModel(e) {
    setSelectedModelId(e.target.value);
    setCurrentPage(1);
  }
  let headerText = "Une gamme large et variée de véhicules d'occasion certifiés et garantis";

  return (
    <div style={{ background: '#F7FAFC' }}>
      <HeroSlider image={headerImage} text={headerText} />
      <Navigation />
      <div className="bons_plans" id="bons_plans">
        <h2>LES VEHICULES EN VENTE</h2>
        <Subtitles />
        <div className="breadcrumb">
          <div className="single_breadcrumb">
            <img src={carNav} alt="car-nav" />
            <span>
              <select
                style={{
                  textOverflow: 'ellipsis',
                }}
                onChange={(e) => renderModelsByMarque(e)}
              >
                <option value="">Marque</option>
                {marques?.map((marque) => (
                  <option value={marque?.id} key={marque?.id}>
                    {marque?.brand}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <i className="fa-sharp fa-chevron-right"></i>
          <div className="single_breadcrumb">
            <span className="ct">
              <select
                style={{
                  textOverflow: 'ellipsis',
                }}
                onChange={(e) => renderDataByModel(e)}
              >
                <option value="">Modèle</option>
                {models?.map((model) => (
                  <option value={model?.id} key={model?.id}>
                    {model?.model}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <i className="fa-sharp fa-chevron-right"></i>
        </div>
        <div className="used_vehicles_container">
          {paginatedVehicules ? (
            paginatedVehicules?.map((vehicule) => (
              <VehiculeBonPlans vehicule={vehicule} key={vehicule.id} />
            ))
          ) : (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}
          {paginatedVehicules?.length === 0 && (
            <div
              className="no_data"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h3>Aucune donnée disponible</h3>
            </div>
          )}
        </div>
        <section className="pieces_catalogue_page">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={vehicules}
            piecesPerPage={piecesPerPage}
          />
        </section>
      </div>
    </div>
  );
}

export default BonsPlansAutoPage;
