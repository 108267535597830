import React, { Fragment, useState } from "react";
import ForfaitsEntretienImage from "../../assets/images/forfaits_entretien.png";
import AbonnementsImage from "../../assets/images/abonnements.png";
import AbonnementImage_mobile1 from "../../assets/images/carroussel/2-Abonnement-1.png";
import AbonnementImage_mobile2 from "../../assets/images/carroussel/2-Abonnement-2.png";
import EntretienImage_mobile1 from "../../assets/images/carroussel/1-Forfaits entretien & révision-1.png";
import EntretienImage_mobile2 from "../../assets/images/carroussel/1-Forfaits entretien & révision-2.png";
import StepsImage from "../../assets/images/Etapes réservation desktop.png";
import reservation1 from "../../assets/images/Etapes réservation mobile 1.png";
import reservation2 from "../../assets/images/Etapes réservation mobile 2.png";
import reservation3 from "../../assets/images/Etapes réservation mobile 3.png";
import { Controller, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const MethodologySection = () => {
  const [currentPageEntretien, setCurrentPageEntretien] = useState(1);
  const [currentPageAbonnements, setCurrentPageAbonnements] = useState(1);
  const totalSlides = 2;
  const [controlledSwiper1, setControlledSwiper1] = useState(null);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);
  const [controlledSwiper3, setControlledSwiper3] = useState(null);
  const handleSlideChangeEntretien = (swiper) => {
    setCurrentPageEntretien(swiper.realIndex + 1);
  };

  const handleSlideChangeAbonnements = (swiper) => {
    setCurrentPageAbonnements(swiper.realIndex + 1);
  };
  return (
    <Fragment>
      <section className="methodology_section" id="forfaits">
        <h2 className="section_title">Forfaits d'entretien</h2>
        <img className="hide_swipper" src={ForfaitsEntretienImage} alt="" />
        <div
          className="hide_desktop"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#EB550E",
          }}
        >{`${currentPageEntretien}/${totalSlides}`}</div>
        <div className="logos_slider hide_desktop">
          <Swiper
            modules={[Controller, Pagination]}
            slidesPerView={1}
            onSwiper={setControlledSwiper1}
            spaceBetween={50}
            onSlideChange={handleSlideChangeEntretien}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return (
                  '<span class="' +
                  className +
                  '" style="background-color: #EB550E;"></span>'
                );
              },
            }}
            controller={{ control: controlledSwiper1 }}
            className="swiper-wrapper"
          >
            <SwiperSlide>
              <img
                alt=""
                className="swiper-slide"
                src={EntretienImage_mobile1}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt=""
                className="swiper-slide"
                src={EntretienImage_mobile2}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="methodology_section" id="abonnements">
        <h2 className="section_title">Les abonnements</h2>
        <img src={AbonnementsImage} alt="" className="hide_swipper" />
        <div
          className="hide_desktop"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#EB550E",
          }}
        >{`${currentPageAbonnements}/${totalSlides}`}</div>
        <div className="logos_slider hide_desktop">
          <Swiper
            modules={[Controller, Pagination]}
            slidesPerView={1}
            onSwiper={setControlledSwiper2}
            onSlideChange={handleSlideChangeAbonnements}
            spaceBetween={50}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return (
                  '<span class="' +
                  className +
                  '" style="background-color: #EB550E;"></span>'
                );
              },
            }}
            controller={{ control: controlledSwiper2 }}
            className="swiper-wrapper"
          >
            <SwiperSlide>
              <img
                alt=""
                className="swiper-slide"
                src={AbonnementImage_mobile1}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt=""
                className="swiper-slide"
                src={AbonnementImage_mobile2}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="methodology_section">
        <img src={StepsImage} alt="" className="hide_swipper" />

        <div className="logos_slider hide_desktop">
          <Swiper
            modules={[Controller, Pagination]}
            slidesPerView={1}
            onSwiper={setControlledSwiper3}
            spaceBetween={50}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return (
                  '<span class="' +
                  className +
                  '" style="background-color: #EB550E;"></span>'
                );
              },
            }}
            controller={{ control: controlledSwiper3 }}
            className="swiper-wrapper"
          >
            <SwiperSlide>
              <img alt="" className="swiper-slide" src={reservation1} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" className="swiper-slide" src={reservation2} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" className="swiper-slide" src={reservation3} />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </Fragment>
  );
};

export default MethodologySection;
