import React from 'react';
import HeroSlider from '../../components/hero-slider/HeroSlider';
import Navigation from '../../components/navigation/Navigation';
import DiagnosticAbout from './DiagnosticAbout';
import Meet from './Meet';
import MethodologySection from './MethodologySection';
import TextOutSection from './TextOutSection';
import headerImage from '../../assets/images/diagnostic2.png';
import { useDocumentTitle } from 'usehooks-ts';

const DiagnosticPage = () => {
  useDocumentTitle('CAREF | Entretien et reparation');
  
  let headerText =
    'Des services d’entretien et de maintenance dans le plus grand respect des préconisations des constructeurs automobiles';
  return (
    <div style={{ background: '#F7FAFC' }}>
      <HeroSlider text={headerText} image={headerImage} />
      <Navigation />
      <TextOutSection />
      <DiagnosticAbout />
      <MethodologySection />
      <Meet />
    </div>
  );
};

export default DiagnosticPage;
