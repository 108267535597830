import React, { useState } from "react";
import { Controller, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DragPlusIcon from "../../assets/images/icons/drag_plus.svg";
import DraganddropIcon from "../../assets/images/icons/draganddrop.svg";
import InfosIcon from "../../assets/images/icons/info.svg";
import { renamePiece } from "./RequestAQuote";
import VinTooltip from "../../components/VinTooltip";

const MAX_STEPS = 3;

const MobileForm = ({
  register,
  handleSubmit,
  watch,
  setValue,
  errors,
  onSubmit,
  marques,
  models,
  drives,
  partCategories,
  filteredPieces,
  files,
  pictureOverlayRef,
  getRootProps,
  setFiles,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="pi_form">
      <Swiper
        modules={[Pagination, Navigation, Controller]}
        pagination={{ clickable: true }}
        navigation={{ clickable: true }}
        onSwiper={setControlledSwiper}
        slidesPerView={1}
        onSlideChange={(e) => {
          setCurrentStep(e.activeIndex + 1);
        }}
        spaceBetween={50}
        className="swiper-wrapper inputs_container"
      >
        <SwiperSlide>
          <div className="title hide_desktop">
            <div>Informations du client</div>
            <div>
              {currentStep}/{MAX_STEPS}
            </div>
          </div>
          <input
            type="text"
            name="fname"
            id="fname"
            placeholder="Prénom*"
            {...register("fname", { required: true, pattern: /^[A-Za-z]+$/i })}
            aria-invalid={errors.fname ? "true" : "false"}
          />
          <input
            type="text"
            name="lname"
            id="lname"
            placeholder="Nom*"
            {...register("lname", { required: true, pattern: /^[A-Za-z]+$/i })}
            aria-invalid={errors.lname ? "true" : "false"}
          />
          <input
            type="tel"
            name="tel"
            id="tel"
            placeholder="Téléphone*"
            {...register("tel", { required: true, pattern: /^[0-9]+$/i })}
            aria-invalid={errors.tel ? "true" : "false"}
          />
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
            aria-invalid={errors.email ? "true" : "false"}
          />
        </SwiperSlide>

        <SwiperSlide>
          <div className="title hide_desktop">
            <div>Identification du Véhicule</div>
            <div>
              {currentStep}/{MAX_STEPS}
            </div>
          </div>
          <select
            name="brand"
            id="brand_selector"
            {...register("brand", { required: true })}
            aria-invalid={errors.brand ? "true" : "false"}
          >
            <option value="">Marque*</option>
            {marques?.map((marque) => (
              <option key={marque.id} value={marque.id}>
                {marque.nom}
              </option>
            ))}
          </select>
          <select
            name="model"
            id="model_selector"
            {...register("model", { required: true })}
            aria-invalid={errors.model ? "true" : "false"}
          >
            <option value="">Modèle*</option>
            {models?.map((model) => (
              <option key={model.id} value={model.id}>
                {model.nom}
              </option>
            ))}
          </select>
          <select
            name="drive"
            id="drive_selector"
            {...register("drive", { required: true })}
            aria-invalid={errors.drive ? "true" : "false"}
          >
            <option value="">Motorisation*</option>
            {drives?.map((drive) => (
              <option key={drive.id} value={drive.id}>
                {drive.nom}
              </option>
            ))}
          </select>
          <div className="img_title">
            <input
              name="vin"
              id="vin"
              type="text"
              placeholder=" Numéro de chassis(VIN)*"
              {...register("vin", {
                required: true,
                pattern: /^[A-Za-z0-9]+$/i,
              })}
              aria-invalid={errors.vin ? "true" : "false"}
            />
            <img src={InfosIcon} alt="infos" data-tooltip-id="vinfos" />
            <VinTooltip id="vinfos" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="title hide_desktop">
            <div>Informations de la pièce</div>
            <div>
              {currentStep}/{MAX_STEPS}
            </div>
          </div>
          <select
            name="category"
            id="category_selector"
            {...register("category", { required: true })}
            aria-invalid={errors.category ? "true" : "false"}
          >
            <option value="">Catégorie*</option>
            {partCategories?.map((partCategory) => (
              <option key={partCategory.id} value={partCategory.id}>
                {partCategory.nom}
              </option>
            ))}
          </select>
          <select
            name="parts"
            id="parts_selector"
            {...register("parts", { required: true })}
            aria-invalid={errors.parts ? "true" : "false"}
          >
            <option value="">Nom de la pièce*</option>
            {filteredPieces?.map((piece) => (
              <option key={piece.id} value={piece.id}>
                {renamePiece(piece.parts)}
              </option>
            ))}
          </select>
          <select
            name="status"
            id="status_selector"
            {...register("status", { required: true })}
            aria-invalid={errors.status ? "true" : "false"}
          >
            <option value="">Etat*</option>
            <option value="new">Pièce neuve</option>
            <option value="secondhand">Pièce d’occasion</option>
          </select>
          <div className="quantity_input_container">
            <span>Quantité*</span>
            <div className="quantity">
              <div
                className="quantity-minus"
                onClick={() => {
                  if (watch("quantity") > 1)
                    setValue("quantity", +watch("quantity") - 1);
                }}
              >
                -
              </div>
              <input
                className="quantity_input"
                name="quantity"
                id="quantity"
                placeholder="Quantité"
                min="1"
                {...register("quantity")}
                max="99"
                defaultValue="1"
                step="1"
                required
              />
              <div
                className="quantity-plus"
                onClick={() => setValue("quantity", +watch("quantity") + 1)}
              >
                +
              </div>
            </div>
          </div>
          <textarea
            name="message"
            id="message"
            placeholder="Message"
            {...register("message")}
          ></textarea>
          <div className="overlay_container" {...getRootProps()}>
            <input
              type="file"
              name="files"
              className="picture_hide"
              id="files"
              multiple
              max={2}
              onInput={(e) => setFiles(Array.from(e.target.files).slice(0, 2))}
              {...register("files")}
            />
            <label
              ref={pictureOverlayRef}
              htmlFor="files"
              className="picture_overlay"
            >
              <img src={DraganddropIcon} alt="drag" />
              <p>Glisser ou importer vos images (maximum 2)</p>
              <img src={DragPlusIcon} alt="plus" className="right_image" />
            </label>
            {files?.length !== 0 && (
              <div className="files_overview" onClick={() => setFiles([])}>
                {Array.from(files || []).map((file) => (
                  <div key={file.name} className="file_overview">
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
      {currentStep === MAX_STEPS && (
        <div className="btn-submit">
          <button
            onClick={() => {
              controlledSwiper?.slidePrev();
            }}
            type="button"
            className="open_sent_pi back"
          >
            <i className="fas fa-arrow-left"></i>
            Retour
          </button>
          <button className="open_sent_pi">Envoyer la demande</button>
        </div>
      )}
      {currentStep !== MAX_STEPS && (
        <div className="nextStep">
          <button
            type="button"
            disabled={currentStep === 1}
            onClick={() => {
              controlledSwiper?.slidePrev();
            }}
            className="nextStepBtn"
            style={{ marginRight: "1.5rem" }}
          >
            <i className="fas fa-arrow-left"></i>
          </button>

          <button
            type="button"
            onClick={() => {
              controlledSwiper?.slideNext();
            }}
            className="nextStepBtn"
          >
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      )}
    </form>
  );
};

export default MobileForm;
