import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const SearchResContainer = ({
  searchRes,
  searching,
  searchPart,
  isSearch,
  setIsSearch,
  searchInputRef,
  setSearchRes,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsSearch(false);
        setSearchRes([]);
        if (searchInputRef.current) searchInputRef.current.value = '';
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSearch, searchInputRef, setIsSearch, setSearchRes]);

  return (
    <div className="res_container" ref={ref}>
      {!searching &&
        searchRes?.slice(0, 5).map((res, index) => (
          <Link
            key={index}
            onClick={(e) => {
              e.preventDefault();
              searchPart(res);
            }}
          >
            <div>{res?.parts}</div>
            <div>{res?.caref}</div>
          </Link>
        ))}
      {searching && (
        <div
          style={{
            height: '100px',
          }}
          className="loader-container"
        >
          <span className="loader"></span>
        </div>
      )}
      {!searching && searchRes?.length === 0 && (
        <div
          style={{
            padding: '20px 0',
          }}
          className="center"
        >
          Aucun résultat trouvé
        </div>
      )}
    </div>
  );
};
export default SearchResContainer;
