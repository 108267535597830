import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import calendar from '../../assets/images/icons/car_calendar.svg';
import carClutch from '../../assets/images/icons/car_clutch.svg';
import carEmail from '../../assets/images/icons/car_email.svg';
import location from '../../assets/images/icons/car_location.svg';
import carMeter from '../../assets/images/icons/car_meter.svg';
import carburant from '../../assets/images/icons/car_oil.svg';
import carOption from '../../assets/images/icons/car_options.svg';
import carPhone from '../../assets/images/icons/car_phone.svg';
import car_return from '../../assets/images/icons/car_return_arrow.svg';
import status from '../../assets/images/icons/car_status.svg';
import imageHolder from '../../assets/images/logo_center.svg';
import SelectedImage from './SelectedImage';
import { formatNumberHumanReadable } from '../../utils';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
function VehiculeDetails({ vehicule }) {
  const [image, setImage] = useState(null);
  const [showMore, toggleShowMore] = useToggle(true);
  const navigate = useNavigate();
  return (
    <div className="vehicle_details" id="vehicle_container">
      <Link
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        <img src={car_return} alt="return" />
      </Link>

      {vehicule ? (
        <div className="vehicle">
          <div className="images">
            <div className="thumbnails">
              {vehicule?.links?.map(
                (link, index) =>
                  index !== 0 && (
                    <SelectedImage link={link ?? imageHolder} setImage={setImage} key={index} />
                  ),
              )}
            </div>
            <div className="selected_vehicule">
              <img src={image ?? vehicule?.links[1]} alt="zoomed" />
            </div>
          </div>
          <div className="infos_actions">
            <div>
              <div className="infos">
                <div className="top">
                  <h3>{vehicule?.type}</h3>
                  <p>{vehicule?.brandname}</p>
                  <p>{vehicule?.modelname}</p>
                  <p>{vehicule?.drivename}</p>
                </div>
                <h3>{formatNumberHumanReadable(vehicule?.price)} FCFA</h3>
              </div>
              <div className="actions">
                <div className="top_wrapper">
                  <button onClick={toggleShowMore}>
                    Contacter le vendeur
                    {showMore ? (
                      <i className="fas fa-chevron-up"></i>
                    ) : (
                      <i className="fas fa-chevron-down"></i>
                    )}
                  </button>
                  {showMore ? (
                    <div className="expand_details">
                      <h3>{vehicule?.seller}</h3>
                      <div className="one_line">
                        <i class="fa-regular fa-user"></i>
                        <p>{vehicule?.vendor ?? 'CAREF'}</p>
                      </div>
                      <div className="one_line">
                        <img src={carPhone} alt="distance" />
                        <p>+221 77 000 00 00</p>
                      </div>
                      <div className="one_line">
                        <img src={carEmail} alt="distance" />
                        <p>caref-auto@gmail.com</p>
                      </div>
                      <div className="one_line">
                        <img src={location} alt="distance" />
                        <p>Maristes, Dakar, Senegal</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <h3>Détails</h3>
              <div>
                <div className="bottom">
                  <div className="one_line">
                    <img src={carMeter} alt="distance" />
                    <p>{formatIncompletePhoneNumber(vehicule?.distance)}km</p>
                  </div>
                  <div className="one_line">
                    <img src={calendar} alt="year" />
                    <p>{vehicule?.year}</p>
                  </div>
                  <div className="one_line">
                    <img src={carClutch} alt="transmission" />
                    <p>{vehicule?.clutch}</p>
                  </div>
                </div>
                <div className="bottom">
                  <div className="one_line">
                    <img src={carburant} alt="Carburant" />
                    <p>{vehicule?.carburant}</p>
                  </div>
                  {vehicule?.option ? (
                    <div className="one_line">
                      <img src={carOption} alt="Options" />
                      <p>
                        Options:
                        <a href={vehicule?.links[0]} target="_blank" rel="noopener noreferrer">
                          Télécharger
                        </a>
                      </p>
                    </div>
                  ) : null}
                  <div className="one_line">
                    <img src={status} alt="Etat" />
                    <p>{vehicule?.infos}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </div>
  );
}

export default VehiculeDetails;
