import React from 'react';

import HeroSlider from '../../components/hero-slider/HeroSlider';
import Navigation from '../../components/navigation/Navigation';
import BestSellers from './BestSellers';
import CarmakerBrands from './CarmakerBrands';
import PiecesCatalogue from './PiecesCatalogue';
import RequestAQuote from './RequestAQuote';
import { useFetch } from 'usehooks-ts';
import { API_URL } from '../../constant/api';
import headerImage from '../../assets/images/hero_slides/slide_2.png';
import { useDocumentTitle } from 'usehooks-ts';

const HomePage = () => {
  useDocumentTitle('CAREF | Pièces de rechange');
  const { data: parts } = useFetch(`${API_URL}/parts`);
  let headerText =
    'Commandez une gamme plus large de pièces de rechange de qualité garantie en seulement quelques clics!';
  return (
    <div style={{ backgroundColor: '#F7FAFC' }}>
      <HeroSlider image={headerImage} text={headerText} />
      <Navigation />
      <PiecesCatalogue />
      <RequestAQuote />
      <CarmakerBrands />
      <BestSellers parts={parts} />
    </div>
  );
};

export default HomePage;
