import { create } from 'zustand';

export const useAuthStore = create((set) => ({
  user: null,
  customer: null,
  confirmResult: null,
  loadingUser: true,
  orders: [],
  setConfirmResult: (confirmResult) => set(() => ({ confirmResult })),
  login: (user) => set(() => ({ user })),
  setCustomer: (customer) => {
    return set(() => ({ customer }));
  },
  logout: () => set(() => ({ user: null })),
  setLoadingUser: (loadingUser) => set(() => ({ loadingUser })),
  setOrders: (orders) => set(() => ({ orders })),
}));
