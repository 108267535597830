import React, { useEffect, useState } from "react";
import HeroSlider from "../../components/hero-slider/HeroSlider";
import Navigation from "../../components/navigation/Navigation";
import Pagination from "../../components/pagination/Pagination";
import PieceBonPlans from "../../components/Piece-Bon-Plans/PieceBonPlans";
import filtre_icon from "../../assets/images/icons/filtre_icon.png";
import Subtitles from "./Subtitles";
import { useDocumentTitle, useFetch } from "usehooks-ts";
import { API_URL } from "../../constant/api";
import headerImage from "../../assets/images/Image Header ventes d'occasions pièces détachées.png";

function BonsPlansPiecePage() {
  useDocumentTitle('CAREF | Ventes de pièces d\'occasion');
  const [currentPage, setCurrentPage] = useState(1);
  const piecesPerPage = 8;
  const indexOfLastPiece = currentPage * piecesPerPage;
  const indexOfFirstPiece = indexOfLastPiece - piecesPerPage;
  const { data: marques } = useFetch(`${API_URL}/brands`);
  const { data: categories } = useFetch(`${API_URL}/categories_piece`);
  const { data: pieces } = useFetch(`${API_URL}/parts`);
  const [selectedMarque, setSelectedMarque] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [filteredPieces, setFilteredPieces] = useState(null);
  useEffect(() => {
    setFilteredPieces(pieces);
  }, [pieces]);

  function renderDataByMarque(event) {
    setSelectedMarque(event.target.value);
    if (
      event.target.value === "" &&
      (selectedCategorie == null || selectedCategorie === "")
    ) {
      setSelectedMarque(null);
      return setFilteredPieces(pieces);
    } else if (
      event.target.value === "" &&
      (selectedCategorie !== null || selectedCategorie !== "")
    ) {
      setSelectedMarque(null);
      return setFilteredPieces(
        pieces?.filter((piece) => piece?.category === selectedCategorie)
      );
    }
    selectedCategorie === "" || selectedCategorie === null
      ? setFilteredPieces(
          pieces?.filter((piece) => piece?.brand === event.target.value)
        )
      : setFilteredPieces(
          pieces?.filter(
            (piece) =>
              piece?.brand === event.target.value &&
              piece?.category === selectedCategorie
          )
        );
  }

  function renderDataByCategorie(event) {
    setSelectedCategorie(event.target.value);
    if (event.target.value === "" && selectedMarque !== null) {
      setSelectedCategorie(null);
      return setFilteredPieces(
        pieces?.filter((piece) => piece?.brand === selectedMarque)
      );
    } else if (
      (event.target.value === "" || event.target.value == null) &&
      (selectedMarque === "" || selectedMarque === null)
    ) {
      setSelectedCategorie(null);
      return setFilteredPieces(pieces);
    }

    selectedMarque === "" || selectedMarque === null
      ? setFilteredPieces(
          pieces?.filter((piece) => piece?.category === event.target.value)
        )
      : setFilteredPieces(
          pieces?.filter(
            (piece) =>
              piece?.brand === selectedMarque &&
              piece?.category === event.target.value
          )
        );
  }

  const paginatedPieces = filteredPieces?.slice(
    indexOfFirstPiece,
    indexOfLastPiece
  );
  let headerText = "Une gamme large et variée de pièces d'occasion garanties";
  return (
    <div style={{ background: "#F7FAFC" }}>
      <HeroSlider image={headerImage} text={headerText} />
      <Navigation />
      <div className="bons_plans" id="bons_plans_p">
        <Subtitles />
        <div className="breadcrumb">
          <div className="single_breadcrumb">
            <img src={filtre_icon} alt="filtre" />
            <span>
              <select
                style={{
                  textOverflow: "ellipsis",
                }}
                name="marque"
                id="marque"
                onChange={(event) => renderDataByMarque(event)}
              >
                <option value="">Marque</option>
                {marques?.map((marque, index) => (
                  <option key={index} value={marque?.brand}>
                    {marque?.brand}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <i className="fa-sharp fa-chevron-right"></i>
          <div className="single_breadcrumb">
            <span className="ct">
              <select
                style={{
                  textOverflow: "ellipsis",
                }}
                name="categorie"
                id="categorie"
                onChange={(event) => renderDataByCategorie(event)}
              >
                <option value="">Categorie</option>
                {categories?.map((categorie, index) => (
                  <option key={index} value={categorie?.nom}>
                    {categorie?.nom}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <i className="fa-sharp fa-chevron-right"></i>
        </div>
        <div className="bons_plans_container">
          {paginatedPieces ? (
            paginatedPieces?.map((piece, index) => (
              <PieceBonPlans key={index} product={piece} />
            ))
          ) : (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          )}
          {paginatedPieces?.length === 0 && (
            <div
              className="no_data"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Aucune donnée disponible</h3>
            </div>
          )}
        </div>
        <section className="pieces_catalogue_page">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredPieces}
            piecesPerPage={piecesPerPage}
          />
        </section>
      </div>
    </div>
  );
}

export default BonsPlansPiecePage;
