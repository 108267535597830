import React from "react";
import methodologyMobile from "../../assets/images/Diagramme des services CAREF.png";
import methodologyDesktop from "../../assets/images/methodology.jpg";

function Methodology() {
  return (
    <section className="methodology_section">
      <h1 id="about">QUI SOMMES NOUS ?</h1>
      <p>
        Le marché de l’automobile connaît une forte croissance au Sénégal durant
        cette dernière décennie et devient de plus en plus dynamique avec
        plusieurs opportunités de business.
        <br />
        <br />
        CAREF est une société spécialisée dans les services de maintenance
        automobiles créée par des ingénieurs ayant plus de 20 ans d’expériences
        cumulées dans l’industrie automobile, en partenariat avec des
        entreprises internationales expertes dans le domaine de l’aftermarket
        (après-vente).
      </p>
      <h1
        style={{
          display: "block",
          textAlign: "left",
        }}
      >
        NOS OBJECTIFS:
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <p>
          <b>1-</b> La sécurité routière
        </p>
        <p>
          <b>2-</b> Le respect de l’environnement
        </p>
        <p>
          <b>3-</b> La réparabilité des véhicules modernes
        </p>
        <p>
          <b>4-</b> La sécurisation du marché de l’occasion
        </p>
      </div>
      <p>
        Cliquez{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.carefautomobile.com/presentation.pdf"
          style={{ textDecoration: "underline" }}
        >
          ici
        </a>{" "}
        pour obtenir la présentation complète sur CAREF Automobile.
      </p>
      <h1>Méthodologie CAREF Automobile</h1>
      <img
        src={methodologyDesktop}
        alt="Methodologie CAREF"
        className="hide_mobile"
      />
      <img
        src={methodologyMobile}
        alt="Methodologie CAREF"
        className="hide_desktop"
      />
      <p>
        CAREF offre une large gamme de services pensés pour matcher aux besoins
        de nos différentes catégories de clientèle grâce à notre expertise et à
        nos partenariats solides dans le domaine de l’aftermarket (après-vente)
        automobile.
      </p>
    </section>
  );
}

export default Methodology;
