import React from 'react';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';

const SuccessSignUpModal = ({ open, toggle }) => {
  const navigate = useNavigate();

  function close() {
    toggle(false);
    navigate(window.location.pathname, { replace: true });
  }
  return (
    <Modal
      open={open}
      onClose={() => close()}
      classNames={{
        overlay: 'dialog_overlay',
        modal: 'dialog_container',
      }}
      id="user_created"
    >
      <div className="popup user_created">
        <i className="fa-solid fa-check"></i>
        <h4>Votre compte a été bien créé</h4>
        <div className="buttons">
          <button onClick={() => close()} className="button_primary" id="close_user_created">
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessSignUpModal;
