import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import RemoveImage from '../assets/images/icons/remove.svg';
import imageHolder from '../assets/images/logo_center.svg';
import { API_URL } from '../constant/api';
import { fetchApi } from '../libs/fetch';
import { useAuthStore } from '../store/auth';
import { useCartStore } from '../store/cart';
import { useFavoriteStore } from '../store/useFavorite';
import { formatNumberHumanReadable } from '../utils';

const CartItem = ({ item, favorite, toggleFavorite }) => {
  const [moreInfo, toggleInfos] = useToggle();
  const decrement = useCartStore((state) => state.decrementQuantity);
  const increment = useCartStore((state) => state.incrementQuantity);
  const remove = useCartStore((state) => state.removeFromCart);
  const isFavorite = favorite.find((itm) => itm.caref === item.caref);

  return (
    <Fragment>
      <div className="article">
        <div className="details">
          <div>
            <img className="remove" src={RemoveImage} onClick={() => remove(item)} alt="" />
          </div>
          <div
            style={{
              width: '6.25rem',
              height: '5.75rem',
            }}
          >
            <img
              style={{
                objectFit: 'contain',
                width: '4.5rem',
                height: 'auto',
                scale: '1.2',
              }}
              src={item?.link_vendor ?? imageHolder}
              alt=""
            />
            <img
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              className="article_img"
              src={item?.link ?? imageHolder}
              alt=""
            />
          </div>
          <div className="text">
            <div className="marque_model">
              {item?.brand} &nbsp; {item?.model}
            </div>
            <div className="title">{item?.parts}</div>
            <div className="category">{item?.category}</div>
            {moreInfo && (
              <Fragment>
                <div className="category">{item?.sous_category}</div>
                <div className="category">{item?.dname}</div>
                <div className="category">Etat: {item?.isNew ? 'Neuf' : 'Occasion'}</div>
              </Fragment>
            )}
            <div className="caref">CAREF : {item?.caref}</div>
            <div style={{ cursor: 'pointer' }} className="btn">
              <span onClick={toggleInfos}>
                {moreInfo ? 'Moins d’infos' : 'Plus d’infos'}
                {moreInfo ? (
                  <i className="fas fa-chevron-up"></i>
                ) : (
                  <i className="fas fa-chevron-down"></i>
                )}
              </span>
              <div
                className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart`}
                onClick={() => toggleFavorite(item, isFavorite)}
              ></div>
            </div>
          </div>
        </div>
        <div
          className="price"
          style={{
            textTransform: 'uppercase',
          }}
        >
          {formatNumberHumanReadable(item.price)} FCFA
        </div>
        <div className="quantity">
          <div>
            <button disabled={item.quantity === 1} onClick={() => decrement(item)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="3" viewBox="0 0 10 3">
                <path d="M0 2.1V0H9.09V2.1H0Z" />
              </svg>
            </button>
            <span>{item.quantity}</span>
            <button disabled={item.quantity === 99} onClick={() => increment(item)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <path d="M0 7.89V5.85H5.67V0H7.77V5.85H13.5V7.89H7.77V13.8H5.67V7.89H0Z" />
              </svg>
            </button>
          </div>
        </div>
        <div
          className="totalPrice"
          style={{
            textTransform: 'uppercase',
          }}
        >
          {formatNumberHumanReadable(item.price * item.quantity)} FCFA
        </div>
      </div>
    </Fragment>
  );
};

const CartItemMobile = ({ item, favorite, toggleFavorite }) => {
  const [moreInfo, toggleInfos] = useToggle();
  const decrement = useCartStore((state) => state.decrementQuantity);
  const increment = useCartStore((state) => state.incrementQuantity);
  const remove = useCartStore((state) => state.removeFromCart);

  const isFavorite = favorite.find((itm) => itm.caref === item.caref);

  return (
    <Fragment>
      <div className="mobile_article">
        <div className="details">
          <div>
            <svg
              className="remove"
              onClick={() => remove(item)}
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </div>
          <div
            style={{
              gap: '1.25rem',
              width: '4.875rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={item?.link_vendor ?? imageHolder}
              alt=""
              style={{
                marginTop: '0.75rem',
                objectFit: 'contain',
                width: '4.5rem',
                height: 'auto',
                scale: '1.2',
              }}
            />
            <img
              style={{ objectFit: 'contain', width: '100%', height: '4.5rem' }}
              className="article_img"
              src={item?.link ?? imageHolder}
              alt=""
            />
          </div>
          <div className="text">
            <div className="marque_model">
              {item?.brand} {item?.model}
            </div>
            <div className="title">{item?.parts}</div>
            <div className="category">{item?.category}</div>
            {moreInfo && (
              <Fragment>
                <div className="category">{item?.drive}</div>
                <div className="category">{item?.model}</div>
                <div className="category">Etat: {item?.isNew ? 'Neuf' : 'Occasion'}</div>
              </Fragment>
            )}
            <div className="caref">CAREF : {item?.caref}</div>
            <div onClick={toggleInfos} style={{ cursor: 'pointer' }} className="btn">
              <span>
                {moreInfo ? 'Moins d’infos' : 'Plus d’infos'}
                {moreInfo ? (
                  <i className="fas fa-chevron-up"></i>
                ) : (
                  <i className="fas fa-chevron-down"></i>
                )}
              </span>
              <div
                className={`heart ${isFavorite ? 'fa-solid' : 'fa-regular'} fa-heart`}
                onClick={() => toggleFavorite(item, isFavorite)}
              ></div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div
            className="totalPrice"
            style={{
              textTransform: 'uppercase',
            }}
          >
            {formatNumberHumanReadable(item.price * item.quantity)} FCFA
          </div>
          <div className="quantity">
            <div>
              <button disabled={item.quantity === 1} onClick={() => decrement(item)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="3" viewBox="0 0 10 3">
                  <path d="M0 2.1V0H9.09V2.1H0Z" />
                </svg>
              </button>
              <span>{item.quantity}</span>
              <button disabled={item.quantity === 99} onClick={() => increment(item)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                  <path d="M0 7.89V5.85H5.67V0H7.77V5.85H13.5V7.89H7.77V13.8H5.67V7.89H0Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Cart = ({ cart }) => {
  const navigate = useNavigate();
  const addToFavorite = useFavoriteStore((state) => state.addToFavorite);
  const removeFromFavorite = useFavoriteStore((state) => state.removeFromFavorite);
  const user = useAuthStore((state) => state.user);
  const customer = useAuthStore((state) => state.customer);
  const favorite = useFavoriteStore((state) => state.favorite);
  const [liking, setLiking] = useState(false);

  async function toggleFavorite(p, isFavorite) {
    try {
      if (liking) return;

      if (isFavorite) {
        removeFromFavorite(p);
        if (user) {
          setLiking(true);
          await fetchApi(`${API_URL}/likes/${customer.id}/${p?.product_id ?? p?.id}`, {
            method: 'DELETE',
          });
          setLiking(false);
        }
      } else {
        addToFavorite(p);
        if (user) {
          const data = {
            user_id: user?.id,
            product_id: p?.id,
            customer_id: customer.id,
            price: p?.price,
            brand: p?.brand,
            category: p?.category,
            model: p?.model,
            vendor: p?.vendor,
            parts: p?.parts,
            caref: p?.caref,
            link: p?.link,
          };

          const formData = new FormData();

          for (const key in data) {
            formData.append(key, data[key]);
          }

          setLiking(true);
          await fetchApi(`${API_URL}/likes`, {
            method: 'POST',
            body: formData,
            Headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setLiking(false);
        }
      }
    } catch (error) {
      console.error('add favorite', error);
      setLiking(false);
    }
  }
  return (
    <Fragment>
      <div className="hide_mobile">
        <div className="order_content" id="order_content">
          <div className="title title_first right_border">Articles</div>
          <div className="title right_border">Prix Unitaire</div>
          <div className="title right_border">Quantité</div>
          <div className="title title_last">Coût</div>
        </div>
        <div className="order_content_articles">
          {cart.map((item) => (
            <CartItem key={item.id} item={item} favorite={favorite} toggleFavorite={toggleFavorite} />
          ))}
          {cart.length === 0 && (
            <div className="empty_card">
              <h2>Votre panier est vide</h2>
              <button onClick={() => navigate('/')}>Continuer mes achats</button>
            </div>
          )}
        </div>
      </div>
      <div className="mobile_articles hide_desktop">
        <h2>LISTE DES ARTICLES</h2>
        <div className="mobile_articles_articles">
          {cart.map((item) => (
            <CartItemMobile
              key={item.id}
              item={item}
              favorite={favorite}
              toggleFavorite={toggleFavorite}
            />
          ))}
          {cart.length === 0 && (
            <div className="empty_card">
              <h2>Votre panier est vide</h2>
              <button onClick={() => navigate('/')}>Continuer mes achats</button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Cart;
