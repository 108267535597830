import { create } from 'zustand';

export const useFavoriteStore = (() => {
  const favoriteData = localStorage.getItem('favorite') ?? '[]';

  return create((set) => ({
    favorite: JSON.parse(favoriteData),

    setFavorite: (favorite) => {
      localStorage.setItem('favorite', JSON.stringify(favorite));
      return set(() => ({ favorite }));
    },
    addToFavorite: (product) =>
      set((state) => {
        let favorite = [...state.favorite, product];
        localStorage.setItem('favorite', JSON.stringify(favorite));
        return { favorite };
      }),
    removeFromFavorite: (product) =>
      set((state) => {
        const favorite = state.favorite.filter((p) => p.caref !== product.caref);
        localStorage.setItem('favorite', JSON.stringify(favorite));
        return { favorite };
      }),
    clearFavorite: () =>
      set(() => {
        localStorage.setItem('favorite', JSON.stringify([]));
        return { favorite: [] };
      }),
  }));
})();
