import React from 'react';
import { useToggle } from 'usehooks-ts';
import { Link } from 'react-router-dom';

function getFirstLettersOfName(name) {
  return name
    ?.split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();
}

const UserProfile = ({ user, hideDesktop }) => {
  const [showMenu, toggleMenu] = useToggle(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && showMenu) {
        toggleMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu, toggleMenu]);

  return (
    <div
      ref={ref}
      className={`user_profile ${
        hideDesktop ? 'hide_desktop user_link open_login_form' : 'hide_mobile'
      }`}
      onClick={toggleMenu}
    >
      {user.photoURL ? <img src={user.photoURL} alt="" /> : getFirstLettersOfName(user?.displayName)}
      {showMenu && (
        <div className="menu">
          <button className="user">{user?.displayName}</button>
          <Link to="/commandes">
            <i className="fas fa-box-open"></i>
            Mes commandes
          </Link>
          <Link to="/cart">
            <i className="fas fa-shopping-cart"></i>
            Mon panier
          </Link>
          <Link to="/commandes#favoris">
            <i className="fas fa-heart"></i>
            Mes favoris
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
